/*

  s e t t i n g s
  Settings

  :description:
  Application-wide settings.

*/

//
//  :version:
// eslint-disable-next-line
const {VERSION} = require("../package.json")

//
//  :environment:
export const { REACT_APP_BASE_API_URL, REACT_APP_BASE_API_URL_ } = process.env;

//
//  :base-api-url:
export const BASE_API_URL_CONFIG = {
  LOCAL_STORAGE_KEY: "eky-base-api-url",
  CAN_EXIST_IN_LOCAL_STORAGE: false,
  LOCAL: "http://0.0.0.0:3344/dev/web-pages/state-of-the-industry/beverages",
  LOCALv2: "http://0.0.0.0:3344/dev",
  LIVE: REACT_APP_BASE_API_URL,
  LIVEv2: REACT_APP_BASE_API_URL_
}

export const BASE_API_URL_CONFIG_VIRTUAL_ROOM = {
  submit: {
    LOCAL: "http://0.0.0.0:3344/dev/web-pages/virtual-brand-store/questionnaire/submit",
    LIVE: "https://av6pbxaaxi.execute-api.eu-west-2.amazonaws.com/dev/web-pages/virtual-brand-store/questionnaire/submit"
  },
  scan: {
    LOCAL: "http://0.0.0.0:3344/dev/web-pages/virtual-brand-store/questionnaire/scan",
    LIVE: "https://av6pbxaaxi.execute-api.eu-west-2.amazonaws.com/dev/web-pages/virtual-brand-store/questionnaire/scan"
  }
}

export const ENCRYPT_DECRYPT_API_URL = {
  LOCAL: "http://0.0.0.0:3344/dev/encrypt-data/post",
  LIVE: "https://av6pbxaaxi.execute-api.eu-west-2.amazonaws.com/dev/encrypt-data/post"
}

//
//  :jwt:
export const LOCAL_STORAGE_TOKEN_KEY = "jwt"