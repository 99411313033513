//
//  :react & redux:
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  formSubmissionAsync, selectFormSubmissionError, selectFormSubmissionRequesting,
  selectFormSubmissionResponse
} from "../../../features/virtual-room-questionnaire/virtualRoomQuestionnaireSlice";

const GROUP = `[VIRTUAL-ROOM-QUESTIONNAIRE]`

//
//  :component:
export const QuestionContainer = (props) => {
  return (
    <div className="w-full bg-white rounded-lg text-black p-8">
      <h3 className="text-lg mb-2">{props.title || "QUESTION TEXT"}</h3>
      {props.children}
    </div>
  )
}

const DEFAULT_QUESTION_PLACEHOLDER = "Your answer"

const QUESTIONS = {
  fullName: {
    text: "Full Name",
    placeholder: DEFAULT_QUESTION_PLACEHOLDER
  },
  company: {
    text: "Company",
    placeholder: DEFAULT_QUESTION_PLACEHOLDER
  },
  email: {
    text: "Email",
    placeholder: DEFAULT_QUESTION_PLACEHOLDER
  },
  visionImage: {
    text: "Select the image that best suits your vision for your room:",
    placeholder: DEFAULT_QUESTION_PLACEHOLDER
  },
  styleImage: {
    text: "Select the style that best suits your vision for your room:",
    placeholder: DEFAULT_QUESTION_PLACEHOLDER
  },
  brandGuidelines: {
    text: "Please send us your brand guidelines:",
    placeholder: DEFAULT_QUESTION_PLACEHOLDER
  },
  previousCampaigns: {
    text: "If there has been a previous campaign(s) that you really liked the visuals for, please share that with us:",
    placeholder: DEFAULT_QUESTION_PLACEHOLDER
  },
  prohibitedItems: {
    text: "Is there anything we are prohibited to showing in the room?",
    placeholder: DEFAULT_QUESTION_PLACEHOLDER
  },
  syndicationDestinations: {
    text: "Where will your Virtual Room be syndicated (for example, to your own .com websites, to your retailers, to your channel partners via their product pages, brand pages, landing pages.)?",
    placeholder: DEFAULT_QUESTION_PLACEHOLDER
  },
  numberOfRooms: {
    text: "How many rooms would you like to have for your Virtual Room experience / how many do you want?",
    placeholder: DEFAULT_QUESTION_PLACEHOLDER
  },
  brandsInRooms: {
    text: "Which brand / brands you want in which room(s)?",
    placeholder: DEFAULT_QUESTION_PLACEHOLDER
  },
  productsInRooms: {
    text: "Which products would you like to see in which room(s)?",
    placeholder: DEFAULT_QUESTION_PLACEHOLDER
  },
  productUpdateFrequency: {
    text: "How often do you expect an update to the products in the room?",
    placeholder: DEFAULT_QUESTION_PLACEHOLDER
  },
}


export const VirtualRoomQuestionnairePageFormV3 = (props) => {

  //
  //  :redux:
  //  Variables to handle things with our redux state.
  const dispatch = useDispatch()

  //
  //  :state:
  //  Variables to store things in the react state.
  //const formSubmissionStatus = useSelector(selectFormSubmissionStatus)
  const formSubmissionRequesting = useSelector(selectFormSubmissionRequesting)
  const formSubmissionError = useSelector(selectFormSubmissionError)
  const formSubmissionResponse = useSelector(selectFormSubmissionResponse)

  //
  //  :human information:
  const [fullName, setFullName] = useState("")
  const [company, setCompany] = useState("")
  const [email, setEmail] = useState("")

  //
  //  :image selection:
  const [styleImage, setStyleImage] = useState("")

  //
  //  :files:
  const [brandGuidelines, setBrandGuidelines] = useState("")
  const [previousCampaigns, setPreviousCampaigns] = useState("")

  //
  //  :text areas / inputs:
  const [prohibitedItems, setProhibitedItems] = useState("")
  const [syndicationDestinations, setSyndicationDestinations] = useState([])
  const [numberOfRooms, setNumberOfRooms] = useState("")
  const [brandsInRooms, setBrandsInRooms] = useState("")
  const [productsInRooms, setProductsInRooms] = useState("")

  //
  //  :radio inputs:
  const [productUpdateFrequency, setProductUpdateFrequency] = useState("")

  //
  //  :errors:
  const [fullNameError, setFullNameError] = useState(null)
  const [companyError, setCompanyError] = useState(null)
  const [emailError, setEmailError] = useState(null)
  const [styleImageError, setStyleImageError] = useState(null)
  const [brandGuidelinesError, setBrandGuidelinesError] = useState(null)
  const [previousCampaignsError, setPreviousCampaignsError] = useState(null)
  const [prohibitedItemsError, setProhibitedItemsError] = useState(null)
  const [syndicationDestinationsError, setSyndicationDestinationsError] = useState(null)
  const [numberOfRoomsError, setNumberOfRoomsError] = useState(null)
  const [brandsInRoomsError, setBrandsInRoomsError] = useState(null)
  const [productsInRoomsError, setProductsInRoomsError] = useState(null)
  const [productUpdateFrequencyError, setProductUpdateFrequencyError] = useState(null)
  const [fieldErrorsPresent, SetFieldErrorsPresent] = useState(null)

  //
  //  :helpers:
  //  Generic helper functions.
  //  -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =

  const _resetErrors = () => {
    const functions = [
      setFullNameError, setCompanyError, setEmailError, setStyleImageError, setBrandGuidelinesError,
      setPreviousCampaignsError, setProhibitedItemsError, setSyndicationDestinationsError, setNumberOfRoomsError,
      setBrandsInRoomsError, setProductsInRoomsError, setProductUpdateFrequencyError, SetFieldErrorsPresent
    ]
    functions.map((f) => {
      return f(null)
    })
    return true
  }

  const _isDev = () => {
    const knownDevEnvironments = ["localhost", "0.0.0.0"];
    const currentURL = document.location.href;
    return knownDevEnvironments.some(env => currentURL.includes(env));
  }

  //
  //  :events:
  //  Functions that deal with events.
  //  -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =
  const _validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const _setInputErrorsIfRequired = () => {
    let isValid = true
    const ERROR_MESSAGE_IS_REQUIRED = "* required"
    if (!fullName) {
      setFullNameError(ERROR_MESSAGE_IS_REQUIRED)
      isValid = false
    }
    if (!company) {
      setCompanyError(ERROR_MESSAGE_IS_REQUIRED)
      isValid = false
    }
    if (!email) {
      setEmailError(ERROR_MESSAGE_IS_REQUIRED)
      isValid = false
    }
    if (!_validateEmail(email)) {
      setEmailError(`the email address is not valid`)
      isValid = false
    }
    // if (!visionImage) {
    //   setVisionImageError(ERROR_MESSAGE_IS_REQUIRED)
    //   isValid = false
    // }
    if (!styleImage) {
      setStyleImageError(ERROR_MESSAGE_IS_REQUIRED)
      isValid = false
    }
    if (!brandGuidelines) {
      setBrandGuidelinesError(ERROR_MESSAGE_IS_REQUIRED)
      isValid = false
    }
    if (!previousCampaigns) {
      setPreviousCampaignsError(ERROR_MESSAGE_IS_REQUIRED)
      isValid = false
    }
    if (!prohibitedItems) {
      setProhibitedItemsError(ERROR_MESSAGE_IS_REQUIRED)
      isValid = false
    }
    if (!syndicationDestinations.length > 0) {
      setSyndicationDestinationsError(ERROR_MESSAGE_IS_REQUIRED)
      isValid = false
    }
    if (!numberOfRooms) {
      setNumberOfRoomsError(ERROR_MESSAGE_IS_REQUIRED)
      isValid = false
    }
    if (!brandsInRooms) {
      setBrandsInRoomsError(ERROR_MESSAGE_IS_REQUIRED)
      isValid = false
    }
    if (!productsInRooms) {
      setProductsInRoomsError(ERROR_MESSAGE_IS_REQUIRED)
      isValid = false
    }

    if (!productUpdateFrequency) {
      setProductUpdateFrequencyError(ERROR_MESSAGE_IS_REQUIRED)
      isValid = false
    }

    isValid ? SetFieldErrorsPresent(false) : SetFieldErrorsPresent(true)

    //
    //  Now validate if there is an error present or not.
    return isValid
  }

  const onClickStyleImage = (event) => {
    //
    //  If we've clicked on the currently selected target, clear it.
    if (styleImage === event.target.src) {
      const images = Array.from(document.querySelectorAll(".style-image"))
      images.map((i) => {
        i.classList.remove("opacity-25")
        return true
      })
      setStyleImage(null)
    } else {
      //
      //  In all other instances we have selected an image element, fade out the rest.
      setStyleImage(event.target.src)
      //
      //  Now select all vision images an add an opacity class to them.
      const images = Array.from(document.querySelectorAll(".style-image"))
      images.map((i) => {
        i.classList.add("opacity-25")
        return true
      })
      event.target.classList.remove("opacity-25")
    }
  }

  const onClickSubmitForm = (event) => {
    if (formSubmissionRequesting || formSubmissionResponse) {
      console.log(`form is requesting or has already been completed, skipping form submission`)
      return
    }

    //
    //  Stop propagation beyond this point.
    event.preventDefault()

    //
    //  Reset all of our errors.
    _resetErrors()

    //
    //  Assemble our payload.
    const payload = {
      fullName: fullName || null,
      company: company || null,
      email: email || null,
      styleImage: styleImage || null,
      brandGuidelines: brandGuidelines || null,
      previousCampaigns: previousCampaigns || null,
      prohibitedItems: prohibitedItems || null,
      syndicationDestinations: syndicationDestinations.join() || null,
      numberOfRooms: numberOfRooms || null,
      brandsInRooms: brandsInRooms || null,
      productsInRooms: productsInRooms || null,
      productUpdateFrequency: productUpdateFrequency || null
    }

    //
    //  Ok, validate our inputs and update our error variables if there is something wrong.
    const IS_VALID = _setInputErrorsIfRequired()

    //
    //  If the form is not valid, return from here.
    if (!IS_VALID) {
      console.warn(`${GROUP} error, input validation has failed, aborting submission`)
      // console.log(payload);
      return
    }

    //
    //  Ok, now dispatch our action.
    dispatch(formSubmissionAsync(payload))
  }

  const onClickFillQuestions = () => {
    setFullName("af-full-name")
    setCompany("af-company")
    setEmail("liam@eyekandy.com")
    setProhibitedItems("af-prohibited-items")
    setNumberOfRooms("af-number-of-rooms")
    setBrandsInRooms("af-brands-in-rooms")
    setProductsInRooms("af-products-in-rooms")
    setProductsInRooms("af-products-in-rooms")

    document.querySelector("#root > div > div > div > section > form > div:nth-child(5) > div > img:nth-child(2)").click()
    document.querySelector("#root > div > div > div > section > form > div:nth-child(9) > div > div:nth-child(1) > input[type=checkbox]").click()
    document.querySelector("#root > div > div > div > section > form > div:nth-child(13) > div > div:nth-child(1) > input[type=radio]").click()
  }

  //
  //  :question rendering:
  //  As we have a number of different questions, it will be easier to just write out everything into functions.
  //  -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =

  const _renderError = (value) => {
    return <span className="text-red-600 text-xs font-bold font-italic pt-4">{value}</span>
  }

  const _textInputClasses = () => {
    return `w-full outline-none border-b-2 border-[#D0D0D0] bg-transparent text-[#363A48] transition-all ease-in-out focus:border-[#6168b0] duration-500`
  }

  const _textAreaClasses = () => {
    return `w-full outline-none p-2 border-2 border-[#D0D0D0] bg-transparent text-[#363A48] transition-all ease-in-out focus:border-[#6168b0] duration-500`
  }

  const _fileInputClasses = () => {
    return `w-full outline-none p-2 border-2 border-[#D0D0D0] bg-transparent text-[#363A48] transition-all ease-in-out focus:border-[#6168b0] duration-500 text-sm`
  }

  const renderFullNameQuestion = () => {
    const key = "fullName"
    return (
      <QuestionContainer title={QUESTIONS[key].text}>
        <input
          placeholder={QUESTIONS[key].placeholder}
          className={_textInputClasses()}
          value={fullName} onChange={(e) => setFullName(e.target.value)}
          type="text"
          required={true}
        />
        {fullNameError && (_renderError(fullNameError))}
      </QuestionContainer>
    )
  }

  const renderCompanyQuestion = () => {
    const key = "company"
    return (
      <QuestionContainer title={QUESTIONS[key].text}>
        <input
          placeholder={QUESTIONS[key].placeholder}
          className={_textInputClasses()}
          value={company} onChange={(e) => setCompany(e.target.value)}
          type="text"
          required={true}
        />
        {companyError && (_renderError(companyError))}
      </QuestionContainer>
    )
  }

  const renderEmailQuestion = () => {
    const key = "email"
    return (
      <QuestionContainer title={QUESTIONS[key].text}>
        <input
          placeholder={QUESTIONS[key].placeholder}
          className={_textInputClasses()}
          value={email} onChange={(e) => setEmail(e.target.value)}
          type="email"
          required={true}
        />
        {emailError && (_renderError(emailError))}
      </QuestionContainer>
    )
  }

  const renderStyleImageQuestion = () => {
    const key = "styleImage"
    return (
      <QuestionContainer title={QUESTIONS[key].text}>
        <div className="grid grid-cols-2 gap-4 content-around items-center">
          <img alt="" className="style-image transition-opacity" onClick={onClickStyleImage} src="https://cdn.pointandplace.com/questionnaire/style-vision/style-008.png" />
          <img alt="" className="style-image transition-opacity" onClick={onClickStyleImage} src="https://cdn.pointandplace.com/questionnaire/style-vision/style-009.png" />
          <img alt="" className="style-image transition-opacity" onClick={onClickStyleImage} src="https://cdn.pointandplace.com/questionnaire/style-vision/style-010.png" />
          <img alt="" className="style-image transition-opacity" onClick={onClickStyleImage} src="https://cdn.pointandplace.com/questionnaire/style-vision/style-011.png" />
          <img alt="" className="style-image transition-opacity" onClick={onClickStyleImage} src="https://cdn.pointandplace.com/questionnaire/style-vision/style-012.png" />
          <img alt="" className="style-image transition-opacity" onClick={onClickStyleImage} src="https://cdn.pointandplace.com/questionnaire/style-vision/style-013.png" />
          <img alt="" className="style-image transition-opacity" onClick={onClickStyleImage} src="https://cdn.pointandplace.com/questionnaire/style-vision/style-014.png" />
          <img alt="" className="style-image transition-opacity" onClick={onClickStyleImage} src="https://cdn.pointandplace.com/questionnaire/style-vision/style-015.png" />
        </div>
        {styleImageError && (_renderError(styleImageError))}
      </QuestionContainer>
    )
  }

  const renderBrandGuidelinesQuestion = () => {
    const key = "brandGuidelines"
    return (
      <QuestionContainer title={QUESTIONS[key].text}>
        <input
          placeholder={QUESTIONS[key].placeholder}
          className={_fileInputClasses()}
          onChange={(e) => setBrandGuidelines(e.target.files[0])}
          type="file"
          required={true}
          accept=".zip,.xlsx,.csv,.png,.jpg,.jpeg,.pdf,.doc,.docx"
        />
        {brandGuidelinesError && (_renderError(brandGuidelinesError))}
      </QuestionContainer>
    )
  }

  const renderPreviousCampaignsQuestion = () => {
    const key = "previousCampaigns"
    return (
      <QuestionContainer title={QUESTIONS[key].text}>
        <input
          placeholder={QUESTIONS[key].placeholder}
          className={_fileInputClasses()}
          onChange={(e) => { setPreviousCampaigns(e.target.files[0]); }}
          type="file"
          required={true}
          accept=".zip,.xlsx,.csv,.png,.jpg,.jpeg,.pdf,.doc,.docx"
        />
        {previousCampaignsError && (_renderError(previousCampaignsError))}
      </QuestionContainer>
    )
  }

  const renderProhibitedItemsQuestion = () => {
    const key = "prohibitedItems"
    return (
      <QuestionContainer title={QUESTIONS[key].text}>
        <textarea
          placeholder={QUESTIONS[key].placeholder}
          className={_textAreaClasses()}
          value={prohibitedItems} onChange={(e) => setProhibitedItems(e.target.value)}
          required={true}
          rows={6}
        ></textarea>
        {prohibitedItemsError && (_renderError(prohibitedItemsError))}
      </QuestionContainer>
    )
  }

  const renderSyndicationDestinationsQuestion = () => {
    const key = "syndicationDestinations"
    return (
      <QuestionContainer title={QUESTIONS[key].text}>
        <div className="flex flex-col gap-2 ">
          <div className="flex items-center">
            <input type="checkbox" name="syndicationDestinations" value="own website" onClick={(e) => { e.target.checked ? setSyndicationDestinations([...syndicationDestinations, e.target.value]) : setSyndicationDestinations(syndicationDestinations.filter((filter) => filter !== e.target.value)) }} />
            <label className="ml-4">Your own website</label>
          </div>
          <div className="flex items-center">
            <input type="checkbox" name="syndicationDestinations" value="retailers" onClick={(e) => { e.target.checked ? setSyndicationDestinations([...syndicationDestinations, e.target.value]) : setSyndicationDestinations(syndicationDestinations.filter((filter) => filter !== e.target.value)) }} />
            <label className="ml-4">To your retailers</label>
          </div>
          <div className="flex items-center">
            <input type="checkbox" name="syndicationDestinations" value="channel partners product pages" onClick={(e) => { e.target.checked ? setSyndicationDestinations([...syndicationDestinations, e.target.value]) : setSyndicationDestinations(syndicationDestinations.filter((filter) => filter !== e.target.value)) }} />
            <label className="ml-4">To your channel partners via product pages</label>
          </div>
          <div className="flex items-center">
            <input type="checkbox" name="syndicationDestinations" value="channel partners brand pages" onClick={(e) => { e.target.checked ? setSyndicationDestinations([...syndicationDestinations, e.target.value]) : setSyndicationDestinations(syndicationDestinations.filter((filter) => filter !== e.target.value)) }} />
            <label className="ml-4">To your channel partners via brand pages</label>
          </div>
          <div className="flex items-center">
            <input type="checkbox" name="syndicationDestinations" value="channel partners landing pages" onClick={(e) => { e.target.checked ? setSyndicationDestinations([...syndicationDestinations, e.target.value]) : setSyndicationDestinations(syndicationDestinations.filter((filter) => filter !== e.target.value)) }} />
            <label className="ml-4">To your channel partners via landing pages</label>
          </div>
          <div className="flex items-center">
            <input type="checkbox" name="syndicationDestinations" value="not sure" onClick={(e) => { e.target.checked ? setSyndicationDestinations([...syndicationDestinations, e.target.value]) : setSyndicationDestinations(syndicationDestinations.filter((filter) => filter !== e.target.value)) }} />
            <label className="ml-4">Not sure</label>
          </div>
        </div>
        {syndicationDestinationsError && (_renderError(syndicationDestinationsError))}
      </QuestionContainer>
    )
  }

  const renderNumberOfRoomsQuestion = () => {
    const key = "numberOfRooms"
    return (
      <QuestionContainer title={QUESTIONS[key].text}>
        <input
          placeholder={QUESTIONS[key].placeholder}
          className={_textInputClasses()}
          value={numberOfRooms} onChange={(e) => setNumberOfRooms(e.target.value)}
          type="text"
          required={true}
        />
        {numberOfRoomsError && (_renderError(numberOfRoomsError))}
      </QuestionContainer>
    )
  }

  const renderBrandsInRoomsQuestion = () => {
    const key = "brandsInRooms"
    return (
      <QuestionContainer title={QUESTIONS[key].text}>
        <textarea
          placeholder={QUESTIONS[key].placeholder}
          className={_textAreaClasses()}
          value={brandsInRooms} onChange={(e) => setBrandsInRooms(e.target.value)}
          required={true}
          rows={4}
        ></textarea>
        {brandsInRoomsError && (_renderError(brandsInRoomsError))}
      </QuestionContainer>
    )
  }

  const renderProductsInRoomsQuestion = () => {
    const key = "productsInRooms"
    return (
      <QuestionContainer title={QUESTIONS[key].text}>
        <textarea
          placeholder={QUESTIONS[key].placeholder}
          className={_textAreaClasses()}
          value={productsInRooms} onChange={(e) => setProductsInRooms(e.target.value)}
          required={true}
          rows={4}
        ></textarea>
        {productsInRoomsError && (_renderError(productsInRoomsError))}
      </QuestionContainer>
    )
  }

  const renderProductUpdateFrequencyQuestion = () => {
    const key = "productUpdateFrequency"
    return (
      <QuestionContainer title={QUESTIONS[key].text}>
        <div className="flex flex-col gap-2 ">
          <div className="flex items-center">
            <input type="radio" name="productUpdateFrequency" value="Daily" onClick={(e) => { setProductUpdateFrequency(e.target.value) }} />
            <label className="ml-4">Daily</label>
          </div>
          <div className="flex items-center">
            <input type="radio" name="productUpdateFrequency" value="Weekly" onClick={(e) => { setProductUpdateFrequency(e.target.value) }} />
            <label className="ml-4">Weekly</label>
          </div>
          <div className="flex items-center">
            <input type="radio" name="productUpdateFrequency" value="Monthly" onClick={(e) => { setProductUpdateFrequency(e.target.value) }} />
            <label className="ml-4">Monthly</label>
          </div>
          <div className="flex items-center">
            <input type="radio" name="productUpdateFrequency" value="Quarterly" onClick={(e) => { setProductUpdateFrequency(e.target.value) }} />
            <label className="ml-4">Quarterly</label>
          </div>
          <div className="flex items-center">
            <input type="radio" name="productUpdateFrequency" value="Annually" onClick={(e) => { setProductUpdateFrequency(e.target.value) }} />
            <label className="ml-4">Annually</label>
          </div>
        </div>
        {productUpdateFrequencyError && (_renderError(productUpdateFrequencyError))}
      </QuestionContainer>
    )
  }

  const renderQuestions = () => {
    return (
      <>
        {/* human information */}
        {renderFullNameQuestion()}
        {renderCompanyQuestion()}
        {renderEmailQuestion()}

        {/* image selection */}
        {/* {renderVisionImageQuestion()} */}
        {renderStyleImageQuestion()}

        {/* files */}
        {renderBrandGuidelinesQuestion()}
        {renderPreviousCampaignsQuestion()}


        {/* text areas / text inputs */}
        {renderProhibitedItemsQuestion()}
        {renderSyndicationDestinationsQuestion()}
        {renderNumberOfRoomsQuestion()}
        {renderBrandsInRoomsQuestion()}
        {renderProductsInRoomsQuestion()}

        {/* radio inputs */}
        {renderProductUpdateFrequencyQuestion()}
      </>
    )
  }

  const renderButtonClasses = () => {
    if (formSubmissionError) {
      return `focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900`
    }
    if (formSubmissionRequesting) {
      return `text-white bg-blue-400 hover:bg-blue-500 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 cursor-not-allowed`
    }
    if (formSubmissionResponse) {
      return `focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800`
    }
    if (fieldErrorsPresent) {
      return `focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900`
    }
    return `text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800`
  }

  const renderButtonText = () => {
    if (formSubmissionError) {
      return `Something went wrong, please get in contact with us directly via email: prioritysupport@eyekandy.com`
    }
    if (formSubmissionRequesting) {
      return `Submitting...`
    }
    if (formSubmissionResponse) {
      return `Your answers have been recorded, thank you!`
    }
    if (fieldErrorsPresent) {
      return `There are missing answers, please check your submission.`
    }
    return "Submit"
  }

  //
  //  :render:
  //  Our main output for the component, renders the entire form.
  //  -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =

  return (
    <form className="w-full max-w-[512px] flex flex-col gap-4">
      {_isDev() ? <button type="button" className={renderButtonClasses()} onClick={onClickFillQuestions}>Fill Questions</button> : null}

      {formSubmissionResponse ? null : renderQuestions()}

      <button
        type="button"
        className={renderButtonClasses()}
        onClick={onClickSubmitForm}
      >{renderButtonText()}</button>

    </form>
  )
}