/*
  S H O W C A S E  P A G E
  Showcase page

  :description:
  This page contains the documentation on showcase

*/

//
//  :react & redux:
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import {
  configureAppCore,
  initialiseAppCoreAsync,
  selectInitialiseAppCore,
  selectIsConfigured
} from '../../../features/app-core/redux/appCoreSlice'

//
//  :components:
import { Content } from '../../../components/Content'
import { SlideShow } from './components/SlideShow'
import { APP_CORE } from '../../../features/app-core/redux/memory'

//
//  :statics:
import { SHOWCASE_LOCALISATION_ID_ENUMS } from './statics.js'
import { DownloadIcon } from '../svgs/svgs'

export const ARShowcaseV2 = () => {
  const [language, setLanguage] = useState('en-GB')
  const [isHovering, setIsHovering] = useState(false)

  //
  //  :redux:
  //  As this is a top level component we need to enable redux calls for app-core config.
  const dispatch = useDispatch()
  const isConfigured = useSelector(selectIsConfigured)
  const initialisedAppCore = useSelector(selectInitialiseAppCore)
  let [searchParams] = useSearchParams(window.location.href)

  //
  //  :app-core:
  //  We need to first configure app core.
  useEffect(() => {
    dispatch(configureAppCore())
  }, [dispatch])

  //
  //  Once configured, we can then handle initialising app-core.
  useEffect(() => {
    if (isConfigured) {
      dispatch(initialiseAppCoreAsync())
    }
  }, [dispatch, isConfigured])

  const getLocalisedText = id => {
    if (!id) {
      return
    }
    return APP_CORE.localiser.getLocalisation(id, language)
  }

  const getLanguageFromURL = () => {
    if (searchParams.has('eky-language')) {
      return searchParams.get('eky-language')
    } else {
      return 'en-GB'
    }
  }

  const LOCALISED_BANNER_MAPPING = {
    "en-GB": {
      desktop: "https://cdn.pointandplace.com/pages/showcase/images/promotional-template-banner-desktop-en.png",
      mobile: "https://cdn.pointandplace.com/pages/showcase/images/promotional-template-banner-mobile-en.png"
    },
    "fr-FR": {
      desktop: "https://cdn.pointandplace.com/pages/showcase/images/promotional-template-banner-desktop-fr.jpg",
      mobile: "https://cdn.pointandplace.com/pages/showcase/images/promotional-template-banner-mobile-fr.jpg"
    },
    "pt-PT": {
      desktop: "https://cdn.pointandplace.com/pages/showcase/images/promotional-template-banner-desktop-pt.jpg",
      mobile: "https://cdn.pointandplace.com/pages/showcase/images/promotional-template-banner-mobile-pt.jpg"
    },
    "es-ES": {
      desktop: "https://cdn.pointandplace.com/pages/showcase/images/promotional-template-banner-desktop-es.jpg",
      mobile: "https://cdn.pointandplace.com/pages/showcase/images/promotional-template-banner-mobile-es.jpg"
    },
    "de-DE": {
      desktop: "https://cdn.pointandplace.com/pages/showcase/images/promotional-template-banner-desktop-en.png",
      mobile: "https://cdn.pointandplace.com/pages/showcase/images/promotional-template-banner-mobile-en.png"
    },
    "ja-JA": {
      desktop: "https://cdn.pointandplace.com/pages/showcase/images/promotional-template-banner-desktop-en.png",
      mobile: "https://cdn.pointandplace.com/pages/showcase/images/promotional-template-banner-mobile-en.png"
    }
  }

  const downloadTemplates = () => {
    fetch(
      LOCALISED_BANNER_MAPPING[getLanguageFromURL()].desktop
    )
      .then(response => response.blob())
      .then(blob => {
        const url = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.download = `example-promotional-banner-desktop.png`
        link.href = url
        link.click()
        URL.revokeObjectURL(url)
      })

    fetch(
      LOCALISED_BANNER_MAPPING[getLanguageFromURL()].mobile
    )
      .then(response => response.blob())
      .then(blob => {
        const url = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.download = `example-promotional-banner-mobile.png`
        link.href = url
        link.click()
        URL.revokeObjectURL(url)
      })
  }

  const header = () => {
    return (
      <>
        <div className='py-[4rem]'>
          <h1 className='text-[31px] md:text-[60px] text-center font-bold'>
            eyekandy | AR Showcase
          </h1>
        </div>
      </>
    )
  }

  const objectives = () => {
    return (
      <>
        <div className='flex flex-col md:flex-row md:w-[85%]'>
          <div className='hidden md:flex w-full justify-between mb-9'>
            <div className='xl:w-[114rem]'>
              <h1 className='text-[48px] font-bold'>
                {getLocalisedText(
                  SHOWCASE_LOCALISATION_ID_ENUMS.objectiveTitle
                )}
              </h1>
              <p className='ml-5 mt-3 text-[20px] font-bold -indent-[15px]'>
                •{' '}
                {getLocalisedText(
                  SHOWCASE_LOCALISATION_ID_ENUMS.objectiveRevenues
                )}
              </p>
              <p className='ml-6 mt-3 text-[17px]'>
                {getLocalisedText(
                  SHOWCASE_LOCALISATION_ID_ENUMS.objectivesRevenuesSubText
                )}
              </p>
              <p className='ml-5 mt-3 text-[20px] font-bold -indent-[15px]'>
                •{' '}
                {getLocalisedText(
                  SHOWCASE_LOCALISATION_ID_ENUMS.objectivesConversion
                )}
              </p>
              <p className='ml-5 mt-3 text-[20px] font-bold -indent-[15px]'>
                •{' '}
                {getLocalisedText(
                  SHOWCASE_LOCALISATION_ID_ENUMS.objectivesShopperJourney
                )}
              </p>
              <p className='ml-5 mt-3 text-[20px] font-bold -indent-[15px]'>
                •{' '}
                {getLocalisedText(
                  SHOWCASE_LOCALISATION_ID_ENUMS.objectivesBuzz
                )}
              </p>
            </div>

            <div className='relative h-full w-full flex justify-end'>
              <div className='absolute bottom-0'>
                <p className='text-center text-[1.375rem] mb-2'>
                  Select Language
                </p>
                <div className='grid grid-cols-8 gap-4'>
                  <a
                    href={`${window.location.origin}${window.location.pathname}?eky-language=en-GB`}
                  >
                    <img
                      id='en-GB'
                      className='h-[2.5rem] cursor-pointer hover:scale-110 transition-all'
                      src='https://eyekandy.com/wp-content/uploads/2022/10/gb.svg'
                      alt=''
                      onClick={e => {
                        setLanguage(e.currentTarget.id)
                      }}
                    />
                  </a>
                  <a
                    href={`${window.location.origin}${window.location.pathname}?eky-language=fr-FR`}
                  >
                    <img
                      id='fr-FR'
                      className='h-[2.5rem] cursor-pointer hover:scale-110 transition-all'
                      src='https://eyekandy.com/wp-content/uploads/2022/10/fr.svg'
                      alt=''
                      onClick={e => {
                        setLanguage(e.currentTarget.id)
                      }}
                    />
                  </a>
                  <a
                    href={`${window.location.origin}${window.location.pathname}?eky-language=de-DE`}
                  >
                    <img
                      id='de-DE'
                      className='h-[2.5rem] cursor-pointer hover:scale-110 transition-all'
                      src='https://eyekandy.com/wp-content/uploads/2022/10/de.svg'
                      alt=''
                      onClick={e => {
                        setLanguage(e.currentTarget.id)
                      }}
                    />
                  </a>
                  <a
                    href={`${window.location.origin}${window.location.pathname}?eky-language=es-ES`}
                  >
                    <img
                      id='es-ES'
                      className='h-[2.5rem] cursor-pointer hover:scale-110 transition-all'
                      src='https://eyekandy.com/wp-content/uploads/2022/10/es.svg'
                      alt=''
                      onClick={e => {
                        setLanguage(e.currentTarget.id)
                      }}
                    />
                  </a>
                  <a
                    href={`${window.location.origin}${window.location.pathname}?eky-language=pt-PT`}
                  >
                    <img
                      id='pt-PT'
                      className='h-[2.5rem] cursor-pointer hover:scale-110 transition-all'
                      src='https://eyekandy.com/wp-content/uploads/2022/10/pt.svg'
                      alt=''
                      onClick={e => {
                        setLanguage(e.currentTarget.id)
                      }}
                    />
                  </a>
                  <a
                    href={`${window.location.origin}${window.location.pathname}?eky-language=ja-JA`}
                  >
                    <img
                      id='ja-JA'
                      className='h-[2.5rem] cursor-pointer hover:scale-110 transition-all'
                      src='https://cdn.pointandplace.com/pages/showcase/images/flags/jp-circle-flag-001.png'
                      alt=''
                      onClick={e => {
                        setLanguage(e.currentTarget.id)
                      }}
                    />
                  </a>
                  <a
                    href={`${window.location.origin}${window.location.pathname}?eky-language=ar-AR`}
                  >
                    <img
                      id='ar-AR'
                      className='h-[2.5rem] cursor-pointer hover:scale-110 transition-all'
                      src='https://cdn.pointandplace.com/pages/showcase/images/flags/ar-circle-flag-001.png'
                      alt=''
                      onClick={e => {
                        setLanguage(e.currentTarget.id)
                      }}
                    />
                  </a>
                  <a
                    href={`${window.location.origin}${window.location.pathname}?eky-language=kr-KR`}
                  >
                    <img
                      id='kr-KR'
                      className='h-[2.5rem] cursor-pointer hover:scale-110 transition-all'
                      src='https://cdn.pointandplace.com/pages/showcase/images/flags/kr-circle-flag-001.png'
                      alt=''
                      onClick={e => {
                        setLanguage(e.currentTarget.id)
                      }}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className='flex flex-col justify-center items-center md:hidden p-4'>
            <div>
              <p className='text-center text-[1.375rem] mb-2'>
                Select Language
              </p>
              <div className='grid grid-cols-8 gap-2'>
                <a
                  href={`${window.location.origin}${window.location.pathname}?eky-language=en-GB`}
                >
                  <img
                    id='en-GB'
                    className='h-[2.5rem] cursor-pointer hover:scale-110 transition-all'
                    src='https://eyekandy.com/wp-content/uploads/2022/10/gb.svg'
                    alt=''
                    onClick={e => {
                      setLanguage(e.currentTarget.id)
                    }}
                  />
                </a>
                <a
                  href={`${window.location.origin}${window.location.pathname}?eky-language=fr-FR`}
                >
                  <img
                    id='fr-FR'
                    className='h-[2.5rem] cursor-pointer hover:scale-110 transition-all'
                    src='https://eyekandy.com/wp-content/uploads/2022/10/fr.svg'
                    alt=''
                    onClick={e => {
                      setLanguage(e.currentTarget.id)
                    }}
                  />
                </a>
                <a
                  href={`${window.location.origin}${window.location.pathname}?eky-language=de-DE`}
                >
                  <img
                    id='de-DE'
                    className='h-[2.5rem] cursor-pointer hover:scale-110 transition-all'
                    src='https://eyekandy.com/wp-content/uploads/2022/10/de.svg'
                    alt=''
                    onClick={e => {
                      setLanguage(e.currentTarget.id)
                    }}
                  />
                </a>
                <a
                  href={`${window.location.origin}${window.location.pathname}?eky-language=es-ES`}
                >
                  <img
                    id='es-ES'
                    className='h-[2.5rem] cursor-pointer hover:scale-110 transition-all'
                    src='https://eyekandy.com/wp-content/uploads/2022/10/es.svg'
                    alt=''
                    onClick={e => {
                      setLanguage(e.currentTarget.id)
                    }}
                  />
                </a>
                <a
                  href={`${window.location.origin}${window.location.pathname}?eky-language=pt-PT`}
                >
                  <img
                    id='pt-PT'
                    className='h-[2.5rem] cursor-pointer hover:scale-110 transition-all'
                    src='https://eyekandy.com/wp-content/uploads/2022/10/pt.svg'
                    alt=''
                    onClick={e => {
                      setLanguage(e.currentTarget.id)
                    }}
                  />
                </a>
                <a
                  href={`${window.location.origin}${window.location.pathname}?eky-language=ja-JA`}
                >
                  <img
                    id='ja-JA'
                    className='h-[2.5rem] cursor-pointer hover:scale-110 transition-all'
                    src='https://cdn.pointandplace.com/pages/showcase/images/flags/jp-circle-flag-001.png'
                    alt=''
                    onClick={e => {
                      setLanguage(e.currentTarget.id)
                    }}
                  />
                </a>
                <a
                  href={`${window.location.origin}${window.location.pathname}?eky-language=ar-AR`}
                >
                  <img
                    id='ar-AR'
                    className='h-[2.5rem] cursor-pointer hover:scale-110 transition-all'
                    src='https://cdn.pointandplace.com/pages/showcase/images/flags/ar-circle-flag-001.png'
                    alt=''
                    onClick={e => {
                      setLanguage(e.currentTarget.id)
                    }}
                  />
                </a>
                <a
                  href={`${window.location.origin}${window.location.pathname}?eky-language=kr-KR`}
                >
                  <img
                    id='kr-KR'
                    className='h-[2.5rem] cursor-pointer hover:scale-110 transition-all'
                    src='https://cdn.pointandplace.com/pages/showcase/images/flags/kr-circle-flag-001.png'
                    alt=''
                    onClick={e => {
                      setLanguage(e.currentTarget.id)
                    }}
                  />
                </a>
              </div>
            </div>

            <div className='mt-9'>
              <h1 className='text-[31px] font-bold'>
                {getLocalisedText(
                  SHOWCASE_LOCALISATION_ID_ENUMS.objectiveTitle
                )}
              </h1>
              <p className='ml-5 mt-3 text-[20px] font-bold -indent-[15px]'>
                •{' '}
                {getLocalisedText(
                  SHOWCASE_LOCALISATION_ID_ENUMS.objectiveRevenues
                )}
              </p>
              <p className='ml-6 mt-3 text-[17px]'>
                {getLocalisedText(
                  SHOWCASE_LOCALISATION_ID_ENUMS.objectivesRevenuesSubText
                )}
              </p>
              <p className='ml-5 mt-3 text-[20px] font-bold -indent-[15px]'>
                •{' '}
                {getLocalisedText(
                  SHOWCASE_LOCALISATION_ID_ENUMS.objectivesConversion
                )}
              </p>
              <p className='ml-5 mt-3 text-[20px] font-bold -indent-[15px]'>
                •{' '}
                {getLocalisedText(
                  SHOWCASE_LOCALISATION_ID_ENUMS.objectivesShopperJourney
                )}
              </p>
              <p className='ml-5 mt-3 text-[20px] font-bold -indent-[15px]'>
                •{' '}
                {getLocalisedText(
                  SHOWCASE_LOCALISATION_ID_ENUMS.objectivesBuzz
                )}
              </p>
            </div>
          </div>
        </div>
      </>
    )
  }

  const whatWeDo = () => {
    return (
      <>
        <div className='bg-white h-fit w-full flex justify-center md:p-4'>
          <div className='w-[90%] md:max-w-[85%]'>
            <h1 className='text-[#0CB7EB] text-[38px] md:text-[64px] py-7'>
              {getLocalisedText(SHOWCASE_LOCALISATION_ID_ENUMS.whatWeDoTitle)}
            </h1>
            <div className='flex flex-col md:flex-row text-black w-full md:justify-between'>
              <div className='md:w-[40%]'>
                <p className='text-[22px] -indent-[15px]'>
                  •{' '}
                  {getLocalisedText(
                    SHOWCASE_LOCALISATION_ID_ENUMS.whatWeDoDesign
                  )}
                </p>
                <p className='text-[#363A48]'>
                  {getLocalisedText(
                    SHOWCASE_LOCALISATION_ID_ENUMS.whatWeDoDesignBody
                  )}
                </p>
                <p className='mt-3 text-[22px] -indent-[15px]'>
                  •{' '}
                  {getLocalisedText(
                    SHOWCASE_LOCALISATION_ID_ENUMS.whatWeDoAdvertise
                  )}
                </p>
                <p className='text-[#363A48]'>
                  {getLocalisedText(
                    SHOWCASE_LOCALISATION_ID_ENUMS.whatWeDoAdvertiseBody
                  )}
                </p>
              </div>
              <div className='md:w-[40%] mt-3 md:mt-0'>
                <p className='text-[22px] -indent-[15px]'>
                  •{' '}
                  {getLocalisedText(
                    SHOWCASE_LOCALISATION_ID_ENUMS.whatWeDoFund
                  )}
                </p>
                <p className='text-[#363A48]'>
                  {getLocalisedText(
                    SHOWCASE_LOCALISATION_ID_ENUMS.whatWeDoFundBody
                  )}
                </p>
                <p className='mt-3 text-[22px] -indent-[15px]'>
                  •{' '}
                  {getLocalisedText(
                    SHOWCASE_LOCALISATION_ID_ENUMS.whatWeDoMaintenance
                  )}
                </p>
                <p className='text-[#363A48]'>
                  {getLocalisedText(
                    SHOWCASE_LOCALISATION_ID_ENUMS.whatWeDoMaintenanceBody
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  const whatYouDo = () => {
    return (
      <>
        <div className='bg-white h-fit w-full flex justify-center md:p-4'>
          <div className='w-[90%] md:max-w-[85%] mb-6'>
            <h1 className='text-[#0CB7EB] text-[38px] md:text-[64px] py-7'>
              {getLocalisedText(SHOWCASE_LOCALISATION_ID_ENUMS.whatYouDoTitle)}
            </h1>
            <div className='flex flex-col md:flex-row w-full text-black'>
              <div className='mr-[1rem]'>
                <p className='text-[22px] -indent-[15px]'>
                  •{' '}
                  {getLocalisedText(
                    SHOWCASE_LOCALISATION_ID_ENUMS.whatYouDoPromote
                  )}
                </p>
                <p className='text-[#363A48]'>
                  {getLocalisedText(
                    SHOWCASE_LOCALISATION_ID_ENUMS.whatYouDoPromoteBody
                  )}
                </p>
                <p className='mt-6 text-[22px] -indent-[15px]'>
                  •{' '}
                  {getLocalisedText(
                    SHOWCASE_LOCALISATION_ID_ENUMS.whatYouDoMarketing
                  )}
                </p>
                <p className='text-[#363A48]'>
                  {getLocalisedText(
                    SHOWCASE_LOCALISATION_ID_ENUMS.whatYouDoMarketingSubText
                  )}
                </p>
                <div className='ml-3 mt-6'>
                  <p className='mt-1 text-[#363A48]'>
                    •{' '}
                    {getLocalisedText(
                      SHOWCASE_LOCALISATION_ID_ENUMS.whatYouDoBanners
                    )}
                  </p>
                  <p className='mt-2 text-[#363A48]'>
                    •{' '}
                    {getLocalisedText(
                      SHOWCASE_LOCALISATION_ID_ENUMS.whatYouDoEmail
                    )}
                  </p>
                  <p className='mt-2 text-[#363A48]'>
                    •{' '}
                    {getLocalisedText(
                      SHOWCASE_LOCALISATION_ID_ENUMS.whatYouDoReceipts
                    )}
                  </p>
                  <p className='mt-2 text-[#363A48]'>
                    •{' '}
                    {getLocalisedText(
                      SHOWCASE_LOCALISATION_ID_ENUMS.whatYouDoPrinted
                    )}
                  </p>
                  <div className='md:hidden flex flex-col'>
                    <p className='mt-2 text-[#363A48]'>
                      •{' '}
                      {getLocalisedText(
                        SHOWCASE_LOCALISATION_ID_ENUMS.whatYouDoQr
                      )}
                    </p>
                    <p className='mt-2 text-[#363A48]'>
                      •{' '}
                      {getLocalisedText(
                        SHOWCASE_LOCALISATION_ID_ENUMS.whatYouDoCampaign
                      )}
                    </p>
                    <p className='mt-2 text-[#363A48]'>
                      •{' '}
                      {getLocalisedText(
                        SHOWCASE_LOCALISATION_ID_ENUMS.whatYouDoReceipts
                      )}
                    </p>
                    <p className='mt-2 text-[#363A48]'>
                      •{' '}
                      {getLocalisedText(
                        SHOWCASE_LOCALISATION_ID_ENUMS.whatYouDoPrinted
                      )}
                    </p>
                    <div className='max-w-[40rem] flex justify-center items-center mt-2'>
                      <div
                        className='w-[4rem] h-[4rem] rounded-full bg-[#0CB7EB] hover:bg-white hover:border-2 hover:border-[#0CB7EB] flex justify-center items-center cursor-pointer'
                        onMouseEnter={() => { setIsHovering(true) }}
                        onMouseLeave={() => { setIsHovering(false) }}
                        onClick={() => { downloadTemplates() }}>
                        <DownloadIcon fill={isHovering ? "#0CB7EB" : "white"}></DownloadIcon>
                      </div>
                    </div>
                    <img
                      className='hover:scale-125 transition-all mt-3'
                      src={`${LOCALISED_BANNER_MAPPING[getLanguageFromURL()].desktop}`}
                      alt=''
                    />
                    <img
                      className='mt-3 hover:scale-125 transition-all'
                      src={`${LOCALISED_BANNER_MAPPING[getLanguageFromURL()].mobile}`}
                      alt=''
                    />
                  </div>
                </div>
                <a
                  href={`https://ar-showcase.pointandplace.com/1e0c13f9-f51b-4990-a2c4-9f81cfc1a0ba/ie/landing?eky-prices&eky-language=${getLanguageFromURL()}`}
                >
                  <p className='mt-6 text-[22px] -indent-[15px] underline'>
                    •{' '}
                    {getLocalisedText(
                      SHOWCASE_LOCALISATION_ID_ENUMS.whatYouDoShare
                    )}
                  </p>
                </a>
                <p className='text-[#363A48]'>
                  {getLocalisedText(
                    SHOWCASE_LOCALISATION_ID_ENUMS.whatYouDoShareBody
                  )}
                </p>
              </div>
              <div className='hidden md:flex md:flex-col h-fit'>
                <div className='w-[29rem] flex justify-center items-center mb-3'>
                  <div
                    className='w-[4rem] h-[4rem] rounded-full bg-[#0CB7EB] hover:bg-white hover:border-2 hover:border-[#0CB7EB] flex justify-center items-center cursor-pointer'
                    onMouseEnter={() => { setIsHovering(true) }}
                    onMouseLeave={() => { setIsHovering(false) }}
                    onClick={() => { downloadTemplates() }}>
                    <DownloadIcon fill={isHovering ? "#0CB7EB" : "white"}></DownloadIcon>
                  </div>
                </div>
                <img
                  className='lg:max-w-[30rem] md:max-w-[20rem] hover:scale-125 transition-all'
                  src={`${LOCALISED_BANNER_MAPPING[getLanguageFromURL()].desktop}`}
                  alt=''
                />
                <img
                  className='lg:max-w-[30rem] md:max-w-[20rem] mt-3 hover:scale-125 transition-all'
                  src={`${LOCALISED_BANNER_MAPPING[getLanguageFromURL()].mobile}`}
                  alt=''
                />
                <p className='mt-2 text-[#363A48]'>
                  •{' '}
                  {getLocalisedText(SHOWCASE_LOCALISATION_ID_ENUMS.whatYouDoQr)}
                </p>
                <p className='mt-2 text-[#363A48]'>
                  •{' '}
                  {getLocalisedText(
                    SHOWCASE_LOCALISATION_ID_ENUMS.whatYouDoCampaign
                  )}
                </p>
                <p className='mt-2 text-[#363A48]'>
                  •{' '}
                  {getLocalisedText(
                    SHOWCASE_LOCALISATION_ID_ENUMS.whatYouDoReceipts
                  )}
                </p>
                <p className='mt-2 text-[#363A48]'>
                  •{' '}
                  {getLocalisedText(
                    SHOWCASE_LOCALISATION_ID_ENUMS.whatYouDoPrinted
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  const commercial = () => {
    return (
      <>
        <div className='h-fit w-full flex justify-center md:p-4'>
          <div className='w-[90%] md:max-w-[85%] mb-1'>
            <h1 className='text-white text-[38px] md:text-[64px] py-7'>
              {getLocalisedText(SHOWCASE_LOCALISATION_ID_ENUMS.commercialTitle)}
            </h1>
            <div className='flex flex-col text-white xl:max-w-[60%]'>
              <p className='text-[22px] font-bold'>
                •{' '}
                {getLocalisedText(
                  SHOWCASE_LOCALISATION_ID_ENUMS.commercialSetUpFee
                )}
              </p>
              <p className='ml-3'>
                {getLocalisedText(
                  SHOWCASE_LOCALISATION_ID_ENUMS.commercialSetUpFeeBody
                )}
              </p>
              <p className='text-[22px] mt-6 font-bold'>
                •{' '}
                {getLocalisedText(
                  SHOWCASE_LOCALISATION_ID_ENUMS.commercialMaintenance
                )}
              </p>
              <p className='ml-3'>
                {getLocalisedText(
                  SHOWCASE_LOCALISATION_ID_ENUMS.commercialMaintenanceBody
                )}
              </p>
              <p className='text-[22px] mt-6 font-bold'>
                •{' '}
                {getLocalisedText(
                  SHOWCASE_LOCALISATION_ID_ENUMS.commercialRevenue
                )}
              </p>
              <p className='text-[27px] mt-[3rem] font-bold'>
                {getLocalisedText(
                  SHOWCASE_LOCALISATION_ID_ENUMS.commercialRemember
                )}
              </p>
            </div>
          </div>
        </div>
      </>
    )
  }

  const showcaseContent = () => {
    return (
      <>
        {header()}
        {objectives()}
        {whatWeDo()}
        {whatYouDo()}
        {commercial()}
        <SlideShow getLocalisedText={getLocalisedText}></SlideShow>
      </>
    )
  }

  return (
    <>
      <Helmet>
        <title>eyekandy | AR Showcase</title>
        <meta property='og:title' content='eyekandy | AR Showcase' />
        <meta name='description' content='' />
        <meta name='og:description' content='' />
      </Helmet>
      <Content classes='w-full flex flex-col items-center bg-[#0cb7eb]'>
        {initialisedAppCore && showcaseContent()}
      </Content>
    </>
  )
}
