

export const SHOWCASE_LOCALISATION_ID_ENUMS = {
  //
  //  :objectives:
  objectiveTitle: "objectives-title",
  objectiveRevenues: "objectives-revenues",
  objectivesRevenuesSubText: "objectives-revenues-sub-text",
  objectivesConversion: "objectives-conversion",
  objectivesShopperJourney: "objectives-shopper-journey",
  objectivesBuzz: "objectives-buzz",

  //
  //  :what we do:
  whatWeDoTitle: "what-we-do-title",
  whatWeDoDesign: "what-we-do-design",
  whatWeDoDesignBody: "what-we-do-design-body",
  whatWeDoAdvertise: "what-we-do-advertise",
  whatWeDoAdvertiseBody: "what-we-do-advertise-body",
  whatWeDoFund: "what-we-do-fund",
  whatWeDoFundBody: "what-we-do-fund-body",
  whatWeDoMaintenance: "what-we-do-maintenance",
  whatWeDoMaintenanceBody: "what-we-do-maintenance-body",

  //
  //  :what you do:
  whatYouDoTitle: "what-you-do-title",
  whatYouDoPromote: "what-you-do-promote",
  whatYouDoPromoteBody: "what-you-do-promote-body",
  whatYouDoMarketing: "what-you-do-marketing",
  whatYouDoMarketingSubText: "what-you-do-marketing-sub-text",
  whatYouDoBanners: "what-you-do-banners",
  whatYouDoEmail: "what-you-do-email",
  whatYouDoReceipts: "what-you-do-receipts",
  whatYouDoPrinted: "what-you-do-printed",
  whatYouDoQr: "what-you-do-qr",
  whatYouDoCampaign: "what-you-do-campaign",
  whatYouDoShare: "what-you-do-share",
  whatYouDoShareBody: "what-you-do-share-body",

  //
  //  :commercial:
  commercialTitle: "commercial-title",
  commercialSetUpFee: "commercial-set-up-fee",
  commercialSetUpFeeBody: "commercial-set-up-fee-body",
  commercialMaintenance: "commercial-maintenance",
  commercialMaintenanceBody: "commercial-maintenance-body",
  commercialRevenue: "commercial-revenue",
  commercialRemember: "commercial-remember",

  //
  //  :go live:
  goLiveTitle: "go-live-title",

  //
  //  :step one:
  stepOneDedicatedUrl: "step-one-dedicated-url",
  stepOneExample: "step-one-example",
  stepOnePreview: "step-one-preview",
  stepOneIntegrate: "step-one-integrate",
  stepOneIntegrated: "step-one-integrated",
  stepOneIntegratedBody: "step-one-integrated-body",
  stepOneNoIntergration: "step-one-no-intergration",
  stepOneNoIntergrationBody: "step-one-no-intergration-body",

  //
  //  :step two:
  stepTwoFeed: "step-two-feed",
  stepTwoFeedRequirementsbBrand: "step-two-feed-requirements-brand",
  stepTwoFeedRequirementsModelName: "step-two-feed-requirements-model-name",
  stepTwoFeedRequirementsCategory: "step-two-feed-requirements-category",
  stepTwoFeedRequirementsSku: "step-two-feed-requirements-sku",
  stepTwoFeedRequirementsPrice: "step-two-feed-requirements-price",
  stepTwoFeedRequirementsImage: "step-two-feed-requirements-image",
  stepTwoFeedRequirementsProductPage: "step-two-feed-requirements-product-page",
  stepTwoFeedRequirementsPath: "step-two-feed-requirements-path",
  stepTwoFeedUpdate: "step-two-feed-update",
  stepTwoEmailUs: "step-two-email-us",

  //
  //  :step three
  stepThreePopularProducts: "step-three-popular-products",
  stepThreePopularProductsBody: "step-three-popular-products-body",
  stepThreeMinimum: "step-three-minimum"
}