/*

  
  

  :description:
  The Home component.

*/

//
//  :react & redux:
import React, { useState } from "react";
import { getApiUrl } from "../../endpoints";

//
//  :components:


//
//  :component:
export const StateOfTheIndustryBeveragesPageForm = (props) => {

  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [title, setTitle] = useState("")
  const [company, setCompany] = useState("")

  const [formWasSubmitted, setFormWasSubmitted] = useState(false)
  const [formSubmitError, setFormSubmitError] = useState(false)

  const API_URL = getApiUrl()

  const onClickSubmitForm = async (event) => {
    event.preventDefault()

    if (!firstName) {
      alert(`First name is required`)
      return
    }
    if (!email) {
      alert(`Email is required`)
      return
    }
    if (!title) {
      alert(`Title is required`)
      return
    }
    if (!company) {
      alert(`Company is required`)
      return
    }
    if(!validateEmail(email)){
      alert(`Email is not valid`)
      return
    }
    

    //
    //  :step 1:
    //  Assemble our payload.
    const payload = {
      name: `${firstName.trim()} ${lastName.trim()}`.trim(),
      email: email,
      title: title,
      company: company
    }

    //
    //  :step 2:
    //  Post the payload to the API.
    try {
      const response = await fetch(API_URL, {
        method: "post",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      })
      //
      //  If the send was successful, set the notification div.
      if (response.status === 200) {
        setFormWasSubmitted(true)
        //
        //  Disable our button and reset the form.
        resetFormInputs()
        disableSubmitButton(event)
      }
      const data = await response.json()
      console.log(data)
    } catch (err) {
      //
      //  Mark that there was an error when submitting the form.
      setFormSubmitError(true)
      //
      //  Disable the button and reset the form.
      resetFormInputs()
      disableSubmitButton(event)
    }
  }

  const validateEmail = (emailString) => {
    return String(emailString)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const resetFormInputs = () => {
    setTitle("")
    setLastName("")
    setFirstName("")
    setEmail("")
    setCompany("")
  }

  const disableSubmitButton = (event) => {
    event.target.setAttribute("disabled", "true")
    event.target.classList.add("cursor-not-allowed")
    event.target.classList.add("disabled")  }

  return (
    <section name="form" className={`bg-gray-100 lg:bg-transparent px-4 sm:px-8 md:px-32 lg:px-0 xl:px-4 flex lg:max-w-[425px]`}>
      <div id="form" className="form pt-8  w-full">
        <div className="form-header p-10 pt-6 pb-12 bg-eyekandyPurple rounded-2xl -translate-y-24 lg:translate-y-0">
          <p className="text-2xl text-center md:text-3xl lg:text-3xl font-comfortaa text-white">Get your free copy today!
          </p>
        </div>
        <div className="form-content bg-white w-full -mt-8 rounded-2xl p-10 -translate-y-24 lg:translate-y-0 font-[Inter]">
          <form id="submitForm" method="POST" className={`${formWasSubmitted ? 'opacity-50' : ''}`}>
            <div className="relative z-0 w-full mb-6 group">
              <input value={firstName} onChange={(e) => setFirstName(e.target.value)} type="text" name="floating_first_name" id="floating_first_name" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
              <label htmlFor="floating_first_name" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-2 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">First
                name</label>
            </div>
            <div className="relative z-0 w-full mb-6 group">
              <input value={lastName} onChange={(e) => setLastName(e.target.value)} type="text" name="floating_last_name" id="floating_last_name" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
              <label htmlFor="floating_last_name" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-2 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Last
                name</label>
            </div>

            <div className="relative z-0 w-full mb-6 group">
              <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" name="floating_email" id="floating_email" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
              <label htmlFor="floating_email" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-2 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Company
                Email</label>
            </div>

            <div className="relative z-0 w-full mb-6 group">
              <input value={title} onChange={(e) => setTitle(e.target.value)} type="text" name="floating_title" id="floating_title" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
              <label htmlFor="floating_title" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-2 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Title</label>
            </div>
            <div className="relative z-0 w-full mb-6 group">
              <input value={company} onChange={(e) => setCompany(e.target.value)} type="text" name="floating_company" id="floating_company" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
              <label htmlFor="floating_company" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-2 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Company</label>
            </div>
            <button onClick={onClickSubmitForm} type="submit" id="submit-form" className="text-white bg-eyekandyPurple  hover:bg-eyekandyPurple focus:ring-4 focus:outline-none focus:ring-blue-300 text-xl rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Submit</button>
          </form>
          <div id="form-sent-success" className={`w-full ${formWasSubmitted ? 'flex' : 'hidden'}`}>
            <p className="text-black text-sm mt-4 text-green-700">Thanks for you request, we will send you the report shortly.</p>
          </div>
          <div id="form-sent-error" className={`w-full ${formSubmitError ? 'flex' : 'hidden'}`}>
            <p className="mt-4 text-sm text-red-700">Something went wrong and we couldn't submit the information to eyekandy. <br/><br/> Please email us directly at "prioritysupport@eyekandy.com" and request the "State of the Industry - Beverages" report.</p>
          </div>
        </div>
      </div>
    </section>
  )
}