/*

  f e a t u r e s  -  a u t h  S l i c e
  Features - auth Slice

  :description:
  Our main interface between our auth API and the Redux state.

*/

//
//  :react & redux:
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

//
//  :code:
import { LOCAL_STORAGE_TOKEN_KEY } from '../../settings';
import { login } from './authAPI';

//
//  :statics:

//
//  :functions:
export const decodeToken = (token) => {
  return JSON.parse(atob(token.split(".")[1]))
}

export const getToken = () => {
  return localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)
}

//
//  :state:
//  Our initial state object.
const initialState = {
  username: localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY) ? decodeToken(localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)).sub : null,
  role: localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY) ? decodeToken(localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)).role : null,
  isLoggedIn: localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY) ? true : false,
  login: {
    status: "idle",
    requesting: false,
    error: null,
    response: null,
  }
};

//
//  :async actions:
export const loginAsync = createAsyncThunk(
  'auth/login',
  async (credentials) => {
    const response = await login(credentials);
    return response.token || null;
  }
);


//
//  :reducer:
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: state => {
      state.login.status = "idle"
      state.login.error = null
      state.login.response = null
      state.isLoggedIn = false
      state.username = null
      state.role = null
      //
      //  Delete the token in storage.
      localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.login.status = 'requesting';
        state.login.requesting = true
        state.login.error = null
        state.login.response = null
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        state.login.status = 'idle';
        state.login.requesting = false
        state.login.response = action.payload
        if (action.payload) {
          const token = decodeToken(action.payload)
          state.username = token.sub
          state.role = token.role
          state.isLoggedIn = true
          localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, action.payload)
        }
      })
      .addCase(loginAsync.rejected, (state, action) => {
        state.login.status = 'error';
        state.login.requesting = false
        state.login.error = action.error
      })
  },
});

//
//  :sync actions:
export const { logout } = authSlice.actions;

//
//  :exports:
//  :core:
export const selectLoginUsername = (state) => state.auth.username;
export const selectLoginRole = (state) => state.auth.role;
export const selectLoginIsLoggedIn = (state) => state.auth.isLoggedIn;

//
//  :login:
export const selectLoginStatus = (state) => state.auth.login.status;
export const selectLoginRequesting = (state) => state.auth.login.requesting;
export const selectLoginError = (state) => state.auth.login.error;
export const selectLoginResponse = (state) => state.auth.login.response;



//
//  :exports:
export default authSlice.reducer;
