/*

  
  

  :description:
  The Home component.

*/

//
//  :react & redux:
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

//
//  :components:
import { Content } from "../../components/Content";
import { FullScreenImage } from "../../components/ui/FullScreenImage";
import { ScrollToBottom } from "../../components/ui/ScrollToBottom";
import { StateOfTheIndustryBeveragesPageCalendly } from "./Calendly";
import { StateOfTheIndustryBeveragesPageForm } from "./Form";

//
//  :component:
export const StateOfTheIndustryBeveragesPage = (props) => {

  const getScreenSize = () => {
    return window.innerWidth >= 1024 ? "desktop" : "mobile"
  }

  const [displayMode, setDisplayMode] = useState(getScreenSize())

  // eslint-disable-next-line 
  const onResizePageHandler = (event) => {
    if (getScreenSize() !== displayMode) {
      setDisplayMode(getScreenSize())
    }
  }

  // eslint-disable-next-line 
  useEffect(() => {
    window.addEventListener("resize", onResizePageHandler)
    // eslint-disable-next-line 
  }, [onResizePageHandler])

  const renderDesktopPage = () => {
    return <>
      {/* :background-image: */}
      <FullScreenImage src={`/cdn/pages/state-of-the-industry/beverages/bg-${getScreenSize()}.jpg`}>
        <section name="landing" className="w-full min-h-screen h-full flex justify-evenly items-center text-white relative max-w-[1100px]">
          <div className="absolute top-4 left-4 pt-8">
            <h2 className="company-name text-3xl text-white font-comfortaa text-white font-[600] ">eyekandy</h2>
          </div>

          <div className="left flex-1 flex flex-col gap-4 pl-4">
            <h1 className="intro-text text-5xl text-white font-comfortaa text-white font-[600]">
              Enhancing customer engagement with AR</h1>
            <p className="description font-[Inter] mr-32">Discover the key consumer and business trends behind the
              accelerating
              adoption of Augmented Reality, as
              well as global insights on how Wine, Beer and Spirits brands have leveraged AR to significantly enhance
              marketing strategies across consumer and B2B channels.</p>
          </div>

          <div className="right flex-1 drop-shadow-lg flex justify-center">
            <StateOfTheIndustryBeveragesPageForm />
          </div>



        </section>
        <ScrollToBottom></ScrollToBottom>
      </FullScreenImage>

      <section name="contact" id="contact" className="w-full min-h-screen h-full flex flex-col items-center bg-[#363a48] justify-center py-8 px-4 ">
        <div className="justify-between text-black flex text-white relative drop-shadow-lg w-full max-w-[800px] pt-8">


          <div className="flex justify-between items-start">
            <p className="text-4xl font-[550] font-comfortaa text-[#0bb6eb] bg-transparent">Book a call, send us
              an
              email or,
              connect
              on LinkedIn.</p>

            <div className="contact w-full flex flex-col gap-4 ">
              <a className="email-us text-white font-normal underline decoration-white"
                href="mailto:letstalk@eyekandy.com?subject=State%20of%20the%20Industry%20-%20Beverages">letstalk@eyekandy.com</a>
              <span className="call-us text-white font-normal underline decoration-white">+ 44 (0) 203 355 7554</span>
              <span className="call-us text-white font-normal underline decoration-white">+ 1 (855) 326 2237</span>
              <a className="linkedin cursor-pointer text-white font-normal underline decoration-white rounded-md w-8 overflow-hidden"
                href="https://www.linkedin.com/company/eyekandy/" rel="noreferrer" target="_blank">
                <svg className="w-8 flex-inline" xmlns="http://www.w3.org/2000/svg"
                  width="32" height="32" viewBox="0 0 24 24">
                  <path style={{ fill: "white" }}
                    d="M0 0v24h24v-24h-24zm8 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.397-2.586 7-2.777 7 2.476v6.759z" />
                </svg>
              </a>
            </div>

            <div className="location  w-full  flex flex-col gap-4 ">
              <div>
                <p className="street-address text-white">34-37 Liverpool St, London,</p>
                <p className="postcode text-white">EC2M 7PP</p>
              </div>
              <a className="find-us-google-maps text-white underline decoration-white cursor-pointer" rel="noreferrer" target="_blank"
                href="https://www.google.com/maps/place/eyekandy/@51.5169973,-0.0837787,17z/data=!3m2!4b1!5s0x4875fc00103c8831:0x28813f13cf279d7d!4m5!3m4!1s0x48761136c632eb5f:0x1a822f948ee91f71!8m2!3d51.5169973!4d-0.08159">
                <div className="flex gap-2 items-center">
                  <span>Find us</span>
                  <div className="w-8">
                    <svg xmlns="http://www.w3.org/2000/svg" style={{ fill: "white", enableBackground: "new 0 0 612 612" }} viewBox="0 0 612 612">
                      <path
                        d="m516.316 337.52 94.233 193.581c3.832 7.873-.196 14.314-8.952 14.314H10.402c-8.756 0-12.785-6.441-8.952-14.314L95.684 337.52c1.499-3.079 5.528-5.599 8.952-5.599h80.801c2.49 0 5.853 1.559 7.483 3.442 5.482 6.335 11.066 12.524 16.634 18.65 5.288 5.815 10.604 11.706 15.878 17.735h-95.891c-3.425 0-7.454 2.519-8.952 5.599L58.163 505.589h495.67l-62.421-128.242c-1.498-3.08-5.527-5.599-8.953-5.599h-96.108c5.273-6.029 10.591-11.92 15.879-17.735 5.585-6.144 11.2-12.321 16.695-18.658 1.628-1.878 4.984-3.434 7.47-3.434h80.971c3.423 0 7.451 2.518 8.95 5.599zm-71.775-132.292c0 105.776-88.058 125.614-129.472 227.265-3.365 8.26-14.994 8.218-18.36-.04-37.359-91.651-112.638-116.784-127.041-198.432-14.181-80.379 41.471-159.115 122.729-166.796 82.64-7.812 152.144 56.979 152.144 138.003zm-65.427 0c0-40.436-32.779-73.216-73.216-73.216s-73.216 32.78-73.216 73.216c0 40.437 32.779 73.216 73.216 73.216s73.216-32.779 73.216-73.216z" />
                    </svg>
                  </div>
                </div>
              </a>
            </div>

          </div>


        </div>
        <div className=" flex-1 w-full flex items-center justify-center">
          <StateOfTheIndustryBeveragesPageCalendly />
        </div>


        <a rel="noreferrer" target="_blank" className="mt-8 mb-6 opacity-50" href="https://eyekandy.com"><span >&copy; eyekandy 2022</span></a>
      </section>
    </>
  }

  const renderMobilePage = () => {
    return <>
      {/* :background-image: */}
      <FullScreenImage heightClass="h-screen-2/3" src={`/cdn/pages/state-of-the-industry/beverages/bg-${getScreenSize()}.jpg`}>
        <section name="landing" className="relative flex flex-col h-full items-center justify-between py-8">
          <h2 className="company-name text-2xl text-white font-comfortaa text-white font-[600] text-center">eyekandy
          </h2>
          <h1 className="intro-text text-3xl w-80 text-white font-comfortaa text-white font-[550] text-center px-8">
            Enhancing customer engagement with AR</h1>
        </section>
      </FullScreenImage>

      {/* :summary text: */}
      <section name="summary" id="contact" className="flex flex-col text-black bg-white py-20 px-8 md:px-32">
        <p className="description text-center font-[Inter] pb-8">Discover the key consumer and business trends behind the
          accelerating
          adoption of Augmented Reality, as
          well as global insights on how Wine, Beer and Spirits brands have leveraged AR to significantly enhance
          marketing strategies across consumer and B2B channels.</p>
      </section>

      {/* :request-deck-form: */}
      <StateOfTheIndustryBeveragesPageForm />

      {/* :contact: */}
      <section name="footer" className="content-book bg-[#363a48] flex flex-col items-center pb-16">
        <div className="w-full flex flex justify-center">
          <img className="h-[89px] w-10 -translate-y-9" src="/cdn/img/mobile-arrow.png" alt="Arrow pointing down" />
        </div>
        <div className="w-80">

          {/* :call to action: */}
          <div className="text-left w-full mb-4 ">
            <p className="text-[24px] font-bold font-comfortaa text-[#0bb6eb] bg-transparent p-[1rem]">Book a call, send us
              an
              email or,
              connect
              on LinkedIn.</p>
          </div>

          {/* :calendly: */}
          <StateOfTheIndustryBeveragesPageCalendly />

          {/* :contact info: */}
          <div className="details w-full">
            <hr className="mt-6 mb-6" />
            <div className="contact pl-4 w-full flex flex-col gap-2 mb-16">
              <a className="email-us text-white font-normal underline decoration-white" href="mailto:letstalk@eyekandy.com?subject=State%20of%20the%20Industry%20-%20Beverages">letstalk@eyekandy.com</a>
              <span className="call-us text-white font-normal underline decoration-white">+44 (0) 203 355 7554</span>
              <span className="call-us text-white font-normal underline decoration-white">+ 1 (855) 326 2237</span>
              <a className="linkedin cursor-pointer text-white font-normal underline decoration-white rounded-md w-8 overflow-hidden" href="https://www.linkedin.com/company/eyekandy/" rel="noreferrer" target="_blank">
                <svg className="w-8 flex-inline" xmlns="http://www.w3.org/2000/svg"
                  width="32" height="32" viewBox="0 0 24 24">
                  <path style={{ fill: "white" }}
                    d="M0 0v24h24v-24h-24zm8 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.397-2.586 7-2.777 7 2.476v6.759z" />
                </svg>
              </a>
            </div>
            <div className="location pl-4 w-full  flex flex-col gap-2">
              <p className="street-address text-white">34-37 Liverpool St, London,</p>
              <p className="postcode text-white">EC2M 7PP</p>
              <a className="find-us-google-maps text-white underline decoration-white cursor-pointer" target="_blank" rel="noreferrer" href="https://www.google.com/maps/place/eyekandy/@51.5169973,-0.0837787,17z/data=!3m2!4b1!5s0x4875fc00103c8831:0x28813f13cf279d7d!4m5!3m4!1s0x48761136c632eb5f:0x1a822f948ee91f71!8m2!3d51.5169973!4d-0.08159">
                <div className="flex gap-2 items-center">
                  <span>Find us</span>
                  <div className="w-8">
                    <svg xmlns="http://www.w3.org/2000/svg" style={{ fill: "white", enableBackground: "new 0 0 612 612" }} viewBox="0 0 612 612">
                      <path
                        d="m516.316 337.52 94.233 193.581c3.832 7.873-.196 14.314-8.952 14.314H10.402c-8.756 0-12.785-6.441-8.952-14.314L95.684 337.52c1.499-3.079 5.528-5.599 8.952-5.599h80.801c2.49 0 5.853 1.559 7.483 3.442 5.482 6.335 11.066 12.524 16.634 18.65 5.288 5.815 10.604 11.706 15.878 17.735h-95.891c-3.425 0-7.454 2.519-8.952 5.599L58.163 505.589h495.67l-62.421-128.242c-1.498-3.08-5.527-5.599-8.953-5.599h-96.108c5.273-6.029 10.591-11.92 15.879-17.735 5.585-6.144 11.2-12.321 16.695-18.658 1.628-1.878 4.984-3.434 7.47-3.434h80.971c3.423 0 7.451 2.518 8.95 5.599zm-71.775-132.292c0 105.776-88.058 125.614-129.472 227.265-3.365 8.26-14.994 8.218-18.36-.04-37.359-91.651-112.638-116.784-127.041-198.432-14.181-80.379 41.471-159.115 122.729-166.796 82.64-7.812 152.144 56.979 152.144 138.003zm-65.427 0c0-40.436-32.779-73.216-73.216-73.216s-73.216 32.78-73.216 73.216c0 40.437 32.779 73.216 73.216 73.216s73.216-32.779 73.216-73.216z" />
                    </svg>
                  </div>
                </div>
              </a>
            </div>
          </div>


        </div>
        <a rel="noreferrer" target="_blank" className="mt-16 mb-0 opacity-50" href="https://eyekandy.com"><span >&copy; eyekandy 2022</span></a>

      </section>
    </>
  }

  return (
    <>
      <Helmet>
        <title>Eyekandy - State of the Industry: Beverages</title>
        <meta property="og:title" content="Eyekandy - State of the Industry: Beverages" />
        <meta name="description" content="Request a download of the State of the Industry: Beverages Deck and/or get in contact with us!" />
        <meta name="og:description" content="Request a download of the State of the Industry: Beverages Deck and/or get in contact with us!" />

      </Helmet>
      <Content>
        {getScreenSize() === "mobile" && (
          renderMobilePage()
        )}
        {getScreenSize() === "desktop" && (
          renderDesktopPage()
        )}
      </Content>
    </>

  )
}