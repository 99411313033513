/*

  
  

  :description:
  The Home component.

*/

//
//  :react & redux:
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'

//
//  :packages:

//
//  :component:
export const ToolsEyekandyURLsPage = props => {
  const [arid, setARID] = useState('')

  const getPlayerURLWithARButton = () => {
    return `https://eyekandy-player.pointandplace.com/raw/?arid=${arid}&showWebarButton=Y`
  }

  const getPlayerURLNoARButton = () => {
    return `https://eyekandy-player.pointandplace.com/raw/?arid=${arid}`
  }

  const getStagingPlayerURLWithARButton = () => {
    return `https://eyekandy-player.pointandplace.com/raw/?arid=${arid}&showWebarButton=Y&env=staging`
  }

  const getStagingPlayerURLNoARButton = () => {
    return `https://eyekandy-player.pointandplace.com/raw/?arid=${arid}&env=staging`
  }

  const getQRURL = () => {
    return `https://qr.pointandplace.com/delivery/${arid}`
  }

  const getQRURLStaging = () => {
    return `https://qr.pointandplace.com/delivery/${arid}?staging=yes`
  }

  return (
    <>
      <Helmet>
        <title>Eyekandy - Tools - Eyekandy URLs</title>
        <meta property='og:title' content='Tools - Eyekandy URLs' />
        <meta name='description' content='Tools - Eyekandy URLs' />
        <meta name='og:description' content='Tools - Eyekandy URLs' />
      </Helmet>

      <div className='w-full flex flex-col justify-center items-center bg-[#3063D4] best-buy-font-med'>
        <div className='w-4/5 flex items-center p-9'>
          <div className='flex gap-2 items-center'>
            <label>ARID</label>
            <input
              value={arid}
              onChange={e => {
                setARID(e.target.value)
              }}
            />
          </div>
        </div>
      </div>

      {arid && (
        <div className='w-full flex flex-col justify-center items-center bg-green-600 best-buy-font-med'>
          <div className='w-4/5 flex items-center p-9 flex-col gap-4'>
            <h1>URLs</h1>
            
            <div className='flex gap-2 items-center w-full'>
              <a target='_blank' rel='noreferrer' href={getPlayerURLWithARButton()}>Live Player - AR Button</a>
            </div>

            <div className='flex gap-2 items-center w-full'>
              <a target='_blank' rel='noreferrer' href={getPlayerURLNoARButton()}>Live Player - No AR Button</a>
            </div>

            <hr/>

            <div className='flex gap-2 items-center w-full'>
              <a target='_blank' rel='noreferrer' href={getStagingPlayerURLWithARButton()}>Staging Player - AR Button</a>
            </div>

            <div className='flex gap-2 items-center w-full'>
              <a target='_blank' rel='noreferrer' href={getStagingPlayerURLNoARButton()}>Staging Player - No AR Button</a>
            </div>

            <hr/>

            <div className='flex gap-2 items-center w-full'>
              <a target='_blank' rel='noreferrer' href={getQRURL()}>Live QR</a>
            </div>

            <div className='flex gap-2 items-center w-full'>
              <a target='_blank' rel='noreferrer' href={getQRURLStaging()}>Staging QR</a>
            </div>

          </div>
        </div>
      )}
    </>
  )
}
