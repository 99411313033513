//
//  :react:
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

//
//  :assets:
import galleryImage001 from './assets/images/image-gallery-001.jpg'
import galleryImage002 from './assets/images/image-gallery-002.jpg'
import galleryImage003 from './assets/images/image-gallery-003.jpg'
import galleryImage004 from './assets/images/image-gallery-004.jpg'
import galleryImage005 from './assets/images/image-gallery-005.jpg'

import negroniImg from './assets/images/negroni-001.png'
import campariSpritzImg from './assets/images/campari-spritz-001.jpg'
import milanoTorinoImg from './assets/images/milano-torino-recipe-001.jpg'

import videoGallery001 from './assets/images/video-gallery-image-001.png'
import videoGallery002 from './assets/images/video-gallery-image-002.png'
import videoGallery003 from './assets/images/video-gallery-image-003.png'

//
//  :videos:
import videoGalleryMP4001 from './assets/videos/alphonse-mucha-for-moët-chandon.mp4'
import videoGalleryMP4002 from './assets/videos/moët-chandon-effervescence.mp4' 
import videoGalleryMP4003 from './assets/videos/the-perfect-serve.mp4'

//
//  :css:
import './page.css'

//
//  :components:
import { Content } from '../../../../components/Content'
import { VideoPopup } from '../../../../components/VideoPopup'

export const GoPuffMoetContent = () => {
  const [activeVideoGallery, setActiveVideoGallery] = useState(false)

  const triggerResizeEvent = () => {
    setTimeout(() => {
      if (window.parent) {
        window.parent.postMessage(
          { scrollHeight: document.body.scrollHeight, action: 'update-go-puff-campari-content-height' },
          '*'
        )
      }
    }, 400)
  }

  useEffect(() => {
    triggerResizeEvent()
  }, [])

  window.addEventListener('load', () => {
    triggerResizeEvent()
  })
  window.addEventListener('resize', () => {
    triggerResizeEvent()
  })

  const productDescription = () => {
    return (
      <div className="flex flex-col py-4 max-w-[1111px]">
        <h1 className="sm:text-[22px] md:text-[28px] eky-title-go-puff">Product Description</h1>
        <p className="mt-3 eky-text-go-puff">
          Moet & Chandon champagne imperial has a fresh crisp palate reveals white-fleshed fruits (apple, pear, white
          peach), citrus fruits (lemon), floral notes (lime blossom) and elegant hints of brioche and fresh nuts. It is
          a blend of 30% to 40% Pinot Noir, 30% to 40% Pinot Meunier and 20% to 30% Chardonnay. It contains 12% alcohol
          by volume. The palate is richly flavourful and smooth combining generosity and elegance. It is perfect with
          sushi and dumplings, shellfish and fish in light sauces, poached, roasted, or lightly sauced white meats, and
          fresh fruit salads.
        </p>
      </div>
    )
  }

  const imageGallery = () => {
    return (
      <div>
        <h1 className="sm:text-[22px] md:text-[28px] eky-title-go-puff">Image gallery</h1>
        <div className="grid md:grid-cols-5 grid-cols-2 gap-1 w-fit">
          <div className="max-w-[13rem] flex items-center justify-center">
            <img className="hover:scale-150 transition-all max-h-[208px]" src={galleryImage001} alt="" />
          </div>
          <div className="max-w-[13rem]">
            <img className="hover:scale-150 transition-all" src={galleryImage002} alt="" />
          </div>
          <div className="max-w-[13rem]">
            <img className="hover:scale-150 transition-all" src={galleryImage003} alt="" />
          </div>
          <div className="max-w-[13rem]">
            <img className="hover:scale-150 transition-all" src={galleryImage004} alt="" />
          </div>
          <div className="max-w-[13rem]">
            <img className="hover:scale-150 transition-all" src={galleryImage005} alt="" />
          </div>
        </div>
      </div>
    )
  }

  const negroniRecipe = () => {
    return (
      <div className="flex-col md:flex md:flex-row justify-between py-6 max-w-[1111px]">
        <div className="pr-4 w-full">
          <h2 className="sm:text-[20px] md:text-[22px] eky-sub-heading-go-puff">Impérial 1869</h2>
          <p className="mt-4">
            Created to celebrate the Maison’s most iconic cuvée, this cocktail highlights the beautifully fresh flavours
            of Moët Impérial. The grapefruit bitters and orange-blossom water enhance the citrus and floral notes of the
            wine, while the agave plays seamlessly on its yeasty character and adds richness
          </p>
          <div>
            <div className="border-t-4 flex justify-between mt-4">
              <div className="flex flex-col mt-3 mr-1">
                <p className="font-semibold eky-ingredients-and-methods-heading">Ingredients</p>
                <div className="flex flex-col eky-text-go-puff">
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>15 ml Agave Syrup</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>2 Dashes Grapefruit Bitters</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>1 Dash Orange-blossom Water</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>100 ml Moët & Chandon Moët Impérial</p>
                  </div>
                </div>
              </div>
              <div className="w-[20rem] mt-3">
                <p className="font-semibold eky-ingredients-and-methods-heading">Method</p>
                <div className="flex flex-col eky-text-go-puff">
                  <div className="flex">
                    <p className="min-w-[1rem]">1</p>
                    <p>Pour all the ingredients into a coupette and top with champagne</p>
                  </div>
                  <div className="flex">
                    <p className="min-w-[1rem]">2</p>
                    <p>Garnish with a sprig of gypsophila and serve</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-[23rem] flex m-auto mt-4 md:mt-0">
          <img src={negroniImg} alt="" />
        </div>
      </div>
    )
  }

  const campariSpritzRecipe = () => {
    return (
      <div className="bg-[#f3f4f4] flex flex-col-reverse flex-col md:flex md:flex-row justify-between p-4 md:p-6 max-w-[1111px]">
        <div className="max-w-[23rem] flex m-auto mt-4 md:mt-0">
          <img src={campariSpritzImg} alt="" />
        </div>
        <div className="w-full md:pl-6">
          <h2 className="sm:text-[20px] md:text-[22px] eky-sub-heading-go-puff">The Golden Hour</h2>
          <p className="mt-4">
            Inspired by the glorious sunsets in the gardens of the orangery in Epernay, The Golden Hour is a tribute to
            summer. Fruits abound in this drink, where the wine’s berry notes are elevated by the lovely stone-fruit
            character of the liqueurs used to highlight the structure of Moët & Chandon Impérial.
          </p>
          <div className="border-t-4 flex justify-between mt-4">
            <div className="flex flex-col mt-3 mr-1">
              <p className="font-semibold eky-ingredients-and-methods-heading">Ingredients</p>
              <div className="flex flex-col eky-text-go-puff">
                <div className="flex min-w-[11rem] md:min-w-none">
                  <p>10ml Crème de Pêche liqueur</p>
                </div>
                <div className="flex min-w-[11rem] md:min-w-none">
                  <p>5ml Maraschino liqueur</p>
                </div>
                <div className="flex min-w-[11rem] md:min-w-none">
                  <p>1 strawberry (cut into quarters)</p>
                </div>
                <div className="flex min-w-[11rem] md:min-w-none">
                  <p>2 raspberries (cut in half)</p>
                </div>
                <div className="flex min-w-[11rem] md:min-w-none">
                  <p>3 blueberries (cut in half)</p>
                </div>
                <div className="flex min-w-[11rem] md:min-w-none">
                  <p>75ml Moët & Chandon Brut Impérial</p>
                </div>
              </div>
            </div>
            <div className="w-[20rem] mt-3">
              <p className="font-semibold eky-ingredients-and-methods-heading">Method</p>
              <div className="flex flex-col eky-text-go-puff">
                <div className="flex">
                  <p className="min-w-[1rem]">1</p>
                  <p>
                    Place the cut berries in a large glass and gently press down using the back of a spoon. Add the
                    liqueurs, top with Moët Impérial and stir
                  </p>
                </div>
                <div className="flex">
                  <p className="min-w-[1rem]">2</p>
                  <p>Add ice cubes and garnish with two mint sprigs and some berries. Serve</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const vodkaMartiniRecipe = () => {
    return (
      <div className="flex-col md:flex md:flex-row justify-between py-6 max-w-[1111px]">
        <div className="pr-4 w-full">
          <h2 className="sm:text-[20px] md:text-[22px] eky-sub-heading-go-puff">La Favorite</h2>
          <p className="mt-4">
            This unique Signature Cocktail pays tribute to one of the Maison’s most influential patrons: the Marquise of
            Pompadour. Her love of champagne, and of Moët & Chandon in particular, helped cement its position as the
            must-have drink at Versailles! This cocktail complements the intense aromas and radiant, alluring
            spontaneity of Rosé Impérial to perfection, making it a go-to cocktail creation
          </p>
          <div>
            <div className="border-t-4 flex justify-between mt-4">
              <div className="flex flex-col mt-3 mr-1">
                <p className="font-semibold eky-ingredients-and-methods-heading">Ingredients</p>
                <div className="flex flex-col eky-text-go-puff">
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>10ml DOM Bénédictine</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>15ml Lemon Juice</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>15ml Honey Syrup</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>75ml Moët & Chandon Rosé Impérial</p>
                  </div>
                </div>
              </div>
              <div className="w-[20rem] mt-3">
                <p className="font-semibold eky-ingredients-and-methods-heading">Method</p>
                <div className="flex flex-col eky-text-go-puff">
                  <div className="flex">
                    <p className="min-w-[1rem]">1</p>
                    <p>Pour the DOM Bénédictine, lemon juice and honey syrup into a cocktail shaker</p>
                  </div>
                  <div className="flex">
                    <p className="min-w-[1rem]">2</p>
                    <p>Shake with ice cubes and strain into a champagne flute</p>
                  </div>
                  <div className="flex">
                    <p className="min-w-[1rem]">2</p>
                    <p>Top with Rosé Impérial</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-[23rem] flex m-auto mt-4 md:mt-0">
          <img src={milanoTorinoImg} alt="" />
        </div>
      </div>
    )
  }

  const cocktailAndRecipes = () => {
    return (
      <>
        <div className="py-6">
          <h1 className="sm:text-[22px] md:text-[28px] eky-title-go-puff">Cocktail and recipes</h1>
        </div>
        {negroniRecipe()}
        {campariSpritzRecipe()}
        {vodkaMartiniRecipe()}
      </>
    )
  }

  const videoGallery = () => {
    return (
      <div className="md:py-6 py-4">
        {activeVideoGallery.active && (
          <VideoPopup
            url={activeVideoGallery.src}
            type={activeVideoGallery.type}
            containerClasses={
              'bg-white border-2 border-[#dfe1e3] text-[16px] md:text-[24px] bottom-[24rem] md:bottom-[15rem] lg:bottom-[16rem] left-[0%] eky-title-go-puff'
            }
            videoTitle={activeVideoGallery.videoTitle}
            callBack={activeVideoGallery.callBack}
          ></VideoPopup>
        )}
        <h1 className="sm:text-[22px] md:text-[28px] eky-title-go-puff">Video gallery</h1>
        <div className="grid md:grid-cols-4 grid-cols-2 gap-1 w-fit eky-text-go-puff">
          <div className="lg:w-[13rem] text-center eky-video-popup">
            <img
              className="cursor-pointer h-[103px] w-[208px]"
              src={videoGallery001}
              alt="Alphonse Mucha for Moët & Chandon - Discover the Limited Edition"
              onClick={() => {
                setActiveVideoGallery({
                  active: true,
                  src: videoGalleryMP4001,
                  type: 'video/mp4',
                  videoTitle: 'Alphonse Mucha for Moët & Chandon - Discover the Limited Edition',
                  callBack: () => {
                    setActiveVideoGallery(false)
                  },
                })
              }}
            />
            <p className="pt-2 ">Alphonse Mucha for Moët & Chandon - Discover the Limited Edition</p>
          </div>
          <div className="lg:w-[13rem] text-center">
            <img
              className="cursor-pointer h-[103px] w-[208px]"
              src={videoGallery002}
              alt="Moët & Chandon Effervescence - Berlin OOH campaign"
              onClick={() => {
                setActiveVideoGallery({
                  active: true,
                  src: videoGalleryMP4002,
                  type: 'video/mp4',
                  videoTitle: 'Moët & Chandon Effervescence - Berlin OOH campaign',
                  callBack: () => {
                    setActiveVideoGallery(false)
                  },
                })
              }}
            />
            <p className="pt-2">Moët & Chandon Effervescence - Berlin OOH campaign</p>
          </div>
          <div className="lg:w-[13rem] text-center">
            <img
              className="cursor-pointer h-[103px] w-[208px]"
              src={videoGallery003}
              alt="The Perfect Serve - Ep.01 How to open & serve? ft. Pelayo Diaz - Moët & Chandon"
              onClick={() => {
                setActiveVideoGallery({
                  active: true,
                  src: videoGalleryMP4003,
                  type: 'video/mp4',
                  videoTitle: 'The Perfect Serve - Ep.01 How to open & serve? ft. Pelayo Diaz - Moët & Chandon',
                  callBack: () => {
                    setActiveVideoGallery(false)
                  },
                })
              }}
            />
            <p className="pt-2">The Perfect Serve - Ep.01 How to open & serve? ft. Pelayo Diaz - Moët & Chandon</p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <Helmet>
        <title>eyekandy | Bevvi Grey Goose Content</title>
        <meta property="og:title" content="eyekandy | inpage" />
        <meta name="description" content="" />
        <meta name="og:description" content="" />
      </Helmet>
      <Content classes="items-center bg-white relative">
        <div className="eky-inpage flex flex-col bg-white text-[#3f3f3f] w-[100%] transition-all">
          {productDescription()}
          {imageGallery()}
          {cocktailAndRecipes()}
          {videoGallery()}
        </div>
      </Content>
      <div className="bottom-[95%] md:bottom-[91%] right-[10%] left-[10%] left-[5%] bg-[#0067BB] text-white text-[16px] md:text-[24px] hidden"></div>
    </>
  )
}
