//
//  :react:
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

//
//  :assets:
import galleryImage001 from './assets/images/image-gallery-001.jpg'
import galleryImage002 from './assets/images/image-gallery-002.jpg'
import galleryImage003 from './assets/images/image-gallery-003.jpg'
import galleryImage004 from './assets/images/image-gallery-004.jpg'
import galleryImage005 from './assets/images/image-gallery-005.jpg'

import orangeCrushImg from './assets/images/jim-bean-orange-crush-001.png'
import orangeHighballImg from './assets/images/jim-beam-orange-highball-001.jpeg'
import whiskeySourImg from './assets/images/jim-beam-whiskey-sour-001.jpeg'

import videoGallery001 from './assets/images/video-gallery-image-001.png'
import videoGallery002 from './assets/images/video-gallery-image-002.png'
import videoGallery003 from './assets/images/video-gallery-image-003.png'
import videoGallery004 from './assets/images/video-gallery-image-004.png'

//
//  :videos:
import videoGalleryMP4001 from './assets/videos/jim-beam-kentucky-coolers-flavors-that-sip-like-summer.mp4'
import videoGalleryMP4002 from './assets/videos/jim-beam-people-are-good-for-you.mp4'
import videoGalleryMP4003 from './assets/videos/always-welcome-jim-beam.mp4'
import videoGalleryMP4004 from './assets/videos/jim-beam-people-are-good-for-you.mp4'

//
//  :css:
import './page.css'

//
//  :components:
import { Content } from '../../../../components/Content'
import { VideoPopup } from '../../../../components/VideoPopup'

export const GoPuffJimBeamContent = () => {
  const [activeVideoGallery, setActiveVideoGallery] = useState(false)

  const triggerResizeEvent = () => {
    setTimeout(() => {
      if (window.parent) {
        window.parent.postMessage(
          { scrollHeight: document.body.scrollHeight, action: 'update-go-puff-jim-beam-content-height' },
          '*'
        )
      }
    }, 400)
  }

  useEffect(() => {
    triggerResizeEvent()
  }, [])

  window.addEventListener('load', () => {
    triggerResizeEvent()
  })
  window.addEventListener('resize', () => {
    triggerResizeEvent()
  })

  const productDescription = () => {
    return (
      <div className="flex flex-col py-4 max-w-[1111px]">
        <h1 className="sm:text-[22px] md:text-[28px] eky-title-go-puff">Product Description</h1>
        <p className="mt-3 eky-text-go-puff">
          Jim Beam Kentucky Straight Bourbon Whiskey is the world’s one of the number-one selling bourbon. It has a
          strong family heritage with a formula unchanged in over 200 years and has been managed by over seven
          generations of Beam family distillers, ensuring the same consistent quality since its first production.
          Triple-aged Jim Beam Black is a more distinctive bourbon, richer in aroma, darker colour and a smoother, more
          complex flavour profile. After six added years in their deeply charred, white oak barrels, Black delivers a
          smooth caramel and warm oak notes and full-bodied character that’s definitely worth the wait.
        </p>
      </div>
    )
  }

  const imageGallery = () => {
    return (
      <div>
        <h1 className="sm:text-[22px] md:text-[28px] eky-title-go-puff">Image gallery</h1>
        <div className="grid md:grid-cols-5 grid-cols-2 gap-1 w-fit">
          <div className="max-w-[13rem] flex items-center justify-center">
            <img className="hover:scale-150 transition-all max-h-[208px]" src={galleryImage001} alt="" />
          </div>
          <div className="max-w-[13rem]">
            <img className="hover:scale-150 transition-all" src={galleryImage002} alt="" />
          </div>
          <div className="max-w-[13rem]">
            <img className="hover:scale-150 transition-all" src={galleryImage003} alt="" />
          </div>
          <div className="max-w-[13rem]">
            <img className="hover:scale-150 transition-all" src={galleryImage004} alt="" />
          </div>
          <div className="max-w-[13rem]">
            <img className="hover:scale-150 transition-all" src={galleryImage005} alt="" />
          </div>
        </div>
      </div>
    )
  }

  const orangeCrushRecipe = () => {
    return (
      <div className="flex-col md:flex md:flex-row justify-between py-6 max-w-[1111px]">
        <div className="pr-4 w-full">
          <h2 className="sm:text-[20px] md:text-[22px] eky-sub-heading-go-puff">Jim Beam Orange Crush</h2>
          <div>
            <div className="border-t-4 flex justify-between mt-4">
              <div className="flex flex-col mt-3 mr-1">
                <p className="font-semibold eky-ingredients-and-methods-heading">Ingredients</p>
                <div className="flex flex-col eky-text-go-puff">
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>1.5 parts Jim Beam orange</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>0.5 part orange juice</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>0.75 part lemon juice</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>0.5 part simple syrup</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>Soda water</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>Garnish with orange wedge and mint sprig</p>
                  </div>
                </div>
              </div>
              <div className="w-[20rem] mt-3">
                <p className="font-semibold eky-ingredients-and-methods-heading">Method</p>
                <div className="flex flex-col eky-text-go-puff">
                  <div className="flex">
                    <p className="min-w-[1rem]">1</p>
                    <p>Add all ingredients (except soda water) to a cocktail shaker with ice.</p>
                  </div>
                  <div className="flex">
                    <p className="min-w-[1rem]">2</p>
                    <p>Shake and strain into a collins glass over ice</p>
                  </div>
                  <div className="flex">
                    <p className="min-w-[1rem]">2</p>
                    <p>Top with soda water and gently stir</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-[23rem] flex m-auto mt-4 md:mt-0">
          <img src={orangeCrushImg} alt="" />
        </div>
      </div>
    )
  }

  const orangeHighballRecipe = () => {
    return (
      <div className="bg-[#f3f4f4] flex flex-col-reverse flex-col md:flex md:flex-row justify-between p-4 md:p-6 max-w-[1111px]">
        <div className="max-w-[23rem] flex m-auto mt-4 md:mt-0">
          <img src={orangeHighballImg} alt="" />
        </div>
        <div className="w-full md:pl-6">
          <h2 className="sm:text-[20px] md:text-[22px] eky-sub-heading-go-puff">Jim Beam Orange Highball</h2>
          <div className="border-t-4 flex justify-between mt-4">
            <div className="flex flex-col mt-3 mr-1">
              <p className="font-semibold eky-ingredients-and-methods-heading">Ingredients</p>
              <div className="flex flex-col eky-text-go-puff">
                <div className="flex min-w-[11rem] md:min-w-none">
                  <p>1 part Jim Beam orange</p>
                </div>
                <div className="flex min-w-[11rem] md:min-w-none">
                  <p>4 parts premium soda water</p>
                </div>
                <div className="flex min-w-[11rem] md:min-w-none">
                  <p>Garnish with an orange wedge</p>
                </div>
              </div>
            </div>
            <div className="w-[20rem] mt-3">
              <p className="font-semibold eky-ingredients-and-methods-heading">Method</p>
              <div className="flex flex-col eky-text-go-puff">
                <div className="flex">
                  <p className="min-w-[1rem]">1</p>
                  <p>Build over ice in a tall highball glass</p>
                </div>
                <div className="flex">
                  <p className="min-w-[1rem]">2</p>
                  <p>Garnish with an orange wedge</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const whiskeySourRecipe = () => {
    return (
      <div className="flex-col md:flex md:flex-row justify-between py-6 max-w-[1111px]">
        <div className="pr-4 w-full">
          <h2 className="sm:text-[20px] md:text-[22px] eky-sub-heading-go-puff">Jim Beam Whiskey Sour</h2>
          <div>
            <div className="border-t-4 flex justify-between mt-4">
              <div className="flex flex-col mt-3 mr-1">
                <p className="font-semibold eky-ingredients-and-methods-heading">Ingredients</p>
                <div className="flex flex-col eky-text-go-puff">
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>1 part Jim Beam Black</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>1 part fresh lemon juice</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>1/2 part simple syrup</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>1/2 part egg white</p>
                  </div>
                </div>
              </div>
              <div className="w-[20rem] mt-3">
                <p className="font-semibold eky-ingredients-and-methods-heading">Method</p>
                <div className="flex flex-col eky-text-go-puff">
                  <div className="flex">
                    <p className="min-w-[1rem]">1</p>
                    <p>Build in a cocktail shaker</p>
                  </div>
                  <div className="flex">
                    <p className="min-w-[1rem]">2</p>
                    <p>Shake, strain, and serve</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-[23rem] flex m-auto mt-4 md:mt-0">
          <img src={whiskeySourImg} alt="" />
        </div>
      </div>
    )
  }

  const cocktailAndRecipes = () => {
    return (
      <>
        <div className="py-6">
          <h1 className="sm:text-[22px] md:text-[28px] eky-title-go-puff">Cocktail and recipes</h1>
        </div>
        {orangeCrushRecipe()}
        {orangeHighballRecipe()}
        {whiskeySourRecipe()}
      </>
    )
  }

  const videoGallery = () => {
    return (
      <div className="md:py-6 py-4">
        {activeVideoGallery.active && (
          <VideoPopup
            url={activeVideoGallery.src}
            type={activeVideoGallery.type}
            containerClasses={
              'bg-white border-2 border-[#dfe1e3] text-[16px] md:text-[24px] bottom-[24rem] md:bottom-[15rem] lg:bottom-[16rem] left-[0%] eky-title-go-puff'
            }
            videoTitle={activeVideoGallery.videoTitle}
            callBack={activeVideoGallery.callBack}
          ></VideoPopup>
        )}
        <h1 className="sm:text-[22px] md:text-[28px] eky-title-go-puff">Video gallery</h1>
        <div className="grid md:grid-cols-4 grid-cols-2 gap-1 w-fit eky-text-go-puff">
          <div className="lg:w-[13rem] text-center eky-video-popup">
            <img
              className="cursor-pointer h-[114px] w-[206px]"
              src={videoGallery001}
              alt="Jim Beam Kentucky Coolers Flavors That Sip Like Summer"
              onClick={() => {
                setActiveVideoGallery({
                  active: true,
                  src: videoGalleryMP4001,
                  type: 'video/mp4',
                  videoTitle: 'Jim Beam Kentucky Coolers Flavors That Sip Like Summer',
                  callBack: () => {
                    setActiveVideoGallery(false)
                  },
                })
              }}
            />
            <p className="pt-2 ">Jim Beam Kentucky Coolers Flavors That Sip Like Summer</p>
          </div>
          <div className="lg:w-[13rem] text-center">
            <img
              className="cursor-pointer h-[114px] w-[206px]"
              src={videoGallery002}
              alt="Jim Beam People Are Good For You"
              onClick={() => {
                setActiveVideoGallery({
                  active: true,
                  src: videoGalleryMP4002,
                  type: 'video/mp4',
                  videoTitle: 'Jim Beam People Are Good For You',
                  callBack: () => {
                    setActiveVideoGallery(false)
                  },
                })
              }}
            />
            <p className="pt-2">Jim Beam People Are Good For You</p>
          </div>
          <div className="lg:w-[13rem] text-center">
            <img
              className="cursor-pointer h-[114px] w-[206px]"
              src={videoGallery003}
              alt="Always Welcome | Jim Beam"
              onClick={() => {
                setActiveVideoGallery({
                  active: true,
                  src: videoGalleryMP4003,
                  type: 'video/mp4',
                  videoTitle: 'Always Welcome | Jim Beam',
                  callBack: () => {
                    setActiveVideoGallery(false)
                  },
                })
              }}
            />
            <p className="pt-2">Always Welcome | Jim Beam</p>
          </div>
          <div className="lg:w-[13rem] text-center">
            <img
              className="cursor-pointer h-[114px] w-[206px]"
              src={videoGallery004}
              alt="Jim Beam People Are Good For You"
              onClick={() => {
                setActiveVideoGallery({
                  active: true,
                  src: videoGalleryMP4004,
                  type: 'video/mp4',
                  videoTitle: 'Jim Beam People Are Good For You',
                  callBack: () => {
                    setActiveVideoGallery(false)
                  },
                })
              }}
            />
            <p className="pt-2">Jim Beam People Are Good For You</p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <Helmet>
        <title>eyekandy | Bevvi Grey Goose Content</title>
        <meta property="og:title" content="eyekandy | inpage" />
        <meta name="description" content="" />
        <meta name="og:description" content="" />
      </Helmet>
      <Content classes="items-center bg-white relative">
        <div className="eky-inpage flex flex-col bg-white text-[#3f3f3f] w-[100%] transition-all">
          {productDescription()}
          {imageGallery()}
          {cocktailAndRecipes()}
          {videoGallery()}
        </div>
      </Content>
      <div className="bottom-[95%] md:bottom-[91%] right-[10%] left-[10%] left-[5%] bg-[#0067BB] text-white text-[16px] md:text-[24px] hidden"></div>
    </>
  )
}
