//
//  :react & redux:
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formSubmissionAsync, selectFormSubmissionError, selectFormSubmissionRequesting, selectFormSubmissionResponse } from "../../../features/best-buy-landing/bestBuySyndicationSlice";
import { ErrorIcon, MinusIcon, PlusIcon, SuccessIcon } from "../svgs/Svgs";

const GROUP = `[BEST-BUY-SYNDICATION]`

export const BesyBuySyndicationForm = () => {
  //
  //  :human information:
  const [fullName, setFullName] = useState("")
  const [email, setEmail] = useState("")
  const [company, setCompany] = useState("")
  //
  //  :numbers:
  const [amountOfSKUs, setAmountOfSKUs] = useState(0)
  const [variants, setVariants] = useState(0)
  const [totalCost, setTotalCost] = useState(0)
  //
  //  :additional information:
  const [comments, setComments] = useState("")

  //
  //  :dispatch:
  const dispatch = useDispatch()

  //
  //  :state:
  const formSubmissionRequesting = useSelector(selectFormSubmissionRequesting)
  const formSubmissionError = useSelector(selectFormSubmissionError)
  const formSubmissionResponse = useSelector(selectFormSubmissionResponse)
  const [formSubmissionStatus, setFormSubmissionStatus] = useState(0)

  //
  //  :errors:
  const [fullNameError, setFullNameError] = useState(null)
  const [companyError, setCompanyError] = useState(null)
  const [emailError, setEmailError] = useState(null)
  const [amountOfSKUsError, setAmountOfSKUsError] = useState(null)
  const [totalCostError, setTotalCostError] = useState(null)


  useEffect(() => {
    let variantCost = 0;
    let masterSkuCost = 0;
    if (variants !== 0) {
      variantCost = variants * 199
    }
    if (amountOfSKUs !== 0) {
      masterSkuCost = amountOfSKUs * 399
    }

    setTotalCost(variantCost + masterSkuCost)
  }, [amountOfSKUs, variants])

  const _renderError = (value) => {
    return <span className="text-red-600 text-xs font-bold font-italic ml-3">{value}</span>
  }

  //
  //  :events:
  //  Functions that deal with events.
  const _validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const _setInputErrorsIfRequired = () => {
    let isValid = true
    const ERROR_MESSAGE_IS_REQUIRED = "* required"
    if (!fullName) {
      setFullNameError(ERROR_MESSAGE_IS_REQUIRED)
      isValid = false
    }
    if (!company) {
      setCompanyError(ERROR_MESSAGE_IS_REQUIRED)
      isValid = false
    }
    if (!email) {
      setEmailError(ERROR_MESSAGE_IS_REQUIRED)
      isValid = false
    }
    if (!_validateEmail(email)) {
      setEmailError("the email address is not valid")
      isValid = false
    }
    if (amountOfSKUs === 0) {
      setAmountOfSKUsError("* Please choose how many Master SKUs")
      isValid = false
    }
    if (isNaN(totalCost)) {
      console.log(totalCost);
      setTotalCostError("Invalid total cost")
      isValid = false
    }
    return isValid
  }

  const _resetErrors = () => {
    const functions = [
      setFullNameError, setCompanyError, setEmailError, setTotalCostError, setAmountOfSKUsError
    ]
    functions.map((f) => {
      return f(null)
    })
    return true
  }

  const onClickSubmitForm = async () => {
    if (formSubmissionRequesting || formSubmissionResponse) {
      console.log(`form is requesting or has already been completed, skipping form submission`)
      return
    }

    _resetErrors()

    //
    //  Assemble our payload.
    const payload = {
      fullName: fullName || null,
      company: company || null,
      email: email || null,
      amountOfSKUs: amountOfSKUs || null,
      variants: variants || null,
      comments: comments || null,
      totalCost: totalCost || null
    }

    const IS_VALID = _setInputErrorsIfRequired()
    //
    //  If the form is not valid, return from here.
    if (!IS_VALID) {
      console.warn(`${GROUP} error, input validation has failed, aborting submission`)
      return
    }

    const response = await dispatch(formSubmissionAsync(payload))
    setFormSubmissionStatus(response.payload)
  }

  const masterSkuInputOnChange = (e) => {
    const value = +e.currentTarget.value
    if (value < 0) { return; }
    if (!isNaN(value)) {
      setAmountOfSKUs(value)
    }
    return;
  }

  const variantSkuInputOnChange = (e) => {
    const value = +e.currentTarget.value
    if (value < 0) { return; }
    if (!isNaN(value)) {
      setVariants(value)
    }
    return;
  }

  const addMasterSku = () => {
    let inputElement = document.getElementById("master-sku-input")
    inputElement.value = (amountOfSKUs + 1)
    setAmountOfSKUs(amountOfSKUs + 1)
  }

  const minusMasterSku = () => {
    if (amountOfSKUs === 0) { return; }
    let inputElement = document.getElementById("master-sku-input")
    inputElement.value = (amountOfSKUs - 1)
    setAmountOfSKUs(amountOfSKUs - 1)
  }

  const addSkuVariant = () => {
    let inputElement = document.getElementById("variant-sku-input")
    inputElement.value = (variants + 1)
    setVariants(variants + 1)
  }

  const minusSkuVariant = () => {
    if (variants === 0) { return; }
    let inputElement = document.getElementById("variant-sku-input")
    inputElement.value = (variants - 1)
    setVariants(variants - 1)
  }

  const success = () => {
    return <>
      <div className="w-full h-[30rem] bg-[#33ab35] flex flex-col justify-center items-center">
        <SuccessIcon></SuccessIcon>
        <p>Your form has been submitted!</p>
      </div>
    </>
  }

  const error = () => {
    return <>
      <div className="w-full h-[30rem] bg-[#e83336] flex flex-col justify-center items-center">
        <ErrorIcon></ErrorIcon>
        <p>Sorry, something went wrong when trying to send the form, please get in contact with us directly using: prioritysupport@eyekandy.com </p>
      </div>
    </>
  }

  return <>
    {formSubmissionStatus === 200 && success()}
    {formSubmissionStatus > 200 && error()}
    {formSubmissionStatus === 0 && !formSubmissionError &&
      <div className="w-full h-fit flex flex-col justify-center items-center p-9">
        <div className=" md:w-4/5 w-[90%] h-fit flex flex-col items-center">
          <p className="text-white text-[37px] best-buy-font-med">Step1. Let’s get started!</p>
          <p className="text-white best-buy-font-reg text-center max-w-[24rem] text-[15px]">Simply fill out this form to get a quote and set-up a call to discuss the details of your project.</p>
        </div>

        <div className="lg:w-4/5 xl:w-[65%] md:w-[100%] w-[90%] flex md:flex-row flex-col justify-between mt-6">
          <div className="rounded-lg bg-white md:w-[38rem] md:h-[18rem] lg:h-[17rem] p-6 flex flex-col">
            <p className="text-[#3063D4] best-buy-font-bold">For how many Master SKUs would you like to produce 3D/AR models? $399 per Master SKU.</p>

            <div className="flex mt-3">
              <input onChange={(e) => { masterSkuInputOnChange(e) }} id="master-sku-input" className="bg-[#3063D4] appearance-none text-white w-[10rem] h-[2.3rem] p-2 outline-none text-[18px] placeholder:text-white" type="text" placeholder="0" />
              <button className="bg-[#3063D4] w-[2.3rem] h-[2.3rem] ml-2 flex justify-center items-center" onClick={addMasterSku}><PlusIcon></PlusIcon></button>
              <button className="bg-[#3063D4] w-[2.3rem] h-[2.3rem] ml-2 flex justify-center items-center" onClick={minusMasterSku}><MinusIcon></MinusIcon></button>
            </div>
              {amountOfSKUsError && _renderError(amountOfSKUsError)}

            <div className="text-[#3063D4] flex flex-col mt-3">
              <p>For each Master SKU, we will:</p>
              <ul className="pl-4 best-buy-list">
                <li>Produce a high quality, AR/3D model for 1 Master Product, in 1 colour and 1 size.</li>
                <li>Syndicate the AR/3D Model for the lifetime of the Sku on Bestbuy.com</li>
              </ul>
            </div>

          </div>

          <div className="rounded-lg bg-white md:w-[32rem] md:h-[18rem] lg:h-[17rem] p-6 flex flex-col mt-4 md:mt-0 md:ml-3">
            <p className="text-[#3063D4] best-buy-font-bold">How many Master SKU Variants do you require? $199 per Variant.</p>

            <div className="flex mt-3">
              <input onChange={(e) => { variantSkuInputOnChange(e) }} id="variant-sku-input" className="bg-[#3063D4] text-white w-[10rem] h-[2.3rem] p-2 outline-none text-[18px] placeholder:text-white" type="text" placeholder="0" />
              <button className="bg-[#3063D4] w-[2.3rem] h-[2.3rem] ml-2 flex justify-center items-center" onClick={addSkuVariant}><PlusIcon></PlusIcon></button>
              <button className="bg-[#3063D4] w-[2.3rem] h-[2.3rem] ml-2 flex justify-center items-center" onClick={minusSkuVariant}><MinusIcon></MinusIcon></button>
            </div>

            <div className="text-[#3063D4] flex flex-col mt-3">
              <p>For each variant, we will:</p>
              <ul className="pl-4 best-buy-list">
                <li>Resize or change the color of a Master SKU.</li>
                <li>Syndicate the AR/3D Model Variant for the lifetime of the sku on BestBuy.com</li>
              </ul>
            </div>

          </div>
        </div>

        <div className="lg:w-4/5 xl:w-[65%] md:w-[100%] w-[90%] flex lg:flex-row flex-col justify-between border border-white h-fit rounded-lg mt-6 p-6">
          <div className="flex flex-col justify-evenly w-full xl:w-[22rem] xl:h-[14rem]">

            <label>Name, Surname</label>
            <div className="flex flex-col">
              <input className="border border-white bg-transparent outline-none text-white p-1 my-1 md:w-fit w-auto lg:min-w-[20rem]" onChange={(e) => { setFullName(e.currentTarget.value) }} type="text" name="" id="" placeholder="Name" />
              {fullNameError && (_renderError(fullNameError))}
            </div>

            <label>Company name</label>
            <div className="flex flex-col">
              <input className="border border-white bg-transparent outline-none text-white p-1 my-1 md:w-fit w-auto lg:min-w-[20rem]" onChange={(e) => { setCompany(e.currentTarget.value) }} type="text" name="" id="" placeholder="eg:Your brand" />
              {companyError && (_renderError(companyError))}
            </div>

            <label>Email address</label>
            <div className="flex flex-col">
              <input className="border border-white bg-transparent outline-none text-white p-1 my-1 md:w-fit w-auto lg:min-w-[20rem]" onChange={(e) => { setEmail(e.currentTarget.value) }} type="text" name="" id="" placeholder="example@eyekandy.com" />
              {emailError && (_renderError(emailError))}
            </div>

          </div>
          <div className="flex flex-col mt-1">
            <label>Any details we should be aware before our call?</label>
            <textarea className="text-white w-auto  sm:min-w-[23rem] lg:min-w-[25rem] 2xl:min-w-[31rem] h-[12rem] bg-transparent border border-white p-1 my-1 md:ml-1 outline-none" onChange={(e) => { setComments(e.currentTarget.value) }} placeholder="Comments"></textarea>
          </div>
        </div>

        <div className="flex lg:flex-row flex-col justify-between lg:w-4/5 md:w-[100%] xl:w-[65%] w-[90%] bg-white h-fit rounded-lg mt-6 p-6">
          <div className="flex flex-col lg:justify-evenly justify-center items-center">
            <p className="text-[#3063D4] best-buy-font-bold">Total one-off cost:</p>
            <div>
              <p className="text-[#3063D4] text-[13px]">$399 per Master SKU, $199 per Variant.</p>
              <div className="flex">
                <div className="bg-[#3063D4] h-[2.3rem] w-[2rem] flex justify-center items-center">$</div>
                <div className="bg-[#3063D4] h-[2.3rem] w-full lg:w-[13rem] text-white flex items-center">{totalCost}</div>
              </div>
            </div>
            {totalCostError && _renderError(totalCostError)}
          </div>
          <div className="flex justify-center items-center mt-4 lg:mt-0">
            <button onClick={onClickSubmitForm} className="bg-[#3063D4] md:w-[19.8rem] w-[11.7rem] md:h-[5.4rem] h-[3.6rem] text-[27px] rounded-lg best-buy-font-bold drop-shadow-lg">SUBMIT</button>
          </div>
        </div>

      </div>
    }
  </>
}