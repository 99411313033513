//
//  :react:
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'

//
//  :css:
import './page.css'

//
//  :assets:
import topSectionBackgroundImageDesktop from './assets/images/intel-evo-i7-background-image-desktop.png'
import topSectionBackgroundImageMobile from './assets/images/intel-evo-i7-background-image-mobile.png'
import sectionTwoLaptopImageMobile from './assets/images/intel-evo-i7-section-two-laptop-image-mobile.png'

import videoSectionLaptopImage from './assets/images/video-section-laptop.png'
import videoSectionI7BadgeImage from './assets/images/video-section-i7-badge.png'

import middleSectionStockImage001 from './assets/images/middle-section-stock-image-001.png'
import middleSectionStockImage002 from './assets/images/middle-section-stock-image-002.png'
import middleSectionStockImage003 from './assets/images/middle-section-stock-image-003.png'

import bottomSectionImage001 from './assets/images/bottom-section-image-001.png'

import downArrowImage from './assets/images/down-arrow-image.png'

//
//  :components:
import { Content } from '../../../../components/Content'
import { RightArrowIcon } from '../../../../components/svgs/RightArrowIcon'
import { CloseIconSvg } from '../../../../components/svgs/CloseIcon'
import { DropDownIcon } from '../../../../components/svgs/DropDownIcon'

//
//  :reference:
//  https://retailer.portinos.com/product/306

export const IntelEvoI7 = () => {
  // const [isMobile, setIsMobile] = useState(false)
  // const [activeBackgroundImage, setActiveBackgroundImage] = useState(topSectionBackgroundImageMobile)

  // const updateIsMobile = () => {
  //   console.log(window.screen.width);
  //   if (window.screen.width <= 912) {
  //     setActiveBackgroundImage(topSectionBackgroundImageMobile)
  //   } else {
  //     setActiveBackgroundImage(topSectionBackgroundImageDesktop)
  //   }
  // }

  const triggerResizeEvent = () => {
    setTimeout(() => {
      if (window.parent) {
        window.parent.postMessage(
          { scrollHeight: document.body.scrollHeight, action: 'update-intel-evo-in-line-content-height' },
          '*'
        )
      }
    }, 400)
  }

  useEffect(() => {
    // updateIsMobile()
    triggerResizeEvent()
  }, [])

  window.addEventListener('load', () => {
    // updateIsMobile()
    triggerResizeEvent()
  })
  window.addEventListener('resize', () => {
    // updateIsMobile()
    triggerResizeEvent()
  })

  const scrollIntoView = selector => {
    const scrollToElement = document.querySelector(selector)
    scrollToElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
  }

  const topSection = () => {
    return (
      <>
        <div className="relative">
          <img className='id:hidden' src={topSectionBackgroundImageMobile} alt="" />
          <img className='hidden id:block' src={topSectionBackgroundImageDesktop} alt="" />
          <div className="absolute top-[28%] left-[22%] sm:top-[28%] id:left-[47%] id:top-[24%] id:p-[20px]  max-w-[62%] id:max-w-[452px] lg:top-[26%] lg:left-[50%]">
            <p className="text-[10px] sm:text-[14px] md:text-[15px] id:text-[18px] py-[5px] intelone-light">
              Thin and light Intel® Evo™ Laptops
            </p>
            <p className="text-[14px] sm:text-[24px] md:text-[26px] id:text-[28px] pt-[5px] pb-[10px] leading-tight">
              Get the best overall laptop experience from anywhere.
            </p>
            <div className="flex text-[14px] sm:text-[16px] md:text-[18px] id:text-[30px] py-[5px] intelone-light">
              <p className="font-bold mr-1">Laptops.</p>
              <p className="font-bold text-[#00C7FD]">Evolved.</p>
            </div>
          </div>
          {scrollButtons()}
        </div>
      </>
    )
  }

  const scrollButtons = () => {
    return (
      <>
        <div className="absolute w-full flex flex-col id:flex-row items-center text-[#124957] font-bold text-center p-2 text-[12px] bottom-0 sm:px-[4rem] sm:pb-[2rem] sm:text-[22px] id:p-1">
          <div
            className="bg-[#00c7fd] py-[10px] px-[10px] sm:py-[20px] mb-1 sm:mb-3 id:mb-0 id:mr-1 flex justify-center items-center w-full max-w-[500px] id:max-w-none cursor-pointer border border-[#124957] hover:border-white"
            onClick={() => {
              scrollIntoView('.evolved-to-do-it-all')
            }}
          >
            <p>Evolved to do it all</p>
            <RightArrowIcon fill="#124957"></RightArrowIcon>
          </div>
          <div
            className="bg-[#00c7fd] py-[10px] px-[10px] sm:py-[20px] flex justify-center items-center w-full max-w-[500px] id:max-w-none cursor-pointer border border-[#124957] hover:border-white"
            onClick={() => {
              scrollIntoView('.everything-you-need')
            }}
          >
            <p>Everything you need</p>
            <RightArrowIcon fill="#124957"></RightArrowIcon>
          </div>
        </div>
      </>
    )
  }

  const videoSection = () => {
    const videoElement = () => {
      return (
        <>
          <video
            controls
            className="flex justify-center items-center m-auto py-[30px] md:ml-[8.33333%] md:w-[83.33333%]"
          >
            <source src="https://cdn.pointandplace.com/services/in-line-content/intel/evo/i7/videos/intel-evo-i7-video.mp4" type="video/mp4"></source>
            Your browser does not support the video element. Kindly update it to latest version.
          </video>
        </>
      )
    }
    const videoSectionText = () => {
      return (
        <>
          <div className="evolved-to-do-it-all flex flex-col lg:flex-row text-white px-[15px] sm:px-[50px] md:pl-[8.3333%] md:pr-[26px] ">
            <div className="flex flex-col text-[18px] lg:text-[24px] lg:min-w-[44%]">
              <p className="text-[40px] py-[10px] md:max-w-[90%] intelone-medium leading-tight">
                Evolved to do it all so you don’t have to sacrifice a thing.
              </p>
              <div className="flex py-[2px]">
                <p className="font-bold  mr-1">Get</p>
                <p>superior performance</p>
              </div>
              <div className="flex py-[2px]">
                <p className="font-bold mr-1">and</p>
                <p>intelligent collaboration features</p>
              </div>
              <div className="flex py-[2px]">
                <p className="font-bold  mr-1">and</p>
                <p>faster and more reliable connectivity</p>
              </div>
              <div className="flex py-[2px]">
                <p className="font-bold  mr-1">and</p>
                <p>all-day battery life.</p>
              </div>
              <div className="flex justify-between">
                <p className="py-[2px]">All inside a thin and light laptop</p>
                <p
                  className="hidden md:flex lg:hidden underline text-[#00c7fd] text-[14px] cursor-pointer"
                  onClick={() => {
                    scrollIntoView('.back-to-top')
                  }}
                >
                  BACK TO TOP
                </p>
              </div>
              <div className="hidden lg:block">
                <img src={videoSectionI7BadgeImage} alt="" />
              </div>
            </div>
            <div className="hidden lg:flex lg:min-w-[537px] lg:max-h-[623px]">
              <img src={videoSectionLaptopImage} alt="" />
            </div>
          </div>
        </>
      )
    }
    return (
      <>
        <div className="video-section-background bg-[#229be3]">
          {videoElement()}
          <div className="flex w-full justify-center items-center m-auto pt-4 sm:px-[50px] md:w-[83.3333%] lg:hidden">
            <img src={sectionTwoLaptopImageMobile} alt="" />
          </div>
          {videoSectionText()}
          <div className="flex w-full justify-center items-center">
            <div
              onClick={() => {
                scrollIntoView('.everything-you-need')
              }}
            >
              <img className='cursor-pointer my-4 ' src={downArrowImage} alt="" />
            </div>
          </div>
          <div className="hidden lg:flex w-full justify-between lg:pl-[103px] lg:pr-[26px]">
            <div></div>
            <p
              className="underline text-[#00c7fd] float-right text-[14px] cursor-pointer"
              onClick={() => {
                scrollIntoView('.back-to-top')
              }}
            >
              BACK TO TOP
            </p>
          </div>
          <div className="flex w-full sm:justify-center">
            <div className="bg-[#00c7fd] w-[40px] h-[40px] md:ml-[2.45rem]"></div>
          </div>
        </div>
      </>
    )
  }

  const middleSection = () => {
    return (
      <>
        <div className="everything-you-need w-full bg-[#1469ac] middle-section-background">
          <div className="flex flex-col md:flex-row w-full">
            <img className="w-full md:w-[50%] md:min-w-[50%]" src={middleSectionStockImage001} alt="" />
            <div className="flex flex-col relative">
              <div className="flex justify-end md:hidden">
                <div className="bg-[#00c7fd] w-[40px] h-[40px]"></div>
              </div>
              <div className="pb-[55px] pt-[15px] px-[15px] h-full md:flex md:flex-col md:justify-center md:px-[40px] md:p-0 lg:px-[100px]">
                <p className="text-[26px] lg:text-[33px] text-[#00c7fd] font-bold">Collaboration. Evolved.</p>
                <p className="text-[18px] lg:text-[22px] xl:text-[24px]">
                  Look and sound your best on video calls with built-in intelligence.
                </p>
              </div>
              <div className="absolute bottom-0 right-0">
                <p
                  className="underline text-[#00c7fd] text-[14px] float-right mr-2 mb-1 cursor-pointer"
                  onClick={() => {
                    scrollIntoView('.back-to-top')
                  }}
                >
                  BACK TO TOP
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col md:flex-row-reverse w-full">
            <img className="w-full md:w-[50%] md:min-w-[50%]" src={middleSectionStockImage002} alt="" />
            <div className="flex flex-col relative">
              <div className="flex justify-end hidden">
                <div className="bg-[#00c7fd] w-[40px] h-[40px]"></div>
              </div>
              <div className="pb-[55px] pt-[55px] px-[15px] h-full md:flex md:flex-col md:justify-center md:px-[40px] md:p-0 lg:px-[80px]">
                <p className="text-[26px] lg:text-[33px] text-[#00c7fd] font-bold">Connectivity. Evolved.</p>
                <p className="text-[18px] lg:text-[22px] xl:text-[24px]">
                  Cut through crowded Wi-Fi networks automatically with Intel® Wi-Fi 6/6E (Gig+).
                </p>
                <p className="text-[18px] lg:text-[22px] xl:text-[24px] mt-2">
                  Connect to multiple 4K monitors with a Thunderbolt™ 4 cable.
                </p>
              </div>
              <div className="absolute bottom-0 right-0 hidden">
                <p
                  className="underline text-[#00c7fd] text-[14px] float-right mr-2 mb-1 cursor-pointer"
                  onClick={() => {
                    scrollIntoView('.back-to-top')
                  }}
                >
                  BACK TO TOP
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col md:flex-row w-full">
            <img className="w-full md:w-[50%] md:min-w-[50%]" src={middleSectionStockImage003} alt="" />
            <div className="flex flex-col relative">
              <div className="flex justify-end absolute right-0 md:bottom-[20px] md:left-0 md:right-auto md:top-auto">
                <div className="bg-[#00c7fd] w-[40px] h-[40px]"></div>
              </div>
              <div className="flex justify-end absolute right-[40px] top-[40px] md:hidden">
                <div className="bg-[#cc94da] w-[15px] h-[15px]"></div>
              </div>
              <div className="pb-[55px] pt-[55px] px-[15px] h-full md:flex md:flex-col md:justify-center md:px-[40px] md:p-0 lg:px-[80px]">
                <p className="text-[26px] lg:text-[33px] text-[#00c7fd] font-bold"> Battery life. Evolved.</p>
                <p className="text-[18px] lg:text-[22px] xl:text-[24px]">
                  Power your passion wherever it takes you with a fast-charging, long-lasting battery.
                </p>
              </div>
              <div className="absolute bottom-0 right-0 hidden md:block">
                <p
                  className="underline text-[#00c7fd] text-[14px] float-right mr-2 mb-1 cursor-pointer"
                  onClick={() => {
                    scrollIntoView('.back-to-top')
                  }}
                >
                  BACK TO TOP
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  const bottomSection = () => {
    return (
      <>
        <div className="bottom-section-background">
          <div className="flex flex-col py-[24px] relative items-center">
            <div className="flex flex-col py-[20px]">
              <p className="text-[18px] text-center mb-1 px-6">Want a thin and light laptop that does it all?</p>
              <p className="text-[26px] font-bold text-[#00c7fd] text-center">That’s Intel® Evo™</p>
            </div>

            <div className="id:hidden">
              <img src={bottomSectionImage001} alt="" />
            </div>

            <div className="flex flex-col max-w-[324px] id:flex-row id:w-[80%] id:max-w-none justify-between id:ml-0 pr-2">
              <div className="pt-6 id:max-w-[33%]">
                <p className="text-[#00c7fd] font-bold text-[24px]">Works with the hardware and software</p>
                <p className="text-[22px]">you already have</p>
              </div>
              <div className="pt-6 id:max-w-[33%]">
                <p className="text-[#00c7fd] font-bold text-[24px]">Laptops as unique as you</p>
                <p className="text-[22px]">with different models, sizes, colors and modes</p>
              </div>
            </div>

            <div className="hidden id:block">
              <img src={bottomSectionImage001} alt="" />
            </div>

            <div className="flex flex-col id:flex-row id:w-[80%] justify-between id:ml-0 pr-2">
              <div className="pt-6 id:max-w-[33%]">
                <p className="text-[#00c7fd] font-bold text-[24px]">Ultra-responsive touch screens</p>
                <p className="text-[22px]">for every tap and swipe</p>
              </div>
              <div className="pt-6 id:max-w-[33%]">
                <p className="text-[#00c7fd] font-bold text-[24px]">Premium laptop</p>
                <p className="text-[22px]">minus the premium price</p>
              </div>
            </div>

            <div className="absolute bottom-0 right-0">
              <p
                className="underline text-[#00c7fd] text-[14px] float-right mr-2 mb-1 cursor-pointer"
                onClick={() => {
                  scrollIntoView('.back-to-top')
                }}
              >
                BACK TO TOP
              </p>
            </div>
          </div>
        </div>
      </>
    )
  }

  const legalDisclaimer = () => {
    const toggleLegalAndDisclaimerPopup = () => {
      const LegalAndDisclaimerElement = document.querySelector('.legal-and-disclaimer-content')
      const arrowIconEl = document.querySelector('.drop-down-icon')
      LegalAndDisclaimerElement.classList.toggle('is-active')
      arrowIconEl.classList.toggle('rotate-180')
    }

    const popupContent = () => {
      return (
        <>
          <div className="legal-and-disclaimer-content w-full absolute bg-[#d8d8d8] transition-all duration-300 bottom-[3.02rem] left-0 opacity-0 p-[3rem] hidden h-[1px] overflow-hidden">
            <div className="w-full flex flex-row-reverse ">
              <div
                className="w-fit cursor-pointer"
                onClick={() => {
                  toggleLegalAndDisclaimerPopup()
                }}
              >
                <CloseIconSvg></CloseIconSvg>
              </div>
            </div>
            <div className="flex flex-col">
              <p>
                As measured by unique features of Intel® Evo™ designs such as high performing Intel® Core™ CPUs, premium
                components, unmatched compatibility, and the latest connectivity solutions in sleek innovative designs.
                All Intel® Evo™ branded designs must meet demanding thresholds for key mobile user experiences like
                responsiveness and battery life; individual device performance may vary. Details at
                Intel.com/Performance-Evo.
              </p>
              <p className="mt-[10px]">
                Based on verified real-world battery life of Intel® Evo™ designs while performing typical workflows in a
                realistic environment within average daily usage scenarios.
              </p>
              <p>Individual Intel® Evo™ device performance may vary. Details at Intel.com/Performance-Evo.</p>
              <p className="mt-[10px]">Versus standard Wi-Fi. Subject to router requirements and available signal.</p>
              <p className="mt-[10px]">
                Thunderbolt™ 4 technology, at 40 Gbps, delivers the fastest, simplest and most reliable cable solution
                to any dock, display, or data device as compared to other PC I/O connection technologies including
                eSATA, USB, and IEEE 1394 FireWire.
              </p>
              <p className="mt-[10px]">Details at intel.com/PerformanceIndex (Connectivity).</p>
              <p className="mt-[10px]">
                Intel technologies may require enabled hardware, software or service activation. No product or component
                can be absolutely secure. Results may vary. © Intel Corporation. Intel, the Intel logo, and other Intel
                marks are trademarks of Intel Corporations or its subsidiaries. Other names and brands may be claimed as
                the property of others.
              </p>
            </div>
          </div>
        </>
      )
    }

    return (
      <>
        <div className="w-full bg-[#d8d8d8] text-black p-3 relative">
          <div
            className="flex w-fit items-center"
            onClick={() => {
              toggleLegalAndDisclaimerPopup()
            }}
          >
            <div className="cursor-pointer drop-down-icon transition-all duration-300">
              <DropDownIcon></DropDownIcon>
            </div>
            <p className="underline font-bold text-[14px] cursor-pointer">LEGAL & DISCLAIMERS</p>
          </div>
          {popupContent()}
        </div>
      </>
    )
  }

  return (
    <>
      <Helmet>
        <title>eyekandy | Intel EVO i7</title>
        <meta property="og:title" content="eyekandy | Intel EVO i7" />
        <meta name="description" content="" />
        <meta name="og:description" content="" />
      </Helmet>
      <Content classes="max-w-[1200px]">
        <div className="w-full h-full intelone-light back-to-top">
          {topSection()}
          {videoSection()}
          {middleSection()}
          {bottomSection()}
          {legalDisclaimer()}
        </div>
      </Content>
    </>
  )
}
