/*

  f e a t u r e s  -  b e s t  b u y  s y n d i c a t i o n
  Features - Best Buy Syndication Slice

  :description:
  Our reducer for the best buy syndication feature.

*/

//
//  :react & redux:
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

//
//  :code:
import { submitBestBuyForm } from './bestBuySyndicationAPI';

//
//  :statics:

//
//  :functions:

//
//  :state:
//  Our initial state object.
const initialState = {
  formSubmission: {
    status: "idle",
    requesting: false,
    error: null,
    response: null,
  }
};

//
//  :async actions:
export const formSubmissionAsync = createAsyncThunk(
  'bestBuySyndication/bestBuyFormSubmission',
  async (payload) => {
    const response = await submitBestBuyForm(payload)
    return response
  }
);

//
//  :reducer:
export const bestBuySyndicationSlice = createSlice({
  name: 'bestBuySyndication',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(formSubmissionAsync.pending, (state) => {
        state.formSubmission.status = 'requesting';
        state.formSubmission.requesting = true
        state.formSubmission.error = null
        state.formSubmission.response = null
      })
      .addCase(formSubmissionAsync.fulfilled, (state, action) => {
        state.formSubmission.status = 'idle';
        state.formSubmission.requesting = false
        state.formSubmission.response = action.payload
      })
      .addCase(formSubmissionAsync.rejected, (state, action) => {
        state.formSubmission.status = 'error';
        state.formSubmission.requesting = false
        state.formSubmission.error = action.error
      })
  },
});

//
//  :exports:
export const selectFormSubmissionStatus = (state) => state.bestBuySyndicationSlice.formSubmission.status;
export const selectFormSubmissionRequesting = (state) => state.bestBuySyndicationSlice.formSubmission.requesting;
export const selectFormSubmissionError = (state) => state.bestBuySyndicationSlice.formSubmission.error;
export const selectFormSubmissionResponse = (state) => state.bestBuySyndicationSlice.formSubmission.response;

//
//  :exports:
export default bestBuySyndicationSlice.reducer;