/*

  
  

  :description:
  The Home component.

*/

//
//  :react & redux:
import React, { useEffect, useState } from 'react'
//import { useSearchParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'

//
//  :components:
import { Content } from '../../../components/Content'

//
//  :statics:

//
//  :component:
export const IntelInteractiveExperienceViewerPage = props => {
  //const [searchParams, setSearchParams] = useSearchParams()
  //
  //  :helpers:

  useEffect(() => {
    addScript()
  }, [])

  //
  //  :options:
  const [displayID, setDisplayID] = useState('')
  const [displayWidth, setDisplayWidth] = useState(0)
  const [displayHeight, setDisplayHeight] = useState(0)
  const [distributor, setDistributor] = useState()
  const [language, setLanguage] = useState()

  const availableRoomsMap = {
    'efad740a-0485-473d-bfa5-44a93cf930cb': "[1,2,3,4,5,'unison']",
    'bcc57792-ae38-488d-a3b7-1cc5573d2198': '[1]',
    'c05073d9-76fc-4d1b-8934-6f13de74b03a': '[1,2]',
  }

  /*
  const _renderStringOfCurrentSize = () => {
    return `${displayWidth}px x ${displayHeight}px`
  }
  */

  const onClickSizeButton = event => {
    //
    //  :step 1:
    //  We need to read the values from the button that was clicked.
    const x = parseInt(event.target.dataset.x)
    const y = parseInt(event.target.dataset.y)
    const id = event.target.dataset.id

    //
    //  :step 2:
    //  Now update our display size.
    setDisplayWidth(x)
    setDisplayHeight(y)

    //
    //  :step 3:
    //  Update our ID.
    setDisplayID(id)
  }

  const onClickDistributorButton = event => {
    //
    //  :step 1:
    //  Read the UUID from the clicked button.
    const uuid = event.target.dataset.uuid

    //
    //  :step 2:
    //  Update our UUID.
    setDistributor(uuid)

    //
    //  :step 3:
    //  Update our language.
    const languages = {
      'efad740a-0485-473d-bfa5-44a93cf930cb': 'en',
      'bcc57792-ae38-488d-a3b7-1cc5573d2198': 'fr',
      'c05073d9-76fc-4d1b-8934-6f13de74b03a': 'es',
    }
    setLanguage(languages[uuid])

    //
    //  :step 4:
    //  Ok, now we need to re-render PC Rooms.
    setTimeout(() => {
      document.querySelector('eyekandy-pc-rooms-embed').connectedCallback()
    }, 200)
  }

  const renderButtonClasses = () => {
    return 'text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 w-full'
  }

  const renderUI = () => {
    return (
      <div className="w-64 flex flex-col h-screen bg-gray-800">
        <div className="flex flex-col p-4">
          <img alt="" className="w-32" src="https://cdn.pointandplace.com/services/pc-rooms/ui/v2/logos/intel.png" />
          <h2 className="text-xs mt-2 mb-4">Interactive Experience Viewer</h2>
          <hr />
        </div>
        <div className="flex flex-col px-4">
          <div>
            <h3 className="mb-2">desktop</h3>
            <button
              onClick={onClickSizeButton}
              data-id="desktop-xl"
              data-x="1200"
              data-y="675"
              className={renderButtonClasses()}
            >
              16:9 X-Large
            </button>
            <button
              onClick={onClickSizeButton}
              data-id="desktop-lg"
              data-x="900"
              data-y="506"
              className={renderButtonClasses()}
            >
              16:9 Large
            </button>
            <button
              onClick={onClickSizeButton}
              data-id="desktop-md"
              data-x="600"
              data-y="338"
              className={renderButtonClasses()}
            >
              16:9 Medium
            </button>
            <button
              onClick={onClickSizeButton}
              data-id="desktop-sm"
              data-x="320"
              data-y="180"
              className={renderButtonClasses()}
            >
              16:9 Small
            </button>
            <hr className="mt-4 mb-4" />
          </div>
          <div>
            <h3 className="mb-2">mobile</h3>
            <button
              onClick={onClickSizeButton}
              data-id="mobile-p-iphone8"
              data-x="375"
              data-y="667"
              className={renderButtonClasses()}
            >
              iPhone 8
            </button>
            <button
              onClick={onClickSizeButton}
              data-id="mobile-p-iphone11"
              data-x="375"
              data-y="812"
              className={renderButtonClasses()}
            >
              iPhone 11
            </button>
            <button
              onClick={onClickSizeButton}
              data-id="mobile-p-iphone13"
              data-x="390"
              data-y="844"
              className={renderButtonClasses()}
            >
              iPhone 13
            </button>
            <button
              onClick={onClickSizeButton}
              data-id="mobile-p-iphonexr"
              data-x="414"
              data-y="896"
              className={renderButtonClasses()}
            >
              iPhone XR
            </button>
            <button
              onClick={onClickSizeButton}
              data-id="mobile-p-gpixel2"
              data-x="393"
              data-y="851"
              className={renderButtonClasses()}
            >
              Google Pixel 2
            </button>
            <hr className="mt-4" />
          </div>
          {/* */}
          <div>
            <h3 className="mb-2">settings</h3>
            <button
              className={renderButtonClasses()}
              data-uuid="efad740a-0485-473d-bfa5-44a93cf930cb"
              onClick={onClickDistributorButton}
            >
              Newegg US
            </button>
            <button
              className={renderButtonClasses()}
              data-uuid="bcc57792-ae38-488d-a3b7-1cc5573d2198"
              onClick={onClickDistributorButton}
            >
              FNAC FR
            </button>
            <button
              className={renderButtonClasses()}
              data-uuid="c05073d9-76fc-4d1b-8934-6f13de74b03a"
              onClick={onClickDistributorButton}
            >
              FNAC ES
            </button>
            <hr className="mt-4" />
          </div>
        </div>
      </div>
    )
  }

  const renderContent = () => {
    return (
      <div className="w-screen h-auto flex items-center justify-center mt-8">
        <div
          className="bg-green-800 m-0 p-0"
          style={{
            width: displayWidth,
            height: displayHeight,
            transitionProperty: 'all',
            transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
            transitionDuration: '150ms',
          }}
        >
          <eyekandy-pc-rooms-embed
            distributor={distributor}
            language={language}
            displayLanguage={language}
            room="0"
            height={displayID.includes('mobile') ? '100%' : '400px'}
            logging="false"
            availableRooms={availableRoomsMap[distributor]}
          ></eyekandy-pc-rooms-embed>
        </div>
      </div>
    )
  }

  const renderPage = () => {
    return (
      <div className="box-border flex">
        {renderUI()}
        {renderContent()}
      </div>
    )
  }

  const addScript = () => {
    const n = document.createElement('script')
    n.src = 'https://media.pointandplace.com/js/pointandplace.js'
    /*
    if (window.location.href.includes('localhost')) {
      n.src = 'https://media.pointandplace.com/js/pointandplace.js'
    } else {
      n.src = 'https://cdn.pointandplace.com/js/pointandplace/eky-syndication.js'
    }
    */
    n.async = false
    n.id = 'xyz'
    if (!document.querySelector('xyz')) {
      document.body.appendChild(n)
    }
  }

  return (
    <>
      <Helmet>
        <title>Intel Interactive Experience Viewer</title>
        <meta property="og:title" content="Intel Interactive Experience Viewer" />
        <meta name="description" content="A viewer UI for the Intel Interactive Experience" />
        <meta name="og:description" content="A viewer UI for the Intel Interactive Experience" />
      </Helmet>
      <Content>{renderPage()}</Content>
    </>
  )
}
