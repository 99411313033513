/*

  p a g e
  Page

  :description:
  The page component is used as a base in Layout, it is the top-level presenter for <Outlet> content.

*/

//
//  :react & redux:
import React from "react";

//
//  :component:
export const Page = (props) => {
  return (
    <div className="eky-page w-full h-full min-h-screen flex flex-col">
      {props.children}
    </div>
  )
}