//
//  :react:
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'

//
//  :assets:
import galleryImage001 from './assets/images/image-gallery-001.jpg'
import galleryImage002 from './assets/images/image-gallery-002.jpg'
import galleryImage003 from './assets/images/image-gallery-003.jpg'
import galleryImage004 from './assets/images/image-gallery-004.jpg'
import galleryImage005 from './assets/images/image-gallery-005.jpg'

import orangeCrushImg from './assets/images/jim-bean-orange-crush-001.webp'
import orangeHighballImg from './assets/images/jim-beam-orange-highball-001.webp'
import whiskeySourImg from './assets/images/jim-beam-whiskey-sour-001.webp'

//
//  :css:
import './page.css'

//
//  :components:
import { Content } from '../../../../components/Content'

export const GoPuffJagermeisterContent = () => {
  const triggerResizeEvent = () => {
    setTimeout(() => {
      if (window.parent) {
        window.parent.postMessage(
          { scrollHeight: document.body.scrollHeight, action: 'update-go-puff-jagermeister-content-height' },
          '*'
        )
      }
    }, 400)
  }

  useEffect(() => {
    triggerResizeEvent()
  }, [])

  window.addEventListener('load', () => {
    triggerResizeEvent()
  })
  window.addEventListener('resize', () => {
    triggerResizeEvent()
  })

  const productDescription = () => {
    return (
      <div className="flex flex-col py-4 max-w-[1111px]">
        <h1 className="sm:text-[22px] md:text-[28px] eky-title-go-puff">Product Description</h1>
        <p className="mt-3 eky-text-go-puff">
          Every German masterpiece contains equal parts precision and inspiration. Bold, yet balanced, this herbal
          liqueur is no different. Blending 56 botanicals, this ice-cold shot has always been embraced by those who take
          originality to the next level. Powering Perfectionists since 1878. This unique elixir was created in
          Wolfenbüttel, Germany by Curt Mast. While times may have changed, the recipe has not. Never mess with a
          winning formula; being iconic is that simple. 56 ingredients, every one essential. The highest-grade herbs,
          blossoms, roots and fruits from around the globe are crafted into 4 distinct macerates, then combined in
          modern-day alchemy into this herbal spirit. The full ingredient list is a closely guarded secret, but raise a
          glass to your lips and you’ll taste all 4 corners of the world. The taste profile stands out in any crowd.
          True connoisseurs can single out Jägermeister’s 5 distinct notes; sweet, bitter, fruity, spicy and aromatic.
          Every drop of Jägermeister comes from one of 445 Oak barrels. In the darkness of their depths, the macerates
          are left to blend and mature for up to a year until they become liquid German perfection. Best served ice
          cold, -18 degrees to be precise. Prost!
        </p>
      </div>
    )
  }

  const imageGallery = () => {
    return (
      <div>
        <h1 className="sm:text-[22px] md:text-[28px] eky-title-go-puff">Image gallery</h1>
        <div className="grid md:grid-cols-5 grid-cols-2 gap-1 w-fit">
          <div className="max-w-[13rem] flex items-center justify-center">
            <img className="hover:scale-150 transition-all max-h-[208px]" src={galleryImage001} alt="" />
          </div>
          <div className="max-w-[13rem]">
            <img className="hover:scale-150 transition-all" src={galleryImage002} alt="" />
          </div>
          <div className="max-w-[13rem]">
            <img className="hover:scale-150 transition-all" src={galleryImage003} alt="" />
          </div>
          <div className="max-w-[13rem]">
            <img className="hover:scale-150 transition-all" src={galleryImage004} alt="" />
          </div>
          <div className="max-w-[13rem]">
            <img className="hover:scale-150 transition-all" src={galleryImage005} alt="" />
          </div>
        </div>
      </div>
    )
  }

  const jagermeisterMuleRecipe = () => {
    return (
      <div className="flex-col md:flex md:flex-row justify-between py-6 max-w-[1111px]">
        <div className="pr-4 w-full">
          <h2 className="sm:text-[20px] md:text-[22px] eky-sub-heading-go-puff">Jägermeister Mule</h2>
          <p className="my-2">
            Hipster meets heritage. Get ready to spice up your night with this fancy drink straight from the capital of
            cool. Spicy, earthy, underlining Jägermeister’s notes of ginger
          </p>
          <div>
            <div className="border-t-4 flex justify-between mt-4">
              <div className="flex flex-col mt-3 mr-1">
                <p className="font-semibold eky-ingredients-and-methods-heading">Ingredients</p>
                <div className="flex flex-col eky-text-go-puff">
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>5cl Jägermeister</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>12cl Ginger beer</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>2 Lime wedges</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>Cucumber</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>Ice cubes</p>
                  </div>
                </div>
              </div>
              <div className="w-[20rem] mt-3">
                <p className="font-semibold eky-ingredients-and-methods-heading">Method</p>
                <div className="flex flex-col eky-text-go-puff">
                  <div className="flex">
                    <p className="min-w-[1rem]">1</p>
                    <p>Squash the lime wedges at the bottom of the glass</p>
                  </div>
                  <div className="flex">
                    <p className="min-w-[1rem]">2</p>
                    <p>Add ice cubes, Jägermeister and ginger beer, in that order</p>
                  </div>
                  <div className="flex">
                    <p className="min-w-[1rem]">3</p>
                    <p>Stir everything to the beat of an underground techno track</p>
                  </div>
                  <div className="flex">
                    <p className="min-w-[1rem]">4</p>
                    <p>Don’t forget to eat your greens (or at least add cucumber to garnish)</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-[23rem] flex m-auto mt-4 md:mt-0">
          <img src={orangeCrushImg} alt="" />
        </div>
      </div>
    )
  }

  const tennesseHoneyAndLemonade = () => {
    return (
      <div className="bg-[#f3f4f4] flex flex-col-reverse flex-col md:flex md:flex-row justify-between p-4 md:p-6 max-w-[1111px]">
        <div className="max-w-[23rem] flex m-auto mt-4 md:mt-0">
          <img src={orangeHighballImg} alt="" />
        </div>
        <div className="w-full md:pl-6">
          <h2 className="sm:text-[20px] md:text-[22px] eky-sub-heading-go-puff">
            Jägermeister Cold Brew Espresso Martini
          </h2>
          <p className="my-2">
            Ramp up your Espresso Martini game with a drop of Jägermeister Cold Brew Coffee meets Jägermeister, with a
            hint of cacao
          </p>
          <div className="border-t-4 flex justify-between mt-4">
            <div className="flex flex-col mt-3 mr-1">
              <p className="font-semibold eky-ingredients-and-methods-heading">Ingredients</p>
              <div className="flex flex-col eky-text-go-puff">
                <div className="flex min-w-[11rem] md:min-w-none">
                  <p>50ml Jägermeister Cold Brew</p>
                </div>
                <div className="flex min-w-[11rem] md:min-w-none">
                  <p>25ml Espresso</p>
                </div>
                <div className="flex min-w-[11rem] md:min-w-none">
                  <p>15ml Vanilla Sugar Syrup</p>
                </div>
              </div>
            </div>
            <div className="w-[20rem] mt-3">
              <p className="font-semibold eky-ingredients-and-methods-heading">Method</p>
              <div className="flex flex-col eky-text-go-puff">
                <div className="flex">
                  <p className="min-w-[1rem]">1</p>
                  <p>Make your espresso and let it cool while you add the other ingredients to a shaker with ice</p>
                </div>
                <div className="flex">
                  <p className="min-w-[1rem]">2</p>
                  <p>Once the espresso has cooled, add it to the shaker and shake it like a polaroid picture</p>
                </div>
                <div className="flex">
                  <p className="min-w-[1rem]">3</p>
                  <p>Strain the shaker into a martini glass, making sure you get loads of lovely foam on top</p>
                </div>
                <div className="flex">
                  <p className="min-w-[1rem]">4</p>
                  <p>Add three coffee beans on the top</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const unrequitedLovePunchRecipe = () => {
    return (
      <div className="flex-col md:flex md:flex-row justify-between py-6 max-w-[1111px]">
        <div className="pr-4 w-full">
          <h2 className="sm:text-[20px] md:text-[22px] eky-sub-heading-go-puff">Jägermeister Hunter’s Tea</h2>
          <p className="my-2">
            Not your Boston kind of tea party. Though it slightly strays from tradition, this drink definitely deserves
            a ceremony. Highlights all aspects of Jägermeister’s aroma and versatility
          </p>
          <div>
            <div className="border-t-4 flex justify-between mt-4">
              <div className="flex flex-col mt-3 mr-1">
                <p className="font-semibold eky-ingredients-and-methods-heading">Ingredients</p>
                <div className="flex flex-col eky-text-go-puff">
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>5cl Black Tea</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>5cl Jägermeister</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>2cl Lime Juice</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>1.5cl Maple Syrup</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>Lemon zest</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>Ice cubes</p>
                  </div>
                </div>
              </div>
              <div className="w-[20rem] mt-3">
                <p className="font-semibold eky-ingredients-and-methods-heading">Method</p>
                <div className="flex flex-col eky-text-go-puff">
                  <div className="flex">
                    <p className="min-w-[1rem]">1</p>
                    <p>Pour the tea, Jägermeister, maple syrup and lemon juice into a cup</p>
                  </div>
                  <div className="flex">
                    <p className="min-w-[1rem]">2</p>
                    <p>Add ice and make it go round and round like a turn table</p>
                  </div>
                  <div className="flex">
                    <p className="min-w-[1rem]">3</p>
                    <p>Add the zest on top</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-[23rem] flex m-auto mt-4 md:mt-0">
          <img src={whiskeySourImg} alt="" />
        </div>
      </div>
    )
  }

  const cocktailAndRecipes = () => {
    return (
      <>
        <div className="py-6">
          <h1 className="sm:text-[22px] md:text-[28px] eky-title-go-puff">Cocktail and recipes</h1>
        </div>
        {jagermeisterMuleRecipe()}
        {tennesseHoneyAndLemonade()}
        {unrequitedLovePunchRecipe()}
      </>
    )
  }

  return (
    <>
      <Helmet>
        <title>eyekandy | Bevvi Grey Goose Content</title>
        <meta property="og:title" content="eyekandy | inpage" />
        <meta name="description" content="" />
        <meta name="og:description" content="" />
      </Helmet>
      <Content classes="items-center bg-white relative">
        <div className="eky-inpage flex flex-col bg-white text-[#3f3f3f] w-[100%] transition-all">
          {productDescription()}
          {imageGallery()}
          {cocktailAndRecipes()}
        </div>
      </Content>
      <div className="bottom-[95%] md:bottom-[91%] right-[10%] left-[10%] left-[5%] bg-[#0067BB] text-white text-[16px] md:text-[24px] hidden"></div>
    </>
  )
}
