//
//  :react & redux:
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

//
//  :code:
import { APP_CORE } from './memory'

//
//  :state:
//  Our main redux state for this feature.
const initialState = {
  isConfigured: false,
  initialiseAppCore: {
    loading: false,
    error: false,
    response: false,
    status: null,
    ready: false
  }
}

//
//  :thunks:
//  We only really have 1x async thunk here.

export const initialiseAppCoreAsync = createAsyncThunk(
  'app-core/initialise-app-core',
  async payload => {
    const response = await APP_CORE.go()
    return response.data
  }
)

export const appCoreSlice = createSlice({
  name: 'app-core',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    configureAppCore (state) {
      //
      //  :step 1:
      //  Configure all of the HTTP Get arguments that the app supports.
      
      //
      //  :step 2:
      //  Configure our options.
      APP_CORE.setOptionValue('scanForAR', false)
      APP_CORE.setOptionValue('localisation', {
        app: 'pages-ar-showcase',
        version: 'latest'
      })

      //
      //  :step 3:
      //  Mark that we are now configured.
      state.isConfigured = true
    },
    configureLogging (state, action) {
      //
      //  :step 0:
      //  Correct the language.
      let language = APP_CORE.readBrowserConfig('language') || 'en' 
      if(language){
        language = language.split("-")[0]
      }
    }
  },
  extraReducers: builder => {
    builder
      //
      //  :save-to-cloud:
      .addCase(initialiseAppCoreAsync.pending, state => {
        state.initialiseAppCore.error = null
        state.initialiseAppCore.loading = true
        state.initialiseAppCore.response = null
        state.initialiseAppCore.status = 'loading'
      })
      .addCase(initialiseAppCoreAsync.fulfilled, (state, action) => {
        state.initialiseAppCore.error = null
        state.initialiseAppCore.loading = false
        state.initialiseAppCore.response = action.payload
        state.initialiseAppCore.status = 'idle'
        state.initialiseAppCore.ready = true
      })
  }
})

//
//  :exports:
//  The named exports for this feature.
export const { configureAppCore, configureLogging } =
  appCoreSlice.actions

//
//  :selectors:
//  Mechanisms to select from state.
export const selectIsConfigured = state => state.appCore.isConfigured
export const selectInitialiseAppCore = state => state.appCore.initialiseAppCore

//
//  :reducer:
export default appCoreSlice.reducer
