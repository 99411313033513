/*

  c o n t e n t
  Content

  :description:
  The content component allows for simple control over the "container" element for a given page component.

*/

//
//  :react & redux:
import React from "react";
import { FullScreenLoader } from "./ui/FullScreenLoader";

//
//  :component:
export const Content = (props) => {

  const noLoader = props.noLoader || false
  const classes = props.classes || ""

  return (
    <div className={`eky-content h-full w-full flex flex-col flex-1 ${classes}`}>
      {props.children}
      {!noLoader && (<FullScreenLoader/>)}
    </div>
  )
}