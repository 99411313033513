/*

  e n d p o i n t s
  Endpoints

  :description:
  A file to hold onto global functions for interactions with the API.

  TODO: Rename this file.

*/
import { BASE_API_URL_CONFIG } from './settings'

/**
 * A function that determines if the current environment is local.
 * @returns {Boolean} True if we are local, else false.
 */
export const currentEnvironmentIsLocal = () => {
  const stringsToCheck = ["localhost", "0.0.0.0"]
  let isLocal = false
  stringsToCheck.map((stringToCheck) => {
    if(window.location.href.includes(stringToCheck)){
      isLocal = true
    }
    return true
  })
  return isLocal
}

/**
 * A function to retreive the value of the API URL we should use for requests.
 * 
 * Selection Order (if exists):
 *   - localStorage
 *   - URL Flags
 *   - Local environment
 *   - Live
 * @returns {String} The base API URL to use for requests.
 */
export const getApiUrl = () => {
  //
  //  :step 1:
  //  Allow for a local storage override (will always use this if it exists).
  if (BASE_API_URL_CONFIG.CAN_EXIST_IN_LOCAL_STORAGE){
    let valueFromStorage = localStorage.getItem(BASE_API_URL_CONFIG.LOCAL_STORAGE_KEY)
    if(valueFromStorage){
      return valueFromStorage
    }
  }
  //
  //  :step 2:
  //  Check for a URL flags.
  //  TODO

  //
  //  :step 3:
  //  See if we are in a local environment.
  if(currentEnvironmentIsLocal()){
    return BASE_API_URL_CONFIG.LOCAL
  }

  //
  //  :step 4:
  //  There are no other URLs available, so return the live one (actual API).
  return BASE_API_URL_CONFIG.LIVE
}


export const getApiUrlv2 = () => {
  //
  //  :step 1:
  //  Allow for a local storage override (will always use this if it exists).
  if (BASE_API_URL_CONFIG.CAN_EXIST_IN_LOCAL_STORAGE){
    let valueFromStorage = localStorage.getItem(BASE_API_URL_CONFIG.LOCAL_STORAGE_KEY)
    if(valueFromStorage){
      return valueFromStorage
    }
  }
  //
  //  :step 2:
  //  Check for a URL flags.
  //  TODO

  //
  //  :step 3:
  //  See if we are in a local environment.
  if(currentEnvironmentIsLocal()){
    return BASE_API_URL_CONFIG.LOCALv2
  }

  //
  //  :step 4:
  //  There are no other URLs available, so return the live one (actual API).
  return BASE_API_URL_CONFIG.LIVEv2
}