//
//  :react:
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'

//
//  :assets:
import galleryImage001 from './assets/images/image-gallery-001.jpg'
import galleryImage002 from './assets/images/image-gallery-002.jpg'
import galleryImage003 from './assets/images/image-gallery-003.jpg'
import galleryImage004 from './assets/images/image-gallery-004.jpg'
import galleryImage005 from './assets/images/image-gallery-005.jpg'

import orangeCrushImg from './assets/images/jim-bean-orange-crush-001.webp'
import orangeHighballImg from './assets/images/jim-beam-orange-highball-001.webp'
import whiskeySourImg from './assets/images/jim-beam-whiskey-sour-001.webp'

//
//  :css:
import './page.css'

//
//  :components:
import { Content } from '../../../../components/Content'

export const GoPuffJackDanielsContent = () => {
  const triggerResizeEvent = () => {
    setTimeout(() => {
      if (window.parent) {
        window.parent.postMessage(
          { scrollHeight: document.body.scrollHeight, action: 'update-go-puff-jack-daniels-content-height' },
          '*'
        )
      }
    }, 400)
  }

  useEffect(() => {
    triggerResizeEvent()
  }, [])

  window.addEventListener('load', () => {
    triggerResizeEvent()
  })
  window.addEventListener('resize', () => {
    triggerResizeEvent()
  })

  const productDescription = () => {
    return (
      <div className="flex flex-col py-4 max-w-[1111px]">
        <h1 className="sm:text-[22px] md:text-[28px] eky-title-go-puff">Product Description</h1>
        <p className="mt-3 eky-text-go-puff">
          Jack Daniel’s Sinatra Select has been created in honour of this friendship with Old Blue Eyes. The bold,
          smooth qualities of the Sinatra Select come from the specially made Sinatra barrels in which part of the
          whiskey was aged. Deep grooves were cut into the staves of the casks, increasing the amount of exposure the
          whiskey had to the wood. It is then married with Jack Daniel’s Old No. 7 and bottled at 45 percent abv, which
          is how Sinatra would have enjoyed it back in the day.
        </p>
      </div>
    )
  }

  const imageGallery = () => {
    return (
      <div>
        <h1 className="sm:text-[22px] md:text-[28px] eky-title-go-puff">Image gallery</h1>
        <div className="grid md:grid-cols-5 grid-cols-2 gap-1 w-fit">
          <div className="max-w-[13rem] flex items-center justify-center">
            <img className="hover:scale-150 transition-all max-h-[208px]" src={galleryImage001} alt="" />
          </div>
          <div className="max-w-[13rem]">
            <img className="hover:scale-150 transition-all" src={galleryImage002} alt="" />
          </div>
          <div className="max-w-[13rem]">
            <img className="hover:scale-150 transition-all" src={galleryImage003} alt="" />
          </div>
          <div className="max-w-[13rem]">
            <img className="hover:scale-150 transition-all" src={galleryImage004} alt="" />
          </div>
          <div className="max-w-[13rem]">
            <img className="hover:scale-150 transition-all" src={galleryImage005} alt="" />
          </div>
        </div>
      </div>
    )
  }

  const bondedBoulevardierRecipe = () => {
    return (
      <div className="flex-col md:flex md:flex-row justify-between py-6 max-w-[1111px]">
        <div className="pr-4 w-full">
          <h2 className="sm:text-[20px] md:text-[22px] eky-sub-heading-go-puff">Bonded Boulevardier</h2>
          <p className="my-2">A Bonded take on the classic Boulevardier</p>
          <div>
            <div className="border-t-4 flex justify-between mt-4">
              <div className="flex flex-col mt-3 mr-1">
                <p className="font-semibold eky-ingredients-and-methods-heading">Ingredients</p>
                <div className="flex flex-col eky-text-go-puff">
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>50ml Jack Daniel’s Tennessee Honey Lemonade</p>
                  </div>
                </div>
              </div>
              <div className="w-[20rem] mt-3">
                <p className="font-semibold eky-ingredients-and-methods-heading">Method</p>
                <div className="flex flex-col eky-text-go-puff">
                  <div className="flex">
                    <p className="min-w-[1rem]">1</p>
                    <p>3/4 fill a highball glass with cubed ice</p>
                  </div>
                  <div className="flex">
                    <p className="min-w-[1rem]">2</p>
                    <p>Pour Jack Honey over ice</p>
                  </div>
                  <div className="flex">
                    <p className="min-w-[1rem]">3</p>
                    <p>Top with lemonade</p>
                  </div>
                  <div className="flex">
                    <p className="min-w-[1rem]">4</p>
                    <p>Add lemon wedge to garnish</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-[23rem] flex m-auto mt-4 md:mt-0">
          <img src={orangeCrushImg} alt="" />
        </div>
      </div>
    )
  }

  const tennesseHoneyAndLemonade = () => {
    return (
      <div className="bg-[#f3f4f4] flex flex-col-reverse flex-col md:flex md:flex-row justify-between p-4 md:p-6 max-w-[1111px]">
        <div className="max-w-[23rem] flex m-auto mt-4 md:mt-0">
          <img src={orangeHighballImg} alt="" />
        </div>
        <div className="w-full md:pl-6">
          <h2 className="sm:text-[20px] md:text-[22px] eky-sub-heading-go-puff">Tennessee Honey & Lemonade</h2>
          <p className="my-2"></p>
          <div className="border-t-4 flex justify-between mt-4">
            <div className="flex flex-col mt-3 mr-1">
              <p className="font-semibold eky-ingredients-and-methods-heading">Ingredients</p>
              <div className="flex flex-col eky-text-go-puff">
                <div className="flex min-w-[11rem] md:min-w-none">
                  <p>50ml Jack Daniel’s Tennessee Honey Lemonade</p>
                </div>
              </div>
            </div>
            <div className="w-[20rem] mt-3">
              <p className="font-semibold eky-ingredients-and-methods-heading">Method</p>
              <div className="flex flex-col eky-text-go-puff">
                <div className="flex">
                  <p className="min-w-[1rem]">1</p>
                  <p>3/4 fill a highball glass with cubed ice</p>
                </div>
                <div className="flex">
                  <p className="min-w-[1rem]">2</p>
                  <p>Pour Jack Honey over ice</p>
                </div>
                <div className="flex">
                  <p className="min-w-[1rem]">3</p>
                  <p>Top with lemonade</p>
                </div>
                <div className="flex">
                  <p className="min-w-[1rem]">3</p>
                  <p>Add lemon wedge to garnish</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const unrequitedLovePunchRecipe = () => {
    return (
      <div className="flex-col md:flex md:flex-row justify-between py-6 max-w-[1111px]">
        <div className="pr-4 w-full">
          <h2 className="sm:text-[20px] md:text-[22px] eky-sub-heading-go-puff">Unrequited Love Punch</h2>
          <p className="my-2">A fruit-forward cocktail with a dry edge</p>
          <div>
            <div className="border-t-4 flex justify-between mt-4">
              <div className="flex flex-col mt-3 mr-1">
                <p className="font-semibold eky-ingredients-and-methods-heading">Ingredients</p>
                <div className="flex flex-col eky-text-go-puff">
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>50ml Jack Daniel’s Old No. 7</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>50ml Cranberry juice</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>15ml Pineapple juice</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>15ml Orange juice</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>Ginger ale</p>
                  </div>
                </div>
              </div>
              <div className="w-[20rem] mt-3">
                <p className="font-semibold eky-ingredients-and-methods-heading">Method</p>
                <div className="flex flex-col eky-text-go-puff">
                  <div className="flex">
                    <p className="min-w-[1rem]">1</p>
                    <p>1/2 fill the shaker with cubed ice</p>
                  </div>
                  <div className="flex">
                    <p className="min-w-[1rem]">2</p>
                    <p>Add all ingredients except the ginger ale and shake vigorously for 10 seconds</p>
                  </div>
                  <div className="flex">
                    <p className="min-w-[1rem]">3</p>
                    <p>3/4 fill a highball glass with cubed ice</p>
                  </div>
                  <div className="flex">
                    <p className="min-w-[1rem]">4</p>
                    <p>Strain the liquid into the glass</p>
                  </div>
                  <div className="flex">
                    <p className="min-w-[1rem]">5</p>
                    <p>Top with ginger ale</p>
                  </div>
                  <div className="flex">
                    <p className="min-w-[1rem]">6</p>
                    <p>Add orange slice to garnish</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-[23rem] flex m-auto mt-4 md:mt-0">
          <img src={whiskeySourImg} alt="" />
        </div>
      </div>
    )
  }

  const cocktailAndRecipes = () => {
    return (
      <>
        <div className="py-6">
          <h1 className="sm:text-[22px] md:text-[28px] eky-title-go-puff">Cocktail and recipes</h1>
        </div>
        {bondedBoulevardierRecipe()}
        {tennesseHoneyAndLemonade()}
        {unrequitedLovePunchRecipe()}
      </>
    )
  }

  return (
    <>
      <Helmet>
        <title>eyekandy | Bevvi Grey Goose Content</title>
        <meta property="og:title" content="eyekandy | inpage" />
        <meta name="description" content="" />
        <meta name="og:description" content="" />
      </Helmet>
      <Content classes="items-center bg-white relative">
        <div className="eky-inpage flex flex-col bg-white text-[#3f3f3f] w-[100%] transition-all">
          {productDescription()}
          {imageGallery()}
          {cocktailAndRecipes()}
        </div>
      </Content>
      <div className="bottom-[95%] md:bottom-[91%] right-[10%] left-[10%] left-[5%] bg-[#0067BB] text-white text-[16px] md:text-[24px] hidden"></div>
    </>
  )
}
