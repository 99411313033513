/*

  f e a t u r e s  -  a u t h  A P I 
  Features - auth API

  :description:
  Our main API interface for the auth feature.

*/

//
//  :code:
import { getApiUrl } from '../../endpoints';

//
//  :packages:
import axios from 'axios';


export const login = async (credentials) => {
  const response = await axios.post(`${getApiUrl()}/auth/login`, credentials, { headers: { "Content-Type": "application/json" } })
  return response.data
}