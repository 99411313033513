/*

  v i d e o  p o p u p
  VideoPopup.jsx

  :description:
  This is a simple component which displays the video you provide

*/

//
//  :react:
import React from 'react'
import { CloseIconSvg } from './svgs/CloseIcon'

import '../pages/inpage/grey-goose/bevvi/page.css'

/**
 * This is a simple component which displays the video you provide.
 *
 * @example <VideoPopup url={'path-to-mp4-file'} type={'video/mp4'} videoTitle={'some-video-title'} callBack={function}></VideoPopup>
 */
export const VideoPopup = ({ url, type, containerClasses, videoTitle, callBack }) => {
  if (!url || !type || !videoTitle || !callBack) {
    return
  }

  //
  //  We need this as a component to force the 'src' to refresh when it changes
  const VideoElement = ({ src, type }) => {
    return (
      <video controls className="rounded-md eky-video-popup md:min-w-[700px] md:min-h-[409px]">
        <source src={src} type={type}></source>
        Your browser does not support the video element. Kindly update it to latest version.
      </video>
    )
  }

  return (
    <div className={`eky-video-popup absolute rounded-lg p-4 w-fit md:min-w-[700px] md:min-h-[409px] ${containerClasses}`}>
      <div className="flex justify-between eky-video-popup">
        <h1 className="eky-video-popup">{videoTitle}</h1>
        <div
          className="cursor-pointer eky-video-popup"
          onClick={() => {
            callBack()
          }}
        >
          <CloseIconSvg className="eky-video-popup"></CloseIconSvg>
        </div>
      </div>
      <VideoElement src={url} type={type}></VideoElement>
    </div>
  )
}
