/*

  
  

  :description:
  The Home component.

*/

//
//  :react & redux:
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

//
//  :components:
import { Content } from '../../../components/Content'

//
//  :statics:
const breakpointMapping = {
  sm: '400px',
  md: '768px',
  lg: '1300px',
}
const themeChipMapping = {
  default: 'i5-1155G7',
  unison: 'i7-1370P',
  evo: 'i7-1260P',
}
const isEvoFromTheme = {
  default: null,
  unison: true,
  evo: true,
}

//
//  :component:
export const CrossmarkAndULViewer = props => {
  //
  //  :helpers:

  useEffect(() => {
    addScript()
  }, [])

  //
  //  :options:
  const [breakpoint, setBreakpoint] = useState('400px')
  const [language, setLanguage] = useState('en-US')
  const [optionSelected, setOptionSelected] = useState(false)
  const [theme, setTheme] = useState('default')
  const [crossmarkEmbedAttributes, setCrossmarkEmbedAttributes] = useState({
    arid: '8000197',
    distributor: 'dev-3dfw-retailer',
    language: 'en-US',
    eventlogging: 'false',
    chip: 'i5-1155G7',
    breakpoint: 'sm',
  })

  useEffect(() => {
    setCrossmarkEmbedAttributes({
      arid: '8000197',
      distributor: 'dev-3dfw-retailer',
      language: language,
      eventlogging: 'false',
      chip: themeChipMapping[theme],
      breakpoint: breakpoint,
      evo: isEvoFromTheme[theme],
    })
    setOptionSelected(true)
  }, [breakpoint, language, theme])

  const onClickSizeButton = event => {
    const id = event.target.dataset.id
    if (breakpointMapping[id] === breakpoint) {
      return
    }
    setOptionSelected(false)
    setBreakpoint(breakpointMapping[id])
  }

  const onClickLanguageButton = event => {
    setOptionSelected(false)
    const id = event.target.dataset.id
    setLanguage(id)
  }

  const onClickThemeButton = event => {
    const id = event.target.dataset.id
    if (id === theme) {
      return
    }
    setOptionSelected(false)
    setTheme(id)
  }

  const renderButtonClasses = () => {
    return 'text-white bg-[#00C7FD] hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 w-full'
  }

  const renderUI = () => {
    return (
      <div className="w-64 flex flex-col h-screen bg-gray-800">
        <div className="flex flex-col p-4">
          <img alt="" className="w-32" src="https://cdn.pointandplace.com/services/pc-rooms/ui/v2/logos/intel.png" />
          <h2 className="text-xs mt-2 mb-4">Crossmark and UL Viewer</h2>
          <hr />
        </div>
        <div className="flex flex-col px-4">
          <div>
            <h3 className="mb-2">Breakpoints</h3>
            <button onClick={onClickSizeButton} data-id="sm" className={renderButtonClasses()}>
              Mobile
            </button>
            <button onClick={onClickSizeButton} data-id="md" className={renderButtonClasses()}>
              Tablet
            </button>
            <button onClick={onClickSizeButton} data-id="lg" className={renderButtonClasses()}>
              Desktop
            </button>
            <hr className="mt-4 mb-4" />
          </div>
          {/*  */}
          <div>
            <h3 className="mb-2">Language</h3>
            <button onClick={onClickLanguageButton} data-id="en-US" className={renderButtonClasses()}>
              English
            </button>
            <button onClick={onClickLanguageButton} data-id="pt-BR" className={renderButtonClasses()}>
              Portuguese
            </button>
            <button onClick={onClickLanguageButton} data-id="ar-AE" className={renderButtonClasses()}>
              Arabic
            </button>
            <hr className="mt-4 mb-4" />
          </div>
          <div>
            <h3 className="mb-2">Theme</h3>
            <button onClick={onClickThemeButton} data-id="default" className={renderButtonClasses()}>
              Default
            </button>
            <button onClick={onClickThemeButton} data-id="unison" className={renderButtonClasses()}>
              Unison
            </button>
            <button onClick={onClickThemeButton} data-id="evo" className={renderButtonClasses()}>
              Evo
            </button>
            <hr className="mt-4 mb-4" />
          </div>
        </div>
      </div>
    )
  }

  const renderContent = () => {
    return (
      <div className="w-screen h-auto flex items-center justify-center mt-8">
        {optionSelected && (
          <div
            className="bg-green-800 m-0 p-0"
            style={{
              width: breakpoint,
              height: 'fit-content',
              transitionProperty: 'all',
              transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
              transitionDuration: '150ms',
            }}
          >
            <eyekandy-intel-crossmark-embed {...crossmarkEmbedAttributes}></eyekandy-intel-crossmark-embed>
          </div>
        )}
      </div>
    )
  }

  const renderPage = () => {
    return (
      <div className="box-border flex">
        {renderUI()}
        {renderContent()}
      </div>
    )
  }

  const addScript = () => {
    const n = document.createElement('script')
    n.src = 'https://cdn.pointandplace.com/misc/js/eky-syndicated-service-intel-crossmark.js'
    n.async = false
    n.id = 'xyz'
    if (!document.querySelector('xyz')) {
      document.body.appendChild(n)
    }
  }

  return (
    <>
      <Helmet>
        <title>Crossmark And UL Viewer</title>
        <meta property="og:title" content="Crossmark And UL Viewer" />
        <meta name="description" content="A viewer UI for Intel Crossmark" />
        <meta name="og:description" content="A viewer UI for Intel Crossmark" />
      </Helmet>
      <Content>{renderPage()}</Content>
    </>
  )
}
