//
//  :react:
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'

//
//  :assets:
import galleryImage001 from './assets/images/image-gallery-001.jpg'
import galleryImage002 from './assets/images/image-gallery-002.jpg'
import galleryImage003 from './assets/images/image-gallery-003.jpg'
import galleryImage004 from './assets/images/image-gallery-004.jpg'
import galleryImage005 from './assets/images/image-gallery-005.jpg'

import johnnieAndElderflowerImg from './assets/images/johnnie-and-elderflower-001.webp'
import whiskyOldFashionedImg from './assets/images/whisky-old-fashioned-001.webp'
import whiskyHotTodayImg from './assets/images/whisky-hot-today-001.webp'

//
//  :css:
import './page.css'

//
//  :components:
import { Content } from '../../../../components/Content'

export const GoPuffJohnnieWalkerContent = () => {
  const triggerResizeEvent = () => {
    setTimeout(() => {
      if (window.parent) {
        window.parent.postMessage(
          { scrollHeight: document.body.scrollHeight, action: 'update-go-puff-jim-beam-content-height' },
          '*'
        )
      }
    }, 400)
  }

  useEffect(() => {
    triggerResizeEvent()
  }, [])

  window.addEventListener('load', () => {
    triggerResizeEvent()
  })
  window.addEventListener('resize', () => {
    triggerResizeEvent()
  })

  const productDescription = () => {
    return (
      <div className="flex flex-col py-4 max-w-[1111px]">
        <h1 className="sm:text-[22px] md:text-[28px] eky-title-go-puff">Product Description</h1>
        <p className="mt-3 eky-text-go-puff">
          The world’s best-selling Scotch Whisky (IWSR) combines up to 35 whiskies, including many of Scotland’s most
          celebrated pours: Cardhu, Caol Ila, Cameronbridge and Teaninich. Vibrant, fresh apple and citrus fruitiness,
          cinnamon and pepper spice with a smoky finish, these are the iconic flavours of Johnnie Walker Red Label.
          Created with long drinks in mind, it’s perfect to be enjoyed as a highball, in a tall glass filled with ice
          and mixed with ginger ale or a mixer of your choice. A great gift for someone who appreciates good whisky and
          long mixed drinks.
        </p>
      </div>
    )
  }

  const imageGallery = () => {
    return (
      <div>
        <h1 className="sm:text-[22px] md:text-[28px] eky-title-go-puff">Image gallery</h1>
        <div className="grid md:grid-cols-5 grid-cols-2 gap-1 w-fit">
          <div className="max-w-[13rem] flex items-center justify-center">
            <img className="hover:scale-150 transition-all max-h-[208px]" src={galleryImage001} alt="" />
          </div>
          <div className="max-w-[13rem]">
            <img className="hover:scale-150 transition-all" src={galleryImage002} alt="" />
          </div>
          <div className="max-w-[13rem]">
            <img className="hover:scale-150 transition-all" src={galleryImage003} alt="" />
          </div>
          <div className="max-w-[13rem]">
            <img className="hover:scale-150 transition-all" src={galleryImage004} alt="" />
          </div>
          <div className="max-w-[13rem]">
            <img className="hover:scale-150 transition-all" src={galleryImage005} alt="" />
          </div>
        </div>
      </div>
    )
  }

  const johnnieandElderflowerRecipe = () => {
    return (
      <div className="flex-col md:flex md:flex-row justify-between py-6 max-w-[1111px]">
        <div className="pr-4 w-full">
          <h2 className="sm:text-[20px] md:text-[22px] eky-sub-heading-go-puff">Johnnie & Elderflower</h2>
          <p className="my-2">
            Like floral fireworks on your tongue. Enjoy the delicious elderflower cocktail that is Johnnie &
            Elderflower. Whisky, soda and elderflower cordial come together in a fragrant take on a highball drink.
          </p>
          <div>
            <div className="border-t-4 flex justify-between mt-4">
              <div className="flex flex-col mt-3 mr-1">
                <p className="font-semibold eky-ingredients-and-methods-heading">Ingredients</p>
                <div className="flex flex-col eky-text-go-puff">
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>50ml Johnnie Walker</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>30ml Elderflower Cordial</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>100ml Soda Water</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>Lemon twist</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>Lemon thyme or a mint sprig</p>
                  </div>
                </div>
              </div>
              <div className="w-[20rem] mt-3">
                <p className="font-semibold eky-ingredients-and-methods-heading">Method</p>
                <div className="flex flex-col eky-text-go-puff">
                  <div className="flex">
                    <p>
                      Choose your Johnnie Walker below. Add 50ml over ice. Stir in 30ml Elderflower Cordial and 100ml
                      Soda Water. Finish with a lemon twist and lemon thyme or a mint sprig.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-[23rem] flex m-auto mt-4 md:mt-0">
          <img src={johnnieAndElderflowerImg} alt="" />
        </div>
      </div>
    )
  }

  const whiskyOldFashionedRecipe = () => {
    return (
      <div className="bg-[#f3f4f4] flex flex-col-reverse flex-col md:flex md:flex-row justify-between p-4 md:p-6 max-w-[1111px]">
        <div className="max-w-[23rem] flex m-auto mt-4 md:mt-0">
          <img src={whiskyOldFashionedImg} alt="" />
        </div>
        <div className="w-full md:pl-6">
          <h2 className="sm:text-[20px] md:text-[22px] eky-sub-heading-go-puff">Whisky Old Fashioned</h2>
          <p className="my-2">
            A timeless whisky cocktail with a bold Johnnie Walker edge. Enjoy the unmistakable taste of the Old
            Fashioned drink with whisky, sugar syrup and aromatic bitters.
          </p>
          <div className="border-t-4 flex justify-between mt-4">
            <div className="flex flex-col mt-3 mr-1">
              <p className="font-semibold eky-ingredients-and-methods-heading">Ingredients</p>
              <div className="flex flex-col eky-text-go-puff">
                <div className="flex min-w-[11rem] md:min-w-none">
                  <p>60ml Johnnie Walker</p>
                </div>
                <div className="flex min-w-[11rem] md:min-w-none">
                  <p>10ml 1:1 Simple Syrup</p>
                </div>
                <div className="flex min-w-[11rem] md:min-w-none">
                  <p>2 dashes Aromatic Bitters Ice</p>
                </div>
                <div className="flex min-w-[11rem] md:min-w-none">
                  <p>Orange zest</p>
                </div>
                <div className="flex min-w-[11rem] md:min-w-none">
                  <p>Orange peel</p>
                </div>
                <div className="flex min-w-[11rem] md:min-w-none">
                  <p>Toasted rosemary or blood orange wedge</p>
                </div>
              </div>
            </div>
            <div className="w-[20rem] mt-3">
              <p className="font-semibold eky-ingredients-and-methods-heading">Method</p>
              <div className="flex flex-col eky-text-go-puff">
                <div className="flex">
                  <p>
                    Mix 60ml Johnnie Walker, 10ml 1:1 Simple Syrup and 2 dashes Aromatic Bitters in an ice-filled glass.
                    Stir well. For the syrup, heat equal amounts of sugar and water in a pan until the sugar dissolves.
                    Pour into a tumbler over ice. Go for one large cube, an ice ball or several small cubes. Twist a
                    piece of orange zest over the glass to release the aromatic oils. Finish with orange peel and
                    toasted rosemary or a blood orange wedge.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const whiskyHotTodayRecipe = () => {
    return (
      <div className="flex-col md:flex md:flex-row justify-between py-6 max-w-[1111px]">
        <div className="pr-4 w-full">
          <h2 className="sm:text-[20px] md:text-[22px] eky-sub-heading-go-puff">Whiskey Hot Toddy</h2>
          <div>
            <div className="border-t-4 flex justify-between mt-4">
              <div className="flex flex-col mt-3 mr-1">
                <p className="font-semibold eky-ingredients-and-methods-heading">Ingredients</p>
                <div className="flex flex-col eky-text-go-puff">
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>50ml Johnnie Walker</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>50ml Orange Juice</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>50ml cranberry juice</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>30ml of water</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>Teaspoon of Maple Syrup</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>Cinnamon Stick</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>Slice of orange</p>
                  </div>
                </div>
              </div>
              <div className="w-[20rem] mt-3">
                <p className="font-semibold eky-ingredients-and-methods-heading">Method</p>
                <div className="flex flex-col eky-text-go-puff">
                  <div className="flex">
                    <p>
                      50 ml Johnnie Walker Black label, 50 ml Orange Juice, 50 ml cranberry juice and 30ml of water.
                      Bring it to a simmer. Add a teaspoon of Maple Syrup and a Cinnamon Stick. Garnish with a slice of
                      orange.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-[23rem] flex m-auto mt-4 md:mt-0">
          <img src={whiskyHotTodayImg} alt="" />
        </div>
      </div>
    )
  }

  const cocktailAndRecipes = () => {
    return (
      <>
        <div className="py-6">
          <h1 className="sm:text-[22px] md:text-[28px] eky-title-go-puff">Cocktail and recipes</h1>
        </div>
        {johnnieandElderflowerRecipe()}
        {whiskyOldFashionedRecipe()}
        {whiskyHotTodayRecipe()}
      </>
    )
  }

  return (
    <>
      <Helmet>
        <title>eyekandy | Bevvi Grey Goose Content</title>
        <meta property="og:title" content="eyekandy | inpage" />
        <meta name="description" content="" />
        <meta name="og:description" content="" />
      </Helmet>
      <Content classes="items-center bg-white relative">
        <div className="eky-inpage flex flex-col bg-white text-[#3f3f3f] w-[100%] transition-all">
          {productDescription()}
          {imageGallery()}
          {cocktailAndRecipes()}
        </div>
      </Content>
      <div className="bottom-[95%] md:bottom-[91%] right-[10%] left-[10%] left-[5%] bg-[#0067BB] text-white text-[16px] md:text-[24px] hidden"></div>
    </>
  )
}
