/*

  
  

  :description:
  The Home component.

*/

//
//  :react & redux:
import React, { useEffect, useState, useRef } from "react";
import { Spinner } from "../../components/Spinner";

//
//  :components:

const isInViewport = function (elem) {
  const bounding = elem.getBoundingClientRect();
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}


function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    let id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
}
//
//  :component:
export const StateOfTheIndustryBeveragesPageCalendly = (props) => {
  //
  //  :state:
  const [canLoad, setCanLoad] = useState(false)

  //
  //  :dom:
  const div = React.createRef();

  //
  //  :props:
  const backgroundColour = props.backgroundColour || "ffffff"
  const textColour = props.textColour || "000000"
  const primaryColour = props.primaryColour || "0cb7eb"

  //
  //  :add-calendly:
  useEffect(() => {

  }, [])

  const addCalendlyToDOM = () => {
    const script = document.createElement("script")
    script.id = "eky-calendly"
    script.src = "https://assets.calendly.com/assets/external/widget.js"
    if (!document.querySelector("#eky-calendly")) {
      document.head.appendChild(script)
    }
  }

  //
  //  :load-when-visible:
  const loadWhenVisibleInterval = useInterval(() => {
    if (!canLoad) {
      if (isInViewport(div.current)) {
        setCanLoad(true)
      }
    }
  }, 250)
  //
  //  When we can load, clear the hok.
  if (canLoad) {
    clearInterval(loadWhenVisibleInterval)
    //
    //  Add calendly script to this DOM.
    setTimeout(() => {
      addCalendlyToDOM()
    }, 75)
  }

  //
  //  Render our calendly element.
  return (
    <>
      {canLoad ? (
        <div ref={div} id="calendly" className="flex rounded-2xl h-full overflow-hidden min-h-[975px] lg:min-h-[675px] max-w-[1000px] w-full">
          <div
            className="calendly-inline-widget w-80 lg:w-full"
            data-url={`https://calendly.com/letstalkeyekandy/30min?background_color=${backgroundColour}&amp;text_color=${textColour}&amp;primary_color=${primaryColour}`}
          ></div>
        </div>
      ) : (
        <div ref={div} id="calendly"><Spinner></Spinner></div>
      )}
    </>
  )
}
