/*

  
  

  :description:
  

*/

//
//  :react & redux:
import React, { useEffect, useState } from "react";
import { Spinner } from "../Spinner";

//
//  :component:
export const FullScreenLoader = (props) => {
  const loadingMilliseconds = props.milliseconds || 1.5 * 1000
  const [isLoading, setIsLoading] = useState(true)
  const [isRemoved, setIsRemoved] = useState(false)

  useEffect(() => {
    document.body.style.overflow = "hidden"
    setTimeout(() => {
      setIsLoading(false)
    }, loadingMilliseconds)
    setTimeout(() => {
      document.body.style.overflow = "auto"
      setIsRemoved(true)
    }, loadingMilliseconds * 1.1)
      //eslint-disable-next-line
  }, [])

  return (
    <div className={`${isRemoved ? 'hidden' : ''} transition-opacity opacity-1	absolute z-20 top-0 left-0 w-screen min-h-screen h-full flex justify-center items-center bg-black ${isLoading ? '' : 'opacity-0 z-0'}`}>
      <Spinner></Spinner>
    </div>
  )
}

