/*

  m o d e l  f i l e s
  ModelFiles.jsx

  :description:
  Super simple webpage for downloading model files

*/

//
//  :react & redux:
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'

export const ModelFiles = () => {
  const [ARID, setARID] = useState(null)
  const [error, setError] = useState(null)

  const isVaildARID = arid => {
    const regex = /^\d{4,}$/
    return regex.test(arid)
  }

  const isValidFile = blob => {
    return blob.size > 250
  }

  const downloadModelFiles = async () => {
    //
    //  :step 0:
    //  Make sure the ARID is reasonable
    if (!isVaildARID(ARID)) {
      return setError('Invalid ARID')
    } else {
      setError(null)
    }

    //
    //  :step 1:
    //  Set our statics
    const URI_MAP = [
      {
        type: 'glb',
        path: 'https://eyekandy-model-source.s3.eu-west-2.amazonaws.com/GLB',
        condition: 'uncompressed',
      },
      {
        type: 'usdz',
        path: 'https://eyekandy-model-staging.s3.eu-west-2.amazonaws.com/USDZ',
        condition: 'uncompressed',
      },
      {
        type: 'glb',
        path: 'https://eyekandy-model-staging.s3.eu-west-2.amazonaws.com/GLB',
        condition: 'compressed',
      },
    ]

    //
    //  :step 2:
    //  Loop through our URI map
    for (let i = 0; i < URI_MAP.length; i++) {
      console.log(URI_MAP[i])
      const fullPath = `${URI_MAP[i].path}/${ARID}.${URI_MAP[i].type}`
      const outputFileName = `${ARID}-${URI_MAP[i].condition}.${URI_MAP[i].type}`
      const downloadLink = document.createElement('a')

      //
      //  :step 3:
      //  Attempt to download the file
      fetch(fullPath)
        .then(response => response.blob())
        .then(blob => {
          if (!isValidFile(blob)) {
            return setError('It does not look like that file exists, please make sure the ARID is correct')
          }
          const url = URL.createObjectURL(blob)
          downloadLink.href = url
          downloadLink.download = outputFileName
          downloadLink.click()

          URL.revokeObjectURL(url)
          downloadLink.remove()
        })
        .catch(error => {
          console.error('Error:', error)
          return setError(`Something went wrong, please try again`)
        })
    }
  }

  return (
    <>
      <Helmet>
        <title>Eyekandy - Model Files</title>
        <meta property="og:title" content="Eyekandy - Model Files" />
        <meta name="description" content="Gather Model Files" />
        <meta name="og:description" content="Model Files" />
      </Helmet>

      <div className="model-files-container w-full h-[100vh] flex flex-col justify-center items-center">
        <h1 className="mb-2">Enter an ARID and click 'download' to get the model files</h1>
        <input
          type="text"
          name="ARID"
          id="arid-input"
          onChange={e => {
            setARID(e.target.value)
          }}
        />
        <button
          className="border p-2 mt-2"
          onClick={() => {
            downloadModelFiles()
          }}
        >
          download
        </button>
        {error && (
          <div className="text-red-500 mt-2 border border-red-500 p-2">
            <h2>{error}</h2>
          </div>
        )}
      </div>
    </>
  )
}
