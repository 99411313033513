/*

  n o t  f o u n d
  Not Found

  :description:
  A component page to represent when a URL is a 404.

*/

//
//  :react & redux:
import React from "react";

//
//  :components:

//
//  :component:
export const NotFound = (props) => {
  return (
    <>
      <div className="w-screen min-h-screen h-full flex flex-1 bg-gray-700 text-white items-center justify-center">
        <div className="w-96">
          <h1 className="text-5xl">not found</h1>
          <p className="text-xl mb-2">the requested resource does not exist at this time</p>
          <code className="bg-gray-900 rounded-lg text-sm text-white p-2">{window.location.pathname}</code>
        </div>
      </div>
    </>
  )
}