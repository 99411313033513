/*

  h o m e
  Home

  :description:
  The Home component.

*/

//
//  :react & redux:
import React from "react";

//
//  :components:
import { Content } from "../components/Content";

//
//  :component:
export const Home = (props) => {
  return (
    <>

      <Content>

      </Content>
    </>
  )
}