/*

  
  

  :description:

*/

//
//  :react & redux:
import React from "react";

//
//  :component:
export const FullScreenImage = (props) => {
  const src = props.src || "#"
  const heightClass = props.heightClass || "min-h-screen h-full"

  const isIOS = () => {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  }

  return (
    <div className={`background w-100 ${heightClass} flex justify-center`} style={{
      background: `url(${src}) no-repeat center center ${!isIOS() ? "fixed" : ''}`,
      WebkitBackgroundSize: "cover",
      MozBackgroundSize: "cover",
      OBackgroundSize: "cover",
      backgroundSize: "cover",
      height: heightClass !== "min-h-screen h-full" ? '66vh' : "auto"
    }}>
      {props.children}
    </div>
  )
}

