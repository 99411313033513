/*

  
  

  :description:
  

*/

//
//  :react & redux:
import React from "react";

//
//  :component:
export const ScrollToBottom = () => {

  const arrow = {
    border: "solid white",
    borderWidth: "0 10px 10px 0",
    display: "inline-block",
    padding: "12px",
    transform: "rotate(45deg)",
    WebkitTransform: "rotate(45deg)",
    cursor: "pointer",
    position: "absolute",
    bottom: "5rem",
    left: "50%",
  }

  const scrollDown = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  }

  return (
    <div id="arrow" onClick={scrollDown}>
      <div style={arrow}></div>
    </div>
  )
}

