//
//  :react & redux:
import React from "react"
import { Helmet } from "react-helmet"

//
//  :components:
import { Content } from "../../components/Content"
import {
  AppleWatchIcon,
  CameraIcon,
  CarIcon,
  ClipboardInCicleIcon,
  ComputerIcon,
  ControllerIcon,
  DroneIcon,
  HammerInCircleIcon,
  HeadphonesIcon,
  PersonalCareIcon,
  PhoneIcon,
  SmartHomeIcon,
  TickInCircleIcon,
  TvIcon,
  WasherIcon,
  WhiteGoodsIcon,
} from "./svgs/Svgs"
import { TeknosaSyndicationForm } from "./Form"

export const TeknosaLanding = () => {
  const headerContent = () => {
    return (
      <>
        <div className="md:w-4/5 w-[90%] flex items-center md:p-[6.25rem] p-4">
          <div id="header" className="w-full h-fit flex flex-col items-center">
            <img
              className="w-[14rem]"
              src={"https://cdn.pointandplace.com/best-buy-landing-page/images/eyekandy-logo-white.png"}
              alt="eyekandy logo"
            />
            <div className="mt-4 max-w-[54rem]">
              <p className="text-[35px] md:text-[40px] text-center">
                AR ve 3D ürün modellerinin üretimi ve dağıtımı için tercih edilen ortağımız
              </p>
            </div>
            <div className="flex md:flex-row flex-col justify-evenly w-full mt-4 teknosa-font-reg">
              <p className="max-w-[23rem] mb-5 md:mb-0 text-center">
                Teknosa ve eyekandy, Marka Ortaklarının sektör lideri AR ve 3D modeller sunmasına yardımcı olmak için iş
                birliği yaptı. eyekandy, yaratıcı hikaye anlatımı, strateji ve aktivasyon alanında dünya lideridir ve
                yüksek kaliteli AR ve 3D ürün modelleri üretmek için lider markalarla çalışır.
              </p>
              <p className="max-w-[23rem] text-center">
                Bu sayfa, markalara tercih ettiğimiz süreci kullanarak AR ve 3D modelleme oluşturmak için ihtiyaç
                duydukları tüm bilgileri sağlamak üzere tasarlanmıştır. İlgili iletişim bilgilerinden fiyatlandırmaya
                kadar her şeyi bulabilirsiniz (aşağıdaki hesaplayıcıyı kullanarak).
              </p>
            </div>
          </div>
        </div>
      </>
    )
  }

  const serviceUsage = () => {
    return (
      <>
        <div className="w-full h-fit flex flex-col justify-center items-center bg-[white] md:p-9 p-4">
          <div className="md:w-4/5 w-[90%] h-fit flex flex-col items-center">
            <p className="text-[#f58220] text-[37px] teknosa-font-med text-center">
              Bu hizmetler nerelerde kullanılabilir?
            </p>
            <p className="text-black teknosa-font-reg text-center max-w-[38rem] text-[15px]">
              Bu hizmetler Masaüstü tarayıcıdan (sadece 3D), mobil tarayıcıdan ve mobilden Teknosa Uygulaması üzerinden
              kullanılabilmektedir. Hizmetleri aşağıda çalışırken görebilirsiniz:
            </p>
          </div>
          <div className="flex md:flex-row flex-col w-4/5 md:w-[98%] justify-evenly xl:justify-center mt-2 md:mt-9 teknosa-font-reg">
            <div className="flex flex-col items-center text-black justify-between xl:ml-2">
              <p className="text-center max-w-[37rem] my-4">
                Bu, tüketicilere daha bilinçli satın alma kararları vermelerine yardımcı olabilecek ek ürün bilgileri ve
                spesifikasyonlar sağlar.{" "}
              </p>
              <video
                className="w-[32rem] md:w-[35rem] lg:w-[45rem] xl:w-[45rem] rounded-lg drop-shadow-lg"
                autoPlay
                muted
                loop
              >
                <source
                  src="https://cdn.pointandplace.com/teknosa-landing-page/videos/teknosa-3d-example.qt"
                  type="video/mp4"
                ></source>
                Your browser does not support the video element. Kindly update it to latest version.
              </video>
            </div>
          </div>
          <div className="text-center text-black mt-4">
            <p className="max-w-[30rem]">
              AR, tüketicilere daha iyi bir alışveriş deneyimi sunarak satışları artırma ve geliri artırma potansiyeline
              sahip.
            </p>
          </div>
        </div>
      </>
    )
  }

  const supportedCategories = () => {
    return (
      <>
        <div className="w-full h-fit flex flex-col justify-center items-center md:p-9 p-3">
          <p className="text-[37px] teknosa-font-med max-w-[44rem] text-center">
            AR ve 3D tarafından desteklenebilecek ürün kategorileri
          </p>

          <div className="flex md:w-4/5 w-[90%] justify-evenly mt-9">
            <div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 gap-5 sm:gap-9 flex justify-center">
              <div className="flex flex-col justify-evenly items-center text-center">
                <TvIcon></TvIcon>
                <p className="teknosa-font-reg">Televizyon</p>
              </div>
              <div className="flex flex-col justify-evenly items-center text-center">
                <ComputerIcon></ComputerIcon>
                <p className="teknosa-font-reg">Bilgisayar ve tabletler</p>
              </div>
              <div className="flex flex-col justify-evenly items-center text-center">
                <WasherIcon></WasherIcon>
                <p className="teknosa-font-reg">Ev Aletleri</p>
              </div>
              <div className="flex flex-col justify-evenly items-center text-center">
                <PhoneIcon></PhoneIcon>
                <p className="teknosa-font-reg">Telefon</p>
              </div>
              <div className="flex flex-col justify-evenly items-center text-center">
                <PersonalCareIcon></PersonalCareIcon>
                <p className="teknosa-font-reg">Personal Care</p>
              </div>
              <div className="flex flex-col justify-evenly items-center text-center">
                <WhiteGoodsIcon></WhiteGoodsIcon>
                <p className="teknosa-font-reg">Beyaz eşya</p>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  const stepsToGetStarted = () => {
    return (
      <>
        <div className="w-full h-fit flex flex-col justify-center items-center p-9 bg-white">
          <p className="text-[37px] teknosa-font-med max-w-[44rem] text-center text-[#f58220]">Başlamak için 4 adım:</p>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-8 mt-9">
            <div className="sm:w-[23rem] sm:h-[20rem] w-[15rem] border border-black rounded-md p-6 flex flex-col items-center justify-evenly text-center">
              <ClipboardInCicleIcon></ClipboardInCicleIcon>
              <p className="text-black mt-3 sm:mt-0 ">
                1. Lütfen maliyet hesaplayıcıyı doldurun ve 3D/AR uzmanımızla görüşme rezervasyonu yapın. Veya
                teknosa@eyekandy.com adresinden bize ulaşın.
              </p>
            </div>

            <div className="sm:w-[23rem] sm:h-[20rem] w-[15rem] border border-black rounded-md p-6 flex flex-col items-center justify-evenly text-center">
              <img src={"https://cdn.pointandplace.com/best-buy-landing-page/images/url-in-circle.png"} alt="" />
              <p className="text-black mt-3 sm:mt-0">
                2. Ürünlerinizi seçin ve Teknosa ürün sayfasından bize URL'yi gönderin.
              </p>
            </div>

            <div className="sm:w-[23rem] sm:h-[20rem] w-[15rem] border border-black rounded-md p-6 flex flex-col items-center justify-evenly text-center">
              <HammerInCircleIcon></HammerInCircleIcon>
              <p className="text-black mt-3 sm:mt-0">
                3. AR/3D Modellerini Teknosa spesifikasyonlarına göre yaklaşık 10 iş günü içerisinde üretiyoruz.​
              </p>
            </div>

            <div className="sm:w-[23rem] sm:h-[20rem] w-[15rem] border border-black rounded-md p-6 flex flex-col items-center justify-evenly text-center">
              <TickInCircleIcon></TickInCircleIcon>
              <p className="text-black mt-3 sm:mt-0">
                4. eyekandy, AR/3D Modelleriniz Teknosa.com'da Yayınlandığında sizi bilgilendirir
              </p>
            </div>
          </div>
        </div>
      </>
    )
  }

  const renderPage = () => {
    return (
      <>
        {headerContent()}
        {serviceUsage()}
        {supportedCategories()}
        {stepsToGetStarted()}
        <TeknosaSyndicationForm></TeknosaSyndicationForm>
      </>
    )
  }

  return (
    <>
      <Helmet>
        <title>Eyekandy - Teknosa Syndication</title>
        <meta property="og:title" content="Eyekandy - Teknosa Syndication" />
        <meta name="description" content="" />
        <meta name="og:description" content="" />
      </Helmet>
      <Content classes="w-full flex flex-col items-center bg-[#f58220] teknosa-font-med">{renderPage()}</Content>
    </>
  )
}
