/*

  
  

  :description:
  The Home component.

*/

//
//  :react & redux:
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

//
//  :packages:
const { Environment } = require("@eyekandy/eky-environment")

//
//  :component:
export const CocaColaAvatarPage = (props) => {
  const arid = "83672392"
  const env = new Environment()
  const [environmentScanned, setEnvironmentScanned] = useState(false)
  const [ARSupport, setARSupport] = useState(false)

  useEffect(() => {
    const scanEnvironment = async () => {
      await env.scan()
      setARSupport(env.canHandleAR)
      setEnvironmentScanned(true)
    }
    scanEnvironment()
    // eslint-disable-next-line
  }, [])

  const navigateToLaunchPage = () => {
    window.location = `https://launch.pointandplace.com?arid=${arid}&service=ar-assistant`
  }

  return (
    <>
      <Helmet>
        <title>Eyekandy - Coca-Cola Avatar Experience</title>
        <meta property="og:title" content="Eyekandy - Coca-Cola Avatar Experience" />
        <meta name="description" content="Experience the Coca-Cola sales pitch in AR!" />
        <meta name="og:description" content="Experience the Coca-Cola sales pitch in AR!" />
      </Helmet>

      {environmentScanned && !ARSupport && (
        <section name="avatar" className="w-full min-h-screen h-full flex flex-col justify-center items-center">
          <div className="w-full md:w-1/3 p-8">
            <h1 className="text-2xl text-center mb-8">Scan the QR code below to activate the Eyekandy Coca Cola AR Experience</h1>
            <img  alt="Scan this QR with your phone" src={`https://qr.pointandplace.com/delivery/${arid}?c=ar-assistant&s=avatar-web-page-coca-cola`}></img>
          </div>
        </section>
      )}

      {environmentScanned && ARSupport && (
        <div>
          <span>Redirecting to experience...</span>
          {navigateToLaunchPage()}
        </div>
      )}
    </>

  )
}
