//
//  :react & redux:
import React, { useEffect, useState } from "react";

//
//  :statics:
import { SHOWCASE_LOCALISATION_ID_ENUMS } from "../statics";

export const SlideShow = ({ getLocalisedText }) => {
  const [slide, setSlide] = useState("one")

  // calculate the translation distance based on the button's position and width
  const getTranslationDistance = (button) => {
    const buttonRect = button.getBoundingClientRect();
    const containerRect = button.parentNode.getBoundingClientRect();
    const translationDistance = buttonRect.left - containerRect.left + (buttonRect.width / 2);
    return `${translationDistance}px`;
  }

  useEffect(() => {
    const stepOneButton = document.getElementById("step-one-button");
    const stepTwoButton = document.getElementById("step-two-button");
    const stepThreeButton = document.getElementById("step-three-button");
    let activeBorderElement = document.getElementById("animated-active-border")

    if (slide === "one") { activeBorderElement.style.transform = `translateX(${getTranslationDistance(stepOneButton)})`; }
    if (slide === "two") { activeBorderElement.style.transform = `translateX(${getTranslationDistance(stepTwoButton)})`; }
    if (slide === "three") { activeBorderElement.style.transform = `translateX(${getTranslationDistance(stepThreeButton)})`; }
  }, [slide])

  const title = () => {
    return <>
      <h1 className="text-[#0CB7EB] text-[64px] py-7">{getLocalisedText(SHOWCASE_LOCALISATION_ID_ENUMS.goLiveTitle)}</h1>
    </>
  }

  const slides = () => {
    return <>
      <div className="border-2 rounded-lg border-[#0CB7EB] min-h-[40rem] p-4">
        <div className="flex justify-evenly items-center text-black relative">
          <div id="step-one-button" className="cursor-pointer" onClick={() => { setSlide("one") }}>
            <p className="text-[22px] md:text-[40px]">Step 1.</p>
          </div>
          <div id="step-two-button" className="cursor-pointer" onClick={() => { setSlide("two") }}>
            <p className="text-[22px] md:text-[40px]">Step 2.</p>
          </div>
          <div id="step-three-button" className="cursor-pointer" onClick={() => { setSlide("three") }}>
            <p className="text-[22px] md:text-[40px]">Step 3.</p>
          </div>
          <div id="animated-active-border" className="w-[3rem] absolute border-2 border-[#0CB7EB] bottom-[-14%] left-[-1.5rem] transition-all rounded-md"></div>
        </div>
        <div className="text-black flex justify-center mt-8">
          {slide === "one" && slideOneContent()}
          {slide === "two" && slideTwoContent()}
          {slide === "three" && slideThreeContent()}
        </div>
      </div>
    </>
  }

  const slideOneContent = () => {
    return <>
      <div className="flex flex-col w-full justify-center items-center mt-4">
        <div className="flex flex-col justify-between w-[95%] xl:w-[80%] 2xl:w-[70%]">
          <div className="flex flex-col md:flex-row justify-between">
            <div>
              <p className="text-[30px] -indent-[15px]">• {getLocalisedText(SHOWCASE_LOCALISATION_ID_ENUMS.stepOneDedicatedUrl)}</p>
              <p className="text-[18px] ml-3 text-[#363A48]">{getLocalisedText(SHOWCASE_LOCALISATION_ID_ENUMS.stepOneExample)}</p>
              <p className="text-[18px] mt-4 ml-3 text-[#363A48]">{getLocalisedText(SHOWCASE_LOCALISATION_ID_ENUMS.stepOnePreview)}</p>
            </div>
            <div><img className="max-w-[17rem] sm:max-w-[30rem] md:max-w-[20rem] xl:max-w-[27rem] mt-1 hover:scale-150 transition-all" src="https://cdn.pointandplace.com/pages/showcase/images/showcase-landing-example-001.png" alt="" /></div>
          </div>
          <div className="flex flex-col justify-between mt-4">
            <p className="text-[30px] -indent-[15px]">• {getLocalisedText(SHOWCASE_LOCALISATION_ID_ENUMS.stepOneIntegrate)}</p>
            <p className="text-[27px] mt-6">{getLocalisedText(SHOWCASE_LOCALISATION_ID_ENUMS.stepOneIntegrated)}</p>
            <p className="text-[18px] text-[#363A48] break-words">{getLocalisedText(SHOWCASE_LOCALISATION_ID_ENUMS.stepOneIntegratedBody)}</p>
            <p className="text-[27px] mt-3">{getLocalisedText(SHOWCASE_LOCALISATION_ID_ENUMS.stepOneNoIntergration)}</p>
            <a href="https://documentation.pointandplace.com/standard-integration">
              <p className="text-[18px] text-[#363A48] break-words underline cursor-pointer">{getLocalisedText(SHOWCASE_LOCALISATION_ID_ENUMS.stepOneNoIntergrationBody)}</p>  
            </a>
          </div>
        </div>
      </div>
    </>
  }

  const slideTwoContent = () => {
    return <>
      <div className="flex flex-col w-full justify-center items-center mt-4">
        <div className="flex flex-col w-[95%] xl:w-[80%] 2xl:w-[70%]">
          <p className="text-[27px] -indent-[15px]">• {getLocalisedText(SHOWCASE_LOCALISATION_ID_ENUMS.stepTwoFeed)}</p>
          <div className="flex flex-col md:flex-row mt-6">
            <div className="ml-3">
              <p className="text-[18px] text-[#363A48]">• {getLocalisedText(SHOWCASE_LOCALISATION_ID_ENUMS.stepTwoFeedRequirementsbBrand)}</p>
              <p className="text-[18px] mt-2 text-[#363A48]">• {getLocalisedText(SHOWCASE_LOCALISATION_ID_ENUMS.stepTwoFeedRequirementsModelName)}</p>
              <p className="text-[18px] mt-2 text-[#363A48]">• {getLocalisedText(SHOWCASE_LOCALISATION_ID_ENUMS.stepTwoFeedRequirementsCategory)}</p>
              <p className="text-[18px] mt-2 text-[#363A48]">• {getLocalisedText(SHOWCASE_LOCALISATION_ID_ENUMS.stepTwoFeedRequirementsSku)}</p>
            </div>
            <div className="ml-3 md:ml-[4rem]">
              <p className="text-[18px] text-[#363A48]">• {getLocalisedText(SHOWCASE_LOCALISATION_ID_ENUMS.stepTwoFeedRequirementsPrice)}</p>
              <p className="text-[18px] mt-2 text-[#363A48]">• {getLocalisedText(SHOWCASE_LOCALISATION_ID_ENUMS.stepTwoFeedRequirementsImage)}</p>
              <p className="text-[18px] mt-2 text-[#363A48]">• {getLocalisedText(SHOWCASE_LOCALISATION_ID_ENUMS.stepTwoFeedRequirementsProductPage)}</p>
              <p className="text-[18px] mt-2 text-[#363A48]">• {getLocalisedText(SHOWCASE_LOCALISATION_ID_ENUMS.stepTwoFeedRequirementsPath)}</p>
            </div>
          </div>
          <p className="text-[18px] mt-6 ml-3 text-[#363A48]">• {getLocalisedText(SHOWCASE_LOCALISATION_ID_ENUMS.stepTwoFeedUpdate)}</p>
          <p className="text-[18px] mt-6 ml-3 text-[#363A48]">• {getLocalisedText(SHOWCASE_LOCALISATION_ID_ENUMS.stepTwoEmailUs)}</p>
        </div>
      </div>
    </>
  }

  const slideThreeContent = () => {
    return <>
      <div className="flex flex-col w-full justify-center items-center mt-4">
        <div className="flex flex-col w-[95%] xl:w-[80%] 2xl:w-[70%]">
          <p className="text-[27px] -indent-[15px]">• {getLocalisedText(SHOWCASE_LOCALISATION_ID_ENUMS.stepThreePopularProducts)}</p>
          <p className="text-[18px] text-[#363A48]">{getLocalisedText(SHOWCASE_LOCALISATION_ID_ENUMS.stepThreePopularProductsBody)}</p>
          <p className="text-[18px] mt-6 text-[#363A48]">• {getLocalisedText(SHOWCASE_LOCALISATION_ID_ENUMS.stepThreeMinimum)}</p>
        </div>
      </div>
    </>
  }

  return <>
    <div className="bg-white w-full h-fit p-4">
      <div className="w-[90%] md:max-w-[85%] flex flex-col justify-center m-auto">
        {title()}
        {slides()}
      </div>
    </div>
  </>
}