/*

  f e a t u r e s  -  a u t h  A P I 
  Features - auth API

  :description:
  Our main API interface for the auth feature.

*/

//
//  :code:
import { getApiUrlv2 } from '../../endpoints';

//
//  :packages:
import axios from 'axios';


export const submitForm = async (payload) => {
  //
  //  :step 1:
  //  We need to post the metadata for our files and get the presigned URLs.
  const presignedUrlsResponses = await Promise.all([
    getPresignedPutUrl("brand-guidelines", payload.brandGuidelines.type, payload.brandGuidelines.name),
    getPresignedPutUrl("previous-campaigns", payload.previousCampaigns.type, payload.previousCampaigns.name)
  ])

  //
  //  :step 2:
  //  We need to upload the files and get s3 uris.
  await Promise.all([
    uploadFileToS3(presignedUrlsResponses[0].url, payload.brandGuidelines),
    uploadFileToS3(presignedUrlsResponses[1].url, payload.previousCampaigns)
  ])

  //
  //  Update our payload with the returned s3 uris.
  payload.brandGuidelines = presignedUrlsResponses[0].s3
  payload.previousCampaigns = presignedUrlsResponses[1].s3

  //
  //  :step 3:
  //  We can now post our form data to the API to save it.
  const response = await axios.post(`${getApiUrlv2()}/web-pages/virtual-brand-store/questionnaire/submit`, payload, { headers: { "Content-Type": "application/json" } })

  //  
  //  :step 4:
  //  If the response is OK, send the confirmation email 
  if (response.status !== 400){
    await axios.post(`${getApiUrlv2()}/web-pages/virtual-brand-store/questionnaire/email-confirmation`, {email: payload.email}, { headers: { "Content-Type": "application/json" } })
  }
  return response.data
}

export const uploadFileToS3 = async (url, file) => {
  //
  //  Upload our file directly to the URL.
  const response = await axios.put(
    url, file, { headers: { "Content-Type": "application/octet-stream" } }
  )
  //
  //  All done, return the status.
  return response.status
}

export const getPresignedPutUrl = async (mode, contentType, fileName) => {
  const payload = {
    contentType: contentType,
    name: fileName,
    mode: mode
  }
  const response = await axios.post(`${getApiUrlv2()}/web-pages/virtual-brand-store/questionnaire/upload-file`, payload, { headers: { "Content-Type": "application/json" } })
  return response.data
}