//
//  :react:
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

//
//  :assets:
import galleryImage001 from './assets/images/image-gallery-001.jpg'
import galleryImage002 from './assets/images/image-gallery-002.jpg'
import galleryImage003 from './assets/images/image-gallery-003.jpg'
import galleryImage004 from './assets/images/image-gallery-004.jpg'
import galleryImage005 from './assets/images/image-gallery-005.jpg'

import negroniImg from './assets/images/negroni-001.png'
import campariSpritzImg from './assets/images/campari-spritz-001.jpeg'
import milanoTorinoImg from './assets/images/milano-torino-recipe-001.jpeg'

import videoGallery001 from './assets/images/video-gallery-image-001.png'
import videoGallery002 from './assets/images/video-gallery-image-002.png'
import videoGallery003 from './assets/images/video-gallery-image-003.png'
import videoGallery004 from './assets/images/video-gallery-image-004.png'

//
//  :videos:
import videoGalleryMP4001 from './assets/videos/campari-red-passion-manifesto.mp4'
import videoGalleryMP4002 from './assets/videos/campari-red-passion-manifesto.mp4' // wrong link
import videoGalleryMP4003 from './assets/videos/campari-red-passion-bts.mp4'
import videoGalleryMP4004 from './assets/videos/campari-red-passion-bts.mp4' // wrong link

//
//  :css:
import './page.css'

//
//  :components:
import { Content } from '../../../../components/Content'
import { VideoPopup } from '../../../../components/VideoPopup'

export const GoPuffCampariContent = () => {
  const [activeVideoGallery, setActiveVideoGallery] = useState(false)

  const triggerResizeEvent = () => {
    setTimeout(() => {
      if (window.parent) {
        window.parent.postMessage(
          { scrollHeight: document.body.scrollHeight, action: 'update-go-puff-campari-content-height' },
          '*'
        )
      }
    }, 400)
  }

  useEffect(() => {
    triggerResizeEvent()
  }, [])

  window.addEventListener('load', () => {
    triggerResizeEvent()
  })
  window.addEventListener('resize', () => {
    triggerResizeEvent()
  })

  const productDescription = () => {
    return (
      <div className="flex flex-col py-4 max-w-[1111px]">
        <h1 className="sm:text-[22px] md:text-[28px] eky-title-go-puff">Product Description</h1>
        <p className="mt-3 eky-text-go-puff">
          Campari is more than a premium spirit obtained from the infusion of bitter herbs, aromatic plants, fruit and
          water, it is a drinking experience. With its vibrant red colour, intense aroma and inspiring flavour, it has
          always been a symbol of intrigue and pleasure for the taste. Multilayered and sophisticated icon like Milano,
          its hometown. A multifaceted, leading-edge city, modern and historic at the same time, classical and
          avant-garde, entrepreneurial, pleasure-loving and creative. That’s why it has been a source of passionate
          inspiration since 1860, when its founders’ creative genius invented the inimitable and secret recipe that has
          never changed in time and makes Campari an extremely versatile spirit, offering limitless and unexpected
          possibilities to create and experiment with mixology. It’s an essential ingredient for a variety of well-known
          and loved classic cocktails, like Negroni and Americano - both listed in the IBA official drinks list.
          Moreover, it fits well in easy mixed cocktails such as Campari Gin & tonic, Campari Spritz and Campari Tonic,
          characterising them, thanks to its intriguing and unmistakable taste.
        </p>
      </div>
    )
  }

  const imageGallery = () => {
    return (
      <div>
        <h1 className="sm:text-[22px] md:text-[28px] eky-title-go-puff">Image gallery</h1>
        <div className="grid md:grid-cols-5 grid-cols-2 gap-1 w-fit">
          <div className="max-w-[13rem] flex items-center justify-center">
            <img className="hover:scale-150 transition-all max-h-[208px]" src={galleryImage001} alt="" />
          </div>
          <div className="max-w-[13rem]">
            <img className="hover:scale-150 transition-all" src={galleryImage002} alt="" />
          </div>
          <div className="max-w-[13rem]">
            <img className="hover:scale-150 transition-all" src={galleryImage003} alt="" />
          </div>
          <div className="max-w-[13rem]">
            <img className="hover:scale-150 transition-all" src={galleryImage004} alt="" />
          </div>
          <div className="max-w-[13rem]">
            <img className="hover:scale-150 transition-all" src={galleryImage005} alt="" />
          </div>
        </div>
      </div>
    )
  }

  const negroniRecipe = () => {
    return (
      <div className="flex-col md:flex md:flex-row justify-between py-6 max-w-[1111px]">
        <div className="pr-4 w-full">
          <h2 className="sm:text-[20px] md:text-[22px] eky-sub-heading-go-puff">Negroni</h2>
          <p className="mt-4">
            Simple and balanced, it’s considered to be one of the most famous Italian cocktails in the world. Invented
            in 1919 by Count Negroni – who asked to add a touch of gin rather than soda to his Americano – the cocktail
            was named in his honour
          </p>
          <div>
            <div className="border-t-4 flex justify-between mt-4">
              <div className="flex flex-col mt-3 mr-1">
                <p className="font-semibold eky-ingredients-and-methods-heading">Ingredients</p>
                <div className="flex flex-col eky-text-go-puff">
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>1oz Campari</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>1oz Gin</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>1oz Sweet Vermouth</p>
                  </div>
                </div>
              </div>
              <div className="w-[20rem] mt-3">
                <p className="font-semibold eky-ingredients-and-methods-heading">Method</p>
                <div className="flex flex-col eky-text-go-puff">
                  <div className="flex">
                    <p className="min-w-[1rem]">1</p>
                    <p>Pour the campari, gin and sweet vermouth directly into the glass filled with ice</p>
                  </div>
                  <div className="flex">
                    <p className="min-w-[1rem]">2</p>
                    <p>Stir and garnish with a slice of fresh orange</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-[23rem] flex m-auto mt-4 md:mt-0">
          <img src={negroniImg} alt="" />
        </div>
      </div>
    )
  }

  const campariSpritzRecipe = () => {
    return (
      <div className="bg-[#f3f4f4] flex flex-col-reverse flex-col md:flex md:flex-row justify-between p-4 md:p-6 max-w-[1111px]">
        <div className="max-w-[23rem] flex m-auto mt-4 md:mt-0">
          <img src={campariSpritzImg} alt="" />
        </div>
        <div className="w-full md:pl-6">
          <h2 className="sm:text-[20px] md:text-[22px] eky-sub-heading-go-puff">Campari Spritz</h2>
          <p className="mt-4">
            Simplicity is often the key to the best results. A refreshing cocktail full of flavour, enhanced by the
            sparkling aromatic notes of Prosecco
          </p>
          <div className="border-t-4 flex justify-between mt-4">
            <div className="flex flex-col mt-3 mr-1">
              <p className="font-semibold eky-ingredients-and-methods-heading">Ingredients</p>
              <div className="flex flex-col eky-text-go-puff">
                <div className="flex min-w-[11rem] md:min-w-none">
                  <p>2oz Campari</p>
                </div>
                <div className="flex min-w-[11rem] md:min-w-none">
                  <p>3oz Prosecco</p>
                </div>
                <div className="flex min-w-[11rem] md:min-w-none">
                  <p>1oz A Splash of Soda</p>
                </div>
              </div>
            </div>
            <div className="w-[20rem] mt-3">
              <p className="font-semibold eky-ingredients-and-methods-heading">Method</p>
              <div className="flex flex-col eky-text-go-puff">
                <div className="flex">
                  <p className="min-w-[1rem]">1</p>
                  <p>Pour the Campari, Processo and Soda water directly into an ice-filled wine glass</p>
                </div>
                <div className="flex">
                  <p className="min-w-[1rem]">2</p>
                  <p>Garnish with orange slice and orange zest</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const vodkaMartiniRecipe = () => {
    return (
      <div className="flex-col md:flex md:flex-row justify-between py-6 max-w-[1111px]">
        <div className="pr-4 w-full">
          <h2 className="sm:text-[20px] md:text-[22px] eky-sub-heading-go-puff">Mi-To</h2>
          <p className="mt-4">
            Campari bitter (made in Milan) and red vermouth (made in Turin) came together around 1870 and created
            something outstanding. It’s no wonder that the name Mi-To sounds exactly like the Italian word for “myth”
          </p>
          <div>
            <div className="border-t-4 flex justify-between mt-4">
              <div className="flex flex-col mt-3 mr-1">
                <p className="font-semibold eky-ingredients-and-methods-heading">Ingredients</p>
                <div className="flex flex-col eky-text-go-puff">
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>1oz Campari</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>1oz Red Vermouth</p>
                  </div>
                </div>
              </div>
              <div className="w-[20rem] mt-3">
                <p className="font-semibold eky-ingredients-and-methods-heading">Method</p>
                <div className="flex flex-col eky-text-go-puff">
                  <div className="flex">
                    <p className="min-w-[1rem]">1</p>
                    <p>Stir all of the ingredients with ice and strain into an ice-cold glass</p>
                  </div>
                  <div className="flex">
                    <p className="min-w-[1rem]">2</p>
                    <p>Garnish with orange peel</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-[23rem] flex m-auto mt-4 md:mt-0">
          <img src={milanoTorinoImg} alt="" />
        </div>
      </div>
    )
  }

  const cocktailAndRecipes = () => {
    return (
      <>
        <div className="py-6">
          <h1 className="sm:text-[22px] md:text-[28px] eky-title-go-puff">Cocktail and recipes</h1>
        </div>
        {negroniRecipe()}
        {campariSpritzRecipe()}
        {vodkaMartiniRecipe()}
      </>
    )
  }

  const videoGallery = () => {
    return (
      <div className="md:py-6 py-4">
        {activeVideoGallery.active && (
          <VideoPopup
            url={activeVideoGallery.src}
            type={activeVideoGallery.type}
            containerClasses={
              'bg-white border-2 border-[#dfe1e3] text-[16px] md:text-[24px] bottom-[24rem] md:bottom-[15rem] lg:bottom-[16rem] left-[0%] eky-title-go-puff'
            }
            videoTitle={activeVideoGallery.videoTitle}
            callBack={activeVideoGallery.callBack}
          ></VideoPopup>
        )}
        <h1 className="sm:text-[22px] md:text-[28px] eky-title-go-puff">Video gallery</h1>
        <div className="grid md:grid-cols-4 grid-cols-2 gap-1 w-fit eky-text-go-puff">
          <div className="lg:w-[13rem] text-center eky-video-popup">
            <img
              className="cursor-pointer h-[103px] w-[208px]"
              src={videoGallery001}
              alt="Campari Red Passion Manifesto"
              onClick={() => {
                setActiveVideoGallery({
                  active: true,
                  src: videoGalleryMP4001,
                  type: 'video/mp4',
                  videoTitle: 'Campari Red Passion Manifesto',
                  callBack: () => {
                    setActiveVideoGallery(false)
                  },
                })
              }}
            />
            <p className="pt-2 ">Campari Red Passion Manifesto</p>
          </div>
          <div className="lg:w-[13rem] text-center">
            <img
              className="cursor-pointer h-[103px] w-[208px]"
              src={videoGallery002}
              alt="Entering Red Short Movie"
              onClick={() => {
                setActiveVideoGallery({
                  active: true,
                  src: videoGalleryMP4002,
                  type: 'video/mp4',
                  videoTitle: 'Entering Red Short Movie',
                  callBack: () => {
                    setActiveVideoGallery(false)
                  },
                })
              }}
            />
            <p className="pt-2">Entering Red Short Movie</p>
          </div>
          <div className="lg:w-[13rem] text-center">
            <img
              className="cursor-pointer h-[103px] w-[208px]"
              src={videoGallery003}
              alt="Campari Red Passion BTS"
              onClick={() => {
                setActiveVideoGallery({
                  active: true,
                  src: videoGalleryMP4003,
                  type: 'video/mp4',
                  videoTitle: 'Campari Red Passion BTS',
                  callBack: () => {
                    setActiveVideoGallery(false)
                  },
                })
              }}
            />
            <p className="pt-2">Campari Red Passion BTS</p>
          </div>
          <div className="lg:w-[13rem] text-center">
            <img
              className="cursor-pointer h-[103px] w-[208px]"
              src={videoGallery004}
              alt="Campari Red Passion Bartender"
              onClick={() => {
                setActiveVideoGallery({
                  active: true,
                  src: videoGalleryMP4004,
                  type: 'video/mp4',
                  videoTitle: 'Campari Red Passion Bartender',
                  callBack: () => {
                    setActiveVideoGallery(false)
                  },
                })
              }}
            />
            <p className="pt-2">Campari Red Passion Bartender</p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <Helmet>
        <title>eyekandy | Bevvi Grey Goose Content</title>
        <meta property="og:title" content="eyekandy | inpage" />
        <meta name="description" content="" />
        <meta name="og:description" content="" />
      </Helmet>
      <Content classes="items-center bg-white relative">
        <div className="eky-inpage flex flex-col bg-white text-[#3f3f3f] w-[100%] transition-all">
          {productDescription()}
          {imageGallery()}
          {cocktailAndRecipes()}
          {videoGallery()}
        </div>
      </Content>
      <div className="bottom-[95%] md:bottom-[91%] right-[10%] left-[10%] left-[5%] bg-[#0067BB] text-white text-[16px] md:text-[24px] hidden"></div>
    </>
  )
}
