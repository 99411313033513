import React from 'react'
import { Helmet } from 'react-helmet'
import { Content } from '../../components/Content'
import { useSearchParams } from 'react-router-dom'

export const EKNexusLaunch = () => {
  //
  //  :step 1:
  //  The page should have been passed an ID, lets get it
  let [searchParams] = useSearchParams(window.location.href)
  let productID = searchParams.get('productID')
  let environment = searchParams.get('environment')
  let service = searchParams.get('service')

  const launch = (productID, environment, service) => {
    if (!environment) {
      environment = 'dev'
    }
    if (!service) {
      service = 'ar-models'
    }

    const platform = readPlatformFromUserAgent(navigator.userAgent)
    let clickURL = null
    if (platform === 'ios') {
      const iosLaunch = new window.iOSARLaunch({ environment: 'prod' })
      clickURL = iosLaunch.renderLaunchARURL(service, productID, environment)
    }
    if (platform === 'android') {
      const androidLaunch = new window.AndroidARLaunch({ environment: 'prod' })
      clickURL = androidLaunch.renderLaunchARURL(service, productID, environment)
    }
    if (clickURL) {
      _click(clickURL)
    }
  }

  const readPlatformFromUserAgent = userAgent => {
    const platformBlock = readPlatformBlockFromUserAgent(userAgent)

    //
    //  We can just use logic to work this out, go through each of our options.
    const matchedPlatforms = {
      ios: false,
      android: false,
      linux: false,
      windows: false,
    }
    //
    //  :ios-ish:
    if (
      platformBlock.indexOf('iphone') > -1 ||
      platformBlock.indexOf('ipad') > -1 ||
      platformBlock.indexOf('macintosh') > -1
    ) {
      matchedPlatforms.ios = true
    }
    //
    //  :android-ish:
    if (platformBlock.indexOf('android') > -1) {
      matchedPlatforms.android = true
    }
    //
    //  :linux-ish:
    if (platformBlock.indexOf('linux') > -1 && !matchedPlatforms.android) {
      //
      //  We know that linux appears on all android platforms, so consult there before marking
      //  it as "linux".
      matchedPlatforms.linux = true
    }
    if (platformBlock.startsWith('x11')) {
      matchedPlatforms.linux = true
    }
    //
    //  :windows-ish:
    if (platformBlock.startsWith('windows')) {
      matchedPlatforms.windows = true
    }
    //
    //  Now look through our results and verify we only have 1 platform.
    let platform = null
    let totalMatches = 0
    for (const [key, value] of Object.entries(matchedPlatforms)) {
      if (value === true) {
        totalMatches += 1
        //
        //  Set our active platform, caution, will always overwrite if there are duplicates.
        platform = key
      }
    }
    //
    //  Make sure that we only read 1 match for the platform.
    if (totalMatches !== 1) {
      console.error(`error, multiple platforms matched for: '${userAgent}' -> '${JSON.stringify(matchedPlatforms)}'`)
      //  TODO: Throw here.
    }
    //
    //  Now return the platform, it's either null, or one of our keys [ios, android, linux, windows].
    return platform
  }

  const readPlatformBlockFromUserAgent = callerUserAgent => {
    //
    //    Note, here we may be able to use navigator.platform, but it has a chance to not be set ("").
    //
    //  Use the useragent that's available by default if we weren't given one.
    let userAgent = callerUserAgent
    if (typeof userAgent !== 'string') {
      userAgent = navigator.userAgent || null
      //
      //  Add a default to "windows" platform.
      if (userAgent) {
        return 'windows'
      }
    }
    //
    //  Note, User Agents are not predicable! We'll try our best with regexes.
    const platformRegex = /\((.*?)\)/g
    const platformMatches = platformRegex.exec(userAgent)
    //
    //  If we didn't get any matches (there should always be 2), just return.
    if (!platformMatches || platformMatches.length !== 2) {
      //console.warn(
      //  `warning, unable to read any matches from this user agent '${userAgent}'`
      //);
      return null
    }
    //
    //  Define the place to store the block containing the platform information.
    let platformBlock = platformMatches[1]
    if (!platformBlock) {
      //
      //  If it's falsy we can't continue.
      throw new Error('error, unable to read the platformBlock from userAgent')
    }
    //
    //  Change the "platform" we have in our hands to all lowercase to make matching easier.
    platformBlock = platformBlock.toLowerCase()
    //
    //  Return our platform block.
    return platformBlock
  }

  const _click = urlToClick => {
    //
    //  :step 0:
    //  Make sure we have something to click / place our anchor.
    if (typeof document === 'undefined') {
      return false
    }
    if (typeof urlToClick !== 'string') {
      return null
    }
    if (urlToClick.length === 0) {
      return null
    }

    const anchor = document.createElement('a')
    if (urlToClick.includes('.usdz')) {
      anchor.setAttribute('rel', 'ar')
      const image = document.createElement('img')
      image.style.display = 'none'
      anchor.appendChild(image)
      //
      //  :TODO:
      //
      //  To detect taps on a banner in AR, we need to bind a function now.
      //  It will stay bound, and act on its own to trigger the given callback function.
      // if (typeof callbackFunction === "function") {
      //   /* istanbul ignore next */
      //   if (!callbackContext) { callbackContext = this }
      //   /* istanbul ignore next */
      //   anchor.addEventListener("message", callbackFunction.bind(callbackContext), false);
      // }
    }

    anchor.setAttribute('href', urlToClick)
    safeAddToDOM(anchor)
    anchor.click()

    return true
  }

  const safeAddToDOM = (node) => {
    let globalNode = document.getElementById("eyekandy-div");
    if (!globalNode) {
      globalNode = document.createElement("div");
      globalNode.setAttribute("id", "eyekandy-div");
      document.body.appendChild(globalNode);
    }
    globalNode.appendChild(node);
  }

  launch(productID, environment, service)

  return (
    <>
      <Helmet>
        <title>Crossmark And UL Viewer</title>
        <meta property="og:title" content="Crossmark And UL Viewer" />
        <meta name="description" content="A viewer UI for Intel Crossmark" />
        <meta name="og:description" content="A viewer UI for Intel Crossmark" />
        <script src="https://cdn.pointandplace.com/js/eky-ar-launch/eky-ar-launch-v1.0.4.js" async></script>
      </Helmet>
      <Content>
        <div>OPENING IN AR</div>
      </Content>
    </>
  )
}
