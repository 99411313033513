//
//  :react:
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

//
//  :assets:
import galleryImage001 from '../../assets/images/image-gallery-001.jpg'
import galleryImage002 from '../../assets/images/image-gallery-002.jpg'
import galleryImage003 from '../../assets/images/image-gallery-003.jpg'
import galleryImage004 from '../../assets/images/image-gallery-004.jpg'
import vodkaLimeAndSodaImg from '../../assets/images/vodka-lime-and-soda-001.png'
import sundownSpritz from '../../assets/images/sundown-spritz-001.png'
import strawberryLemonGrassAndSoda from '../../assets/images/strawberry-lemongrass-and-soda-001.png'
import videoGallery001 from '../../assets/images/video-gallery-001.png'
import videoGallery002 from '../../assets/images/video-gallery-002.png'
import videoGallery003 from '../../assets/images/video-gallery-003.png'
import videoGallery004 from '../../assets/images/video-gallery-004.png'
//
//  :videos:
import videoGalleryMP4001 from '../../assets/videos/how-to-make-a-lychee-martini-cocktail-grey-goose-vodka.mp4'
import videoGalleryMP4002 from '../../assets/videos/how-to-make-a-vodka-soda-cocktail-grey-goose-vodka.mp4'
import videoGalleryMP4003 from '../../assets/videos/the-perfect-dry-martini-grey-goose-vodka.mp4'
import videoGalleryMP4004 from '../../assets/videos/how-vodka-is-made-grey-goose-vodka-from-field-to-bottle.mp4'

//
//  :css:
import './page.css'

//
//  :components:
import { Content } from '../../../../components/Content'
import { VideoPopup } from '../../../../components/VideoPopup'

export const BevviGreyGooseContent = () => {
  const [activeVideoGallery, setActiveVideoGallery] = useState(false)

  const triggerResizeEvent = () => {
    setTimeout(() => {
      if (window.parent) {
        window.parent.postMessage(
          { scrollHeight: document.body.scrollHeight, action: 'update-bevvi-grey-goose-content-height' },
          '*'
        )
      }
    }, 400)
  }

  useEffect(() => {
    triggerResizeEvent()
  }, [])

  window.addEventListener('load', () => {
    triggerResizeEvent()
  })
  window.addEventListener('resize', () => {
    triggerResizeEvent()
  })

  const learnMoreHiddenDescription = () => {
    return (
      <>
        <p className="mt-3 eky-text-bevvi">
          Motivated by the pursuit of excellence, GREY GOOSE vodka is a product of the obsessive brilliance of François
          Thibault, our Cellar Master and a French Maître de Chais, combined with the devotion of three farming
          co-operatives who work alongside him.
        </p>
        <p className="mt-3 eky-text-bevvi">
          The reasons why GREY GOOSE is such extraordinary vodka are clear. It’s made from only two ingredients – the
          finest soft winter wheat from Picardie and limestone-filtered spring water from our well in Gensac-la-Pallue.
          There are no additives, ever. We harvest the wheat, mill the flour and ferment the mash ourselves. This
          clarity is why GREY GOOSE only ever requires a single distillation and a single filtration.
        </p>
        <p className="mt-3 eky-text-bevvi">
          GREY GOOSE is the quintessential wheat vodka. Using it to make cocktails elevates them to entirely new heights
          of flavour and sophistication. In fact, it has become a vodka of choice as a base for the finest cocktails in
          the world. GREY GOOSE glitters in the all-time classic Martini Cocktail, setting a new standard of excellence.
          For a rich coffee taste, a GREY GOOSE Martini Espresso cocktail will truly impress. And to bring out all of
          its flavours in a simple and elegant long drink, try a GREY GOOSE Vodka Soda.
        </p>
        <p className="mt-3 eky-text-bevvi">
          Made to the highest standards with the highest quality ingredients, the natural brilliance of GREY GOOSE makes
          it an excellent gift. When meeting with friends, a GREY GOOSE cocktail makes simple moments special. Uncorking
          a bottle for celebrating adds excellence and flavour to the occasion. And of course, making cocktails at home
          becomes a special event when GREY GOOSE is involved.
        </p>
      </>
    )
  }

  const productDescription = () => {
    return (
      <div className={`flex flex-col py-4`}>
        <h1 className="sm:text-[22px] md:text-[24px] eky-title-bevvi">Product Description</h1>
        <p className="mt-3 eky-text-bevvi">
          Celebrated as the gold standard, GREY GOOSE takes the finest French winter wheat and the purest
          limestone-filtered spring water and transforms them into one of the most naturally tasteful vodkas in the
          world.
        </p>
        <p className="mt-3 eky-text-bevvi">
          The exquisite flavour of GREY GOOSE premium vodka comes directly from the quality of the ingredients. Its
          taste combines the richness of almond with apple and citrus, alongside a hint of pepper and anise. Rounded and
          silky, it’s a taste celebration.
        </p>
        {learnMoreHiddenDescription()}
      </div>
    )
  }

  const imageGallery = () => {
    return (
      <div>
        <h1 className="sm:text-[22px] md:text-[24px] eky-title-bevvi">Image gallery</h1>
        <div className="grid md:grid-cols-4 grid-cols-2 gap-1 w-fit">
          <div className="max-w-[13rem]">
            <img className="hover:scale-150 transition-all" src={galleryImage001} alt="" />
          </div>
          <div className="max-w-[13rem]">
            <img className="hover:scale-150 transition-all" src={galleryImage002} alt="" />
          </div>
          <div className="max-w-[13rem]">
            <img className="hover:scale-150 transition-all" src={galleryImage003} alt="" />
          </div>
          <div className="max-w-[13rem]">
            <img className="hover:scale-150 transition-all" src={galleryImage004} alt="" />
          </div>
        </div>
      </div>
    )
  }

  const vodkaLimeAndSodaRecipe = () => {
    return (
      <div className="flex-col md:flex md:flex-row justify-between pb-6 max-w-[1111px]">
        <div className="pr-4">
          <h2 className="sm:text-[20px] md:text-[22px] eky-sub-heading-bevvi">Vodka Lime and Soda</h2>
          <div>
            <p className="py-4 eky-text-bevvi">
              Topped with soda and a hint of lime, this cocktail is one of the simplest and most refreshing ways to
              enjoy the fresh and harmonious notes of GREY GOOSE® Vodka.
            </p>
            <div className="flex w-full justify-between px-1 md:min-h-[5rem] eky-text-bevvi">
              <div className="flex flex-col w-[4.5rem] md:w-[6.5rem] lg:w-[8.5rem]">
                <p>Difficulty</p>
                <div className="border-t-4 my-1"></div>
                <p>Easy</p>
              </div>
              <div className="flex flex-col w-[4.5rem] md:w-[6.5rem] lg:w-[8.5rem]">
                <p>Glass</p>
                <div className="border-t-4 my-1"></div>
                <p>Rocks</p>
              </div>
              <div className="flex flex-col w-[4.5rem] md:w-[6.5rem] lg:w-[8.5rem]">
                <p>Garnish</p>
                <div className="border-t-4 my-1"></div>
                <p>Mint Spring</p>
              </div>
              <div className="flex flex-col w-[4.5rem] md:w-[6.5rem] lg:w-[8.5rem]">
                <p>Occasion</p>
                <div className="border-t-4 my-1"></div>
                <p>Aperitif, Cocktail Hour, Entertaining, Warm Weather</p>
              </div>
            </div>
            <div className="border-t-4 flex justify-between mt-4">
              <div className="flex flex-col mt-3 mr-1">
                <p className="text-[17px] font-semibold">Ingredients</p>
                <div className="flex flex-col eky-text-bevvi">
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p className="min-w-[3.5rem]">35 ml</p>
                    <p>GREY GOOSE® Vodka</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p className="min-w-[3.5rem]">100 ml</p>
                    <p>Lime Soda Water</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p className="min-w-[3.5rem]">3</p>
                    <p>Lime Wedges</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p className="min-w-[3.5rem]">+</p>
                    <p>Mint Sprig Garnish</p>
                  </div>
                </div>
              </div>
              <div className="w-[20rem] mt-3">
                <p className="text-[17px] font-semibold">Method</p>
                <div className="flex flex-col eky-text-bevvi">
                  <div className="flex">
                    <p className="min-w-[1rem]">1</p>
                    <p>Build ingredients in a highball glass</p>
                  </div>
                  <div className="flex">
                    <p className="min-w-[1rem]">2</p>
                    <p>Add cubed ice and stir</p>
                  </div>
                  <div className="flex">
                    <p className="min-w-[1rem]">3</p>
                    <p>Garnish with a squeezed lime quarter</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-[23rem] flex m-auto mt-4 md:mt-0">
          <img src={vodkaLimeAndSodaImg} alt="" />
        </div>
      </div>
    )
  }

  const sundownSpritzRecipe = () => {
    return (
      <div className="bg-[#f3f4f4] flex flex-col-reverse flex-col md:flex md:flex-row justify-between p-4 md:p-6 max-w-[1111px]">
        <div className="max-w-[23rem] flex m-auto mt-4 md:mt-0">
          <img src={sundownSpritz} alt="" />
        </div>
        <div className="w-full md:pl-6">
          <h2 className="sm:text-[20px] md:text-[22px] eky-sub-heading-bevvi">Sundown Spritz</h2>
          <p className="eky-text-bevvi py-4">A bright and zesty spritz with notes of preserved orange.</p>
          <div className="flex w-full justify-between px-1 eky-text-bevvi md:min-h-[5rem]">
            <div className="flex flex-col w-[4.5rem] md:w-[8.5rem] ">
              <p>Difficulty</p>
              <div className="border-t-4 my-1"></div>
              <p>Easy</p>
            </div>
            <div className="flex flex-col w-[4.5rem] md:w-[6.5rem] lg:w-[8.5rem]">
              <p>Glass</p>
              <div className="border-t-4 my-1"></div>
              <p>Soda Can or Highball</p>
            </div>
            <div className="flex flex-col w-[4.5rem] md:w-[6.5rem] lg:w-[8.5rem]">
              <p>Garnish</p>
              <div className="border-t-4 my-1"></div>
              <p>Slice of Orange</p>
            </div>
            <div className="flex flex-col w-[4.5rem] md:w-[6.5rem] lg:w-[8.5rem]">
              <p>Occasion</p>
              <div className="border-t-4 my-1"></div>
              <p>Brunch, Entertaining</p>
            </div>
          </div>
          <div className="border-t-4 flex justify-between mt-4">
            <div className="flex flex-col mt-3 mr-1">
              <p className="font-semibold">Ingredients</p>
              <div className="flex flex-col eky-text-bevvi">
                <div className="flex min-w-[11rem] md:min-w-none">
                  <p className="min-w-[3.5rem]">40 ml</p>
                  <p>GREY GOOSE® Vodka</p>
                </div>
                <div className="flex min-w-[11rem] md:min-w-none">
                  <p className="min-w-[3.5rem]">20 ml</p>
                  <p>Manzanilla Wine</p>
                </div>
                <div className="flex min-w-[11rem] md:min-w-none">
                  <p className="min-w-[3.5rem]">140 ml</p>
                  <p>Orangina®</p>
                </div>
                <div className="flex min-w-[11rem] md:min-w-none">
                  <p className="min-w-[3.5rem]">+</p>
                  <p>Orange Wedge</p>
                </div>
              </div>
            </div>
            <div className="w-[20rem] mt-3">
              <p className="font-semibold">Method</p>
              <div className="flex flex-col eky-text-bevvi">
                <div className="flex">
                  <p className="min-w-[1rem]">1</p>
                  <p>Build in a can or highball with cubed ice.</p>
                </div>
                <div className="flex">
                  <p className="min-w-[1rem]">2</p>
                  <p>Garnish with a slice of orange.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const strawberryLemongrassAndSodaRecipe = () => {
    return (
      <div className="flex-col md:flex md:flex-row justify-between py-6 max-w-[1111px]">
        <div className="pr-4 w-full">
          <h2 className="sm:text-[20px] md:text-[22px] eky-sub-heading-bevvi">Strawberry Lemongrass & Soda</h2>
          <div>
            <p className="eky-text-bevvi py-4">
              The taste of sweet strawberry meets lemongrass and soda for an effortlessly elegant cocktail.
            </p>
            <div className="flex w-full justify-between px-1 md:min-h-[5rem] eky-text-bevvi">
              <div className="flex flex-col w-[4.5rem] md:w-[6.5rem] lg:w-[8.5rem]">
                <p>Difficulty</p>
                <div className="border-t-4 my-1"></div>
                <p>Easy</p>
              </div>
              <div className="flex flex-col w-[4.5rem] md:w-[6.5rem] lg:w-[8.5rem]">
                <p>Glass</p>
                <div className="border-t-4 my-1"></div>
                <p>Wine Glass</p>
              </div>
              <div className="flex flex-col w-[4.5rem] md:w-[6.5rem] lg:w-[8.5rem]">
                <p>Garnish</p>
                <div className="border-t-4 my-1"></div>
                <p>Lemongrass & Lemon Twist</p>
              </div>
              <div className="flex flex-col w-[4.5rem] md:w-[6.5rem] lg:w-[8.5rem]">
                <p>Occasion</p>
                <div className="border-t-4 my-1"></div>
                <p>Aperitif, Brunch, Warm Weather</p>
              </div>
            </div>
            <div className="border-t-4 flex justify-between mt-4">
              <div className="flex flex-col mt-3 mr-1">
                <p className="font-semibold">Ingredients</p>
                <div className="flex flex-col eky-text-bevvi">
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p className="min-w-[3.5rem]">50 ml</p>
                    <p>GREY GOOSE® Essence Strawberry & Lemongrass</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p className="min-w-[3.5rem]">150 ml</p>
                    <p>soda</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p className="min-w-[3.5rem]">+</p>
                    <p>Fresh Strawberries</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p className="min-w-[3.5rem]">+</p>
                    <p>Lemongrass</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p className="min-w-[3.5rem]">+</p>
                    <p>Lemon Twist</p>
                  </div>
                </div>
              </div>
              <div className="w-[20rem] mt-3">
                <p className="font-semibold">Method</p>
                <div className="flex flex-col eky-text-bevvi">
                  <div className="flex">
                    <p className="min-w-[1rem]">1</p>
                    <p>Fill a chilled glass with cubed ice and add GREY GOOSE® Essences Strawberry & Lemongrass.</p>
                  </div>
                  <div className="flex">
                    <p className="min-w-[1rem]">2</p>
                    <p>Top with soda water.</p>
                  </div>
                  <div className="flex">
                    <p className="min-w-[1rem]">3</p>
                    <p>Then simply garnish with lemongrass, fresh strawberry, and a lemon twist.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-[23rem] flex m-auto mt-4 md:mt-0">
          <img src={strawberryLemonGrassAndSoda} alt="" />
        </div>
      </div>
    )
  }

  const cocktailAndRecipes = () => {
    return (
      <>
        <div className="py-6">
          <h1 className="sm:text-[22px] md:text-[24px] eky-title-bevvi">Cocktail and recipes</h1>
        </div>
        {vodkaLimeAndSodaRecipe()}
        {sundownSpritzRecipe()}
        {strawberryLemongrassAndSodaRecipe()}
      </>
    )
  }

  const videoGallery = () => {
    return (
      <div className="md:py-6 py-4">
        {activeVideoGallery.active && (
          <VideoPopup
            url={activeVideoGallery.src}
            type={activeVideoGallery.type}
            containerClasses={
              'bg-white border-2 border-[#dfe1e3] text-[16px] md:text-[24px] bottom-[24rem] md:bottom-[15rem] lg:bottom-[16rem] left-[0%] eky-title-bevvi'
            }
            videoTitle={activeVideoGallery.videoTitle}
            callBack={activeVideoGallery.callBack}
          ></VideoPopup>
        )}
        <h1 className="sm:text-[22px] md:text-[24px] eky-title-bevvi">Video gallery</h1>
        <div className="grid md:grid-cols-4 grid-cols-2 gap-1 w-fit eky-text-bevvi">
          <div className="lg:w-[13rem] text-center eky-video-popup">
            <img
              className="cursor-pointer eky-video-popup"
              src={videoGallery001}
              alt="How to make a Lychee Martini Cocktail"
              onClick={() => {
                setActiveVideoGallery({
                  active: true,
                  src: videoGalleryMP4001,
                  type: 'video/mp4',
                  videoTitle: 'How to make a lychee martini cocktail grey goose vodka',
                  callBack: () => {
                    setActiveVideoGallery(false)
                  },
                })
              }}
            />
            <p className="pt-2 ">How to make a Lychee Martini Cocktail</p>
          </div>
          <div className="lg:w-[13rem] text-center">
            <img
              className="cursor-pointer"
              src={videoGallery002}
              alt="How to make a Vodka Soda Cocktail"
              onClick={() => {
                setActiveVideoGallery({
                  active: true,
                  src: videoGalleryMP4002,
                  type: 'video/mp4',
                  videoTitle: 'How to make a Vodka Soda Cocktail',
                  callBack: () => {
                    setActiveVideoGallery(false)
                  },
                })
              }}
            />
            <p className="pt-2">How to make a Vodka Soda Cocktail</p>
          </div>
          <div className="lg:w-[13rem] text-center">
            <img
              className="cursor-pointer"
              src={videoGallery003}
              alt="the Perfect Dry Martini"
              onClick={() => {
                setActiveVideoGallery({
                  active: true,
                  src: videoGalleryMP4003,
                  type: 'video/mp4',
                  videoTitle: 'The Perfect Dry Martini',
                  callBack: () => {
                    setActiveVideoGallery(false)
                  },
                })
              }}
            />
            <p className="pt-2">The Perfect Dry Martini</p>
          </div>
          <div className="lg:w-[13rem] text-center">
            <img
              className="cursor-pointer"
              src={videoGallery004}
              alt="How Vodka is Made: Grey Goose Vodka from Field to Bottle"
              onClick={() => {
                setActiveVideoGallery({
                  active: true,
                  src: videoGalleryMP4004,
                  type: 'video/mp4',
                  videoTitle: 'How Vodka is Made: Grey Goose Vodka from Field to Bottle',
                  callBack: () => {
                    setActiveVideoGallery(false)
                  },
                })
              }}
            />
            <p className="pt-2">How Vodka is Made: Grey Goose Vodka from Field to Bottle</p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <Helmet>
        <title>eyekandy | Bevvi Grey Goose Content</title>
        <meta property="og:title" content="eyekandy | inpage" />
        <meta name="description" content="" />
        <meta name="og:description" content="" />
      </Helmet>
      <Content classes="items-center bg-white relative">
        <div className="eky-inpage flex flex-col bg-white text-[#3f3f3f] w-[100%] transition-all">
          {productDescription()}
          {imageGallery()}
          {cocktailAndRecipes()}
          {videoGallery()}
        </div>
      </Content>
      <div className="bottom-[95%] md:bottom-[91%] right-[10%] left-[10%] left-[5%] bg-[#0067BB] text-white text-[16px] md:text-[24px] hidden"></div>
    </>
  )
}
