/*

  f e a t u r e s  -  b e s t  b u y  l a n d i n g
  Features - Best Buy Landing

  :description:
  Our main API interface for the Best Buy page.

*/

//
//  :code:
import { getApiUrlv2 } from '../../endpoints';

//
//  :packages:
import axios from 'axios';


export const submitTeknosaForm = async (payload) => {
  //
  // All email responses go to luc@eyekandy.com
  
  //
  //  :step 3:
  //  We can now post our form data to the API to save it.
  const response = await axios.post(`${getApiUrlv2()}/web-pages/teknosa/landing`, payload, { headers: { "Content-Type": "application/json" } })

  //  
  //  :step 4:
  //  If the response is OK, send the confirmation email 
  /*
  if (response.status !== 400){
    await axios.post(`${getApiUrlv2()}/web-pages/virtual-brand-store/questionnaire/email-confirmation`, {email: payload.email}, { headers: { "Content-Type": "application/json" } })
  }
  */
  return response.status
} 