//
//  :react:
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

//
//  :assets:
import galleryImage001 from './assets/images/image-gallery-001.jpg'
import galleryImage002 from './assets/images/image-gallery-002.jpg'
import galleryImage003 from './assets/images/image-gallery-003.jpg'
import galleryImage004 from './assets/images/image-gallery-004.jpg'

import espressoMartiniImg from './assets/images/espresso-martini-001.webp'
import moscowMuleImg from './assets/images/moscow-mule-001.jpg'
import vodkaMartiniImg from './assets/images/vodka-martini-001.jpg'

import videoGallery001 from './assets/images/video-gallery-image-001.png'
import videoGallery002 from './assets/images/video-gallery-image-002.png'
import videoGallery003 from './assets/images/video-gallery-image-003.png'
import videoGallery004 from './assets/images/video-gallery-image-004.png'

//
//  :videos:
import videoGalleryMP4001 from './assets/videos/world-behind-absolut-cocktails-behind-the-scenes.mp4'
import videoGalleryMP4002 from './assets/videos/behind-the-scenes-absolute-juice.mp4'
import videoGalleryMP4003 from './assets/videos/the-absolut-creative-competition-four-decades.mp4'
import videoGalleryMP4004 from './assets/videos/the-vodka-with-nothing-to-hide.mp4'

//
//  :css:
import './page.css'

//
//  :components:
import { Content } from '../../../../components/Content'
import { VideoPopup } from '../../../../components/VideoPopup'

export const GoPuffAbsolutContent = () => {
  const [activeVideoGallery, setActiveVideoGallery] = useState(false)

  const triggerResizeEvent = () => {
    setTimeout(() => {
      if (window.parent) {
        window.parent.postMessage(
          { scrollHeight: document.body.scrollHeight, action: 'update-go-puff-absolut-content-height' },
          '*'
        )
      }
    }, 400)
  }

  useEffect(() => {
    triggerResizeEvent()
  }, [])

  window.addEventListener('load', () => {
    triggerResizeEvent()
  })
  window.addEventListener('resize', () => {
    triggerResizeEvent()
  })

  const productDescription = () => {
    return (
      <div className="flex flex-col py-4 max-w-[1111px]">
        <h1 className="sm:text-[22px] md:text-[28px] eky-title-go-puff">Product Description</h1>
        <p className="mt-3 eky-text-go-puff">
          Absolut Vodka is one of the most famous vodkas in the world. It is a Swedish vodka made exclusively from
          natural ingredients, with no added sugar and made from water and winter wheat grown in the fields of Åhus,
          Sweden. Absolut is rich, full bodied and blends beautifully with other aromas, enhancing the taste of your
          drinks and cocktails. This timeless vodka is the perfect gift to offer for Christmas, anniversaries,
          birthdays, Father’s Day and other special occasions. It comes in a 1L (1 Litre) bottle and has an alcohol
          content of 40% vol. Absolut Vodka has completely redefined the premium vodka landscape, becoming synonymous
          with art, culture and nightlife. By starting a revolution in cocktail creation and launching a range of
          flavours never before seen on the market, Absolut has become an icon in its own right. It has been produced at
          the famous old distilleries near Ahus in accordance with more than 400 years of Swedish tradition. In 1879,
          Lars Olsson Smith, its founder, introduced the continuous distillation with which he made Absolut. Instead of
          the usual three or four times, the vodka was distilled an infinite number of times. At Absolut, not only do we
          source all the ingredients locally, we have also made our distillery one of the most energy-efficient in the
          world. Today, Absolut has a CO2 neutral distillery.
        </p>
      </div>
    )
  }

  const imageGallery = () => {
    return (
      <div>
        <h1 className="sm:text-[22px] md:text-[28px] eky-title-go-puff">Image gallery</h1>
        <div className="grid md:grid-cols-4 grid-cols-2 gap-1 w-fit">
          <div className="max-w-[13rem] flex items-center justify-center">
            <img className="hover:scale-150 transition-all max-h-[208px]" src={galleryImage001} alt="" />
          </div>
          <div className="max-w-[13rem]">
            <img className="hover:scale-150 transition-all" src={galleryImage002} alt="" />
          </div>
          <div className="max-w-[13rem]">
            <img className="hover:scale-150 transition-all" src={galleryImage003} alt="" />
          </div>
          <div className="max-w-[13rem]">
            <img className="hover:scale-150 transition-all" src={galleryImage004} alt="" />
          </div>
        </div>
      </div>
    )
  }

  const espressoMartiniRecipe = () => {
    return (
      <div className="flex-col md:flex md:flex-row justify-between py-6 max-w-[1111px]">
        <div className="pr-4 w-full">
          <h2 className="sm:text-[20px] md:text-[22px] eky-sub-heading-go-puff">Espresso Martini</h2>
          <div>
            <div className="border-t-4 flex justify-between mt-4">
              <div className="flex flex-col mt-3 mr-1">
                <p className="font-semibold eky-ingredients-and-methods-heading">Ingredients</p>
                <div className="flex flex-col eky-text-go-puff">
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>Ice Cubes</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>45 ml Absolut Vodka</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>25 ml Kahlúa</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>25 ml Espresso</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>3 Whole Coffee Beans</p>
                  </div>
                </div>
              </div>
              <div className="w-[20rem] mt-3">
                <p className="font-semibold eky-ingredients-and-methods-heading">Method</p>
                <div className="flex flex-col eky-text-go-puff">
                  <div className="flex">
                    <p className="min-w-[1rem]">1</p>
                    <p>Fill a cocktail shaker with ice cubes</p>
                  </div>
                  <div className="flex">
                    <p className="min-w-[1rem]">2</p>
                    <p>Add Absolut Vodka, Kahlúa, and Espresso</p>
                  </div>
                  <div className="flex">
                    <p className="min-w-[1rem]">3</p>
                    <p>Shake well to get the foam on top, and strain it into a cocktail or martini glass</p>
                  </div>
                  <div className="flex">
                    <p className="min-w-[1rem]">4</p>
                    <p>Garnish by placing three coffee beans on top</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-[23rem] flex m-auto mt-4 md:mt-0">
          <img src={espressoMartiniImg} alt="" />
        </div>
      </div>
    )
  }

  const moscowMuleRecipe = () => {
    return (
      <div className="bg-[#f3f4f4] flex flex-col-reverse flex-col md:flex md:flex-row justify-between p-4 md:p-6 max-w-[1111px]">
        <div className="max-w-[23rem] flex m-auto mt-4 md:mt-0">
          <img src={moscowMuleImg} alt="" />
        </div>
        <div className="w-full md:pl-6">
          <h2 className="sm:text-[20px] md:text-[22px] eky-sub-heading-go-puff">Moscow Mule</h2>

          <div className="border-t-4 flex justify-between mt-4">
            <div className="flex flex-col mt-3 mr-1">
              <p className="font-semibold eky-ingredients-and-methods-heading">Ingredients</p>
              <div className="flex flex-col eky-text-go-puff">
                <div className="flex min-w-[11rem] md:min-w-none">
                  <p>Ice Cubes</p>
                </div>
                <div className="flex min-w-[11rem] md:min-w-none">
                  <p>45 ml Absolut Vodka</p>
                </div>
                <div className="flex min-w-[11rem] md:min-w-none">
                  <p>15 ml Lime Juice</p>
                </div>
                <div className="flex min-w-[11rem] md:min-w-none">
                  <p>Ginger Beer</p>
                </div>
                <div className="flex min-w-[11rem] md:min-w-none">
                  <p>1 Wedge Lime</p>
                </div>
              </div>
            </div>
            <div className="w-[20rem] mt-3">
              <p className="font-semibold eky-ingredients-and-methods-heading">Method</p>
              <div className="flex flex-col eky-text-go-puff">
                <div className="flex">
                  <p className="min-w-[1rem]">1</p>
                  <p>Fill a chilled stainless steel or copper mule mug with ice cubes</p>
                </div>
                <div className="flex">
                  <p className="min-w-[1rem]">2</p>
                  <p>Add Absolut Vodka and lime juice</p>
                </div>
                <div className="flex">
                  <p className="min-w-[1rem]">3</p>
                  <p>Top up with ginger beer</p>
                </div>
                <div className="flex">
                  <p className="min-w-[1rem]">4</p>
                  <p>Garnish with a lime wedge</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const vodkaMartiniRecipe = () => {
    return (
      <div className="flex-col md:flex md:flex-row justify-between py-6 max-w-[1111px]">
        <div className="pr-4 w-full">
          <h2 className="sm:text-[20px] md:text-[22px] eky-sub-heading-go-puff">Vodka Martini</h2>
          <div>
            <div className="border-t-4 flex justify-between mt-4">
              <div className="flex flex-col mt-3 mr-1">
                <p className="font-semibold eky-ingredients-and-methods-heading">Ingredients</p>
                <div className="flex flex-col eky-text-go-puff">
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>Ice Cubes</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>45 ml Absolut Vodka</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>5 ml Dry Vermouth</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>1 Twist Lemon Zest</p>
                  </div>
                  <div className="flex min-w-[11rem] md:min-w-none">
                    <p>1 Whole Green Olive</p>
                  </div>
                </div>
              </div>
              <div className="w-[20rem] mt-3">
                <p className="font-semibold eky-ingredients-and-methods-heading">Method</p>
                <div className="flex flex-col eky-text-go-puff">
                  <div className="flex">
                    <p className="min-w-[1rem]">1</p>
                    <p>Fill a mixing glass with ice cubes</p>
                  </div>
                  <div className="flex">
                    <p className="min-w-[1rem]">2</p>
                    <p>Add all ingredients</p>
                  </div>
                  <div className="flex">
                    <p className="min-w-[1rem]">3</p>
                    <p>Stir and strain into a cocktail glass</p>
                  </div>
                  <div className="flex">
                    <p className="min-w-[1rem]">4</p>
                    <p>Garnish with a twisted lemon zest and a green olive</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-[23rem] flex m-auto mt-4 md:mt-0">
          <img src={vodkaMartiniImg} alt="" />
        </div>
      </div>
    )
  }

  const cocktailAndRecipes = () => {
    return (
      <>
        <div className="py-6">
          <h1 className="sm:text-[22px] md:text-[28px] eky-title-go-puff">Cocktail and recipes</h1>
        </div>
        {espressoMartiniRecipe()}
        {moscowMuleRecipe()}
        {vodkaMartiniRecipe()}
      </>
    )
  }

  const videoGallery = () => {
    return (
      <div className="md:py-6 py-4">
        {activeVideoGallery.active && (
          <VideoPopup
            url={activeVideoGallery.src}
            type={activeVideoGallery.type}
            containerClasses={
              'bg-white border-2 border-[#dfe1e3] text-[16px] md:text-[24px] bottom-[24rem] md:bottom-[15rem] lg:bottom-[16rem] left-[0%] eky-title-go-puff'
            }
            videoTitle={activeVideoGallery.videoTitle}
            callBack={activeVideoGallery.callBack}
          ></VideoPopup>
        )}
        <h1 className="sm:text-[22px] md:text-[28px] eky-title-go-puff">Video gallery</h1>
        <div className="grid md:grid-cols-4 grid-cols-2 gap-1 w-fit eky-text-go-puff">
          <div className="lg:w-[13rem] text-center eky-video-popup">
            <img
              className="cursor-pointer eky-video-popup"
              src={videoGallery001}
              alt="World of Absolut Cocktails Behind the Scenes"
              onClick={() => {
                setActiveVideoGallery({
                  active: true,
                  src: videoGalleryMP4001,
                  type: 'video/mp4',
                  videoTitle: 'World of Absolut Cocktails Behind the Scenes',
                  callBack: () => {
                    setActiveVideoGallery(false)
                  },
                })
              }}
            />
            <p className="pt-2 ">World of Absolut Cocktails Behind the Scenes</p>
          </div>
          <div className="lg:w-[13rem] text-center">
            <img
              className="cursor-pointer"
              src={videoGallery002}
              alt="Behind the Scenes Absolut Juice feat. Lizzo"
              onClick={() => {
                setActiveVideoGallery({
                  active: true,
                  src: videoGalleryMP4002,
                  type: 'video/mp4',
                  videoTitle: 'Behind the Scenes Absolut Juice feat. Lizzo',
                  callBack: () => {
                    setActiveVideoGallery(false)
                  },
                })
              }}
            />
            <p className="pt-2">Behind the Scenes Absolut Juice feat. Lizzo</p>
          </div>
          <div className="lg:w-[13rem] text-center">
            <img
              className="cursor-pointer"
              src={videoGallery003}
              alt="The Absolut Creative Competition Four Decades: Haring to Vonnegut"
              onClick={() => {
                setActiveVideoGallery({
                  active: true,
                  src: videoGalleryMP4003,
                  type: 'video/mp4',
                  videoTitle: 'The Absolut Creative Competition Four Decades: Haring to Vonnegut',
                  callBack: () => {
                    setActiveVideoGallery(false)
                  },
                })
              }}
            />
            <p className="pt-2">The Absolut Creative Competition Four Decades: Haring to Vonnegut</p>
          </div>
          <div className="lg:w-[13rem] text-center">
            <img
              className="cursor-pointer"
              src={videoGallery004}
              alt="ABSOLUT The Vodka With Nothing To Hide"
              onClick={() => {
                setActiveVideoGallery({
                  active: true,
                  src: videoGalleryMP4004,
                  type: 'video/mp4',
                  videoTitle: 'ABSOLUT The Vodka With Nothing To Hide',
                  callBack: () => {
                    setActiveVideoGallery(false)
                  },
                })
              }}
            />
            <p className="pt-2">ABSOLUT The Vodka With Nothing To Hide</p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <Helmet>
        <title>eyekandy | Bevvi Grey Goose Content</title>
        <meta property="og:title" content="eyekandy | inpage" />
        <meta name="description" content="" />
        <meta name="og:description" content="" />
      </Helmet>
      <Content classes="items-center bg-white relative">
        <div className="eky-inpage flex flex-col bg-white text-[#3f3f3f] w-[100%] transition-all">
          {productDescription()}
          {imageGallery()}
          {cocktailAndRecipes()}
          {videoGallery()}
        </div>
      </Content>
      <div className="bottom-[95%] md:bottom-[91%] right-[10%] left-[10%] left-[5%] bg-[#0067BB] text-white text-[16px] md:text-[24px] hidden"></div>
    </>
  )
}
