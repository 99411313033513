
//
//  :react & redux:
import React from "react";
import { Helmet } from "react-helmet";

//
//  :components:
import { Content } from "../../../components/Content";
import { VirtualRoomQuestionnairePageFormV3 } from "./Form";

//
//  :component:
export const VirtualRoomQuestionnaireV3 = (props) => {

  const renderEyekandyLogo = () => {
    return <h2 className="company-name text-2xl text-white font-comfortaa text-white font-[600] text-center">eyekandy</h2>
  }

  const renderPageHeading = () => {
    return <h1 className="intro-text text-3xl w-80 text-white font-comfortaa text-white font-[550] text-center px-8 my-[1rem]">
      Virtual Room Questionnaire
    </h1>
  }

  /**
   * Our mainline function to render our form.
   */
  const renderContent = () => {
    return <VirtualRoomQuestionnairePageFormV3></VirtualRoomQuestionnairePageFormV3>
  }

  const renderPage = () => {

    return <>
      <section name="virtual-room-questionnaire" className="relative flex flex-col h-full items-center justify-between py-8 px-[1rem] sm:px-0 w-full bg-[#363A48]">
        {renderEyekandyLogo()}
        {renderPageHeading()}

        {renderContent()}
      </section>
    </>
  }

  return (
    <>
      <Helmet>
        <title>Eyekandy - Virtual Room Questionnaire</title>
        <meta property="og:title" content="Eyekandy - Virtual Room Questionnaire" />
        <meta name="description" content="" />
        <meta name="og:description" content="" />
      </Helmet>
      <Content classes="items-center bg-[#363A48]">
        {renderPage()}
      </Content>
    </>
  )
}
